<div class="form-group text-left" [formGroup]="parentFormGroup">
    <div class="col-lg-12" [ngClass]="actionInProgress || readonly ? 'disabled' : null">
        <ui-switch (change)="setValue()" [checked]="switchValue" switchColor="#fcfcfc"
                   [disabled]="!permissions.Edit || higherRoleIsNeeded || actionInProgress || readonly">

        </ui-switch>
        <label *ngIf="title" class="control-label title">
            {{title}} <app-tooltip *ngIf="toolTipOptions && toolTipOptions.text != ''" [toolTipOptions]="toolTipOptions"></app-tooltip>
            <span *ngIf="required === true">*</span>
        </label>
        <div style="clear: both"></div>
    </div>
</div>
