<app-subpageheader [text]="'Kiterjesztett valóság gyártókörnyezet'" [imgfolder]="'gyartokornyezet'"></app-subpageheader>

<div class="container">
  <div class="row">
    <div class="col">
      <div class="desc text-lg-left text-center">
        Ismerje meg hibrid könyv előállítási rendszerünket! Hatékony tervezés, gyártás, nyelvi lokalizálás.
      </div>
    </div>
  </div>
</div>
<!-- <app-prototyperow></app-prototyperow> -->


<div class="container">




  <div class="row">
    <div class="col-lg-12 text-center my-3">
      <img class="minta" src="assets/img/gyartokornyezet/eukariota_1140.png" alt="" />
    </div>
    <div class="col-lg-12 text-center my-3">
      <img class="minta" src="assets/img/gyartokornyezet/17_1140.png" alt="" />
    </div>
    <div class="col-lg-12 text-center my-3">
      <img class="minta" src="assets/img/gyartokornyezet/15_1140.png" alt="" />
    </div>
    <div class="col-lg-12 text-center my-3">
      <img class="minta" src="assets/img/gyartokornyezet/16_1140.png" alt="" />
    </div>
    <div class="col-lg-12 text-lg-left text-center my-3">
      <p class="red ">
        Amennyiben érdeklődik termékeink iránt, lépjen velünk kapcsolatba cégünk <a href="https://multilearn.hu/ceginformaciok" target="_blank" class="red-dark">elérhetőségein</a>.
      </p>
    </div>
  </div>





</div>

<app-footer></app-footer>
