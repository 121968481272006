<div class="navbar-item">
    <div *ngIf="!navbaritem.child; then noChild else withChild"></div>
    <ng-template #noChild>
        <a class="nav-link" [routerLink]="navbaritem.link" [queryParams]="{ onlymaincontent: navbaritem.onlymaincontent}" id="navbar-item-{{navbaritem.id}}" [state]="{ ignoreLoadingBar: navbaritem.ignoreLoadingBar ? true : false}" [ngClass]=" (navbaritem.link | navlinkHasSameRoot:currentUrl)  ? 'active' : ''">
            <i class="{{navbaritem.icon}}"></i>
            <span class="text">{{navbaritem.name}}</span> <span class="sr-only">(current)</span>
        </a>
    </ng-template>

    <ng-template #withChild>

        <a class="nav-link" href="#collapse-{{navbaritem.id}}" id="navbar-item-{{navbaritem.id}}" data-toggle="collapse" role="button"
           attr.aria-expanded="{{ariaExpanded}}" aria-controls="collapseExample">
            <i class="{{navbaritem.icon}}"></i>
            <span class="text">{{navbaritem.name}}</span> <span class="sr-only">(current)</span>
        </a>

        <div *ngIf="navbaritem.child?.length > 0" class="collapse nav-child {{showClass}}"  id="collapse-{{navbaritem.id}}">
            <div class="nav flex-column">
                <div class="nav-item" *ngFor="let child of navbaritem.child">

                    <a class="nav-link" [ngClass]=" (child.link| navlinkHasSameRoot:currentUrl)  ? 'active' : ''" [routerLink]="child.link"  id="{{child.id}}" [state]="{ ignoreLoadingBar: child.ignoreLoadingBar ? true : false }"  >
                        <span class="prefix">{{child.icon}}</span>
                        <span class="text">{{child.name}}</span>
                    </a>
                </div>
            </div>
        </div>
    </ng-template>
</div>

