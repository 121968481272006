import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {


    constructor(private http: HttpClient) {
    }

    getLanguages(): Observable<any[]> {

        var webdatas = JSON.parse(sessionStorage.getItem("webdatas"));
        var lang = localStorage.getItem("lang");

        return of<any[]>([
            {'id': 1, 'itemName': webdatas[lang]['site_languages']['Hungarian'], 'code': 'hu', 'image': ''},
            {'id': 2, 'itemName': webdatas[lang]['site_languages']['English'], 'code': 'en', 'image': ''}
        ]);
    }

    getLanguageCodes(): Observable<any[]> {
        return of<any[]>(
            ['hu', 'en']
        );
    }
}
