<!-- Description Modal -->
<div class="modal fade" id="descriptionModal" tabindex="-1" role="dialog" aria-labelledby="descriptionModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="descriptionModalLabel">{{modal_title}}</h5>
                <div class="">
                    <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[modal_languagecode]" ></div>{{modal_language}} | <b>{{CatTitle}}:</b> {{modal_category}} | <b>{{orgTitle}}:</b> {{modal_organization}}
                </div>

            </div>

            <div class="modal-body text-left multi_lines_text">
                {{modal_body}}
                <div class="ar-meta-container"  *ngIf="modal_has_ar_meta_grouped">
                    <div class="ar-meta-container-title">{{modal_ar_files_title}}</div>
                    <div *ngFor="let i = index; let meta of modal_ar_meta" class="text-left multi_lines_text ar-meta-row">
                        <div class="ar-meta-title" *ngIf="meta.title">{{meta.title}}</div>
                        <div class="ar-meta-keywords" *ngIf="meta.keywords"># {{meta.keywords}}</div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{closeText}}</button>
            </div>
        </div>
    </div>
</div>


<div class="marketplace-container">
    <div class="books" *ngIf="!actionInProgress && booksLength == 0">
        {{there_are_no_books}}
    </div>

    <div class="filters" *ngIf="!actionInProgress && booksLength > 2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group sort">

                        <select [(ngModel)]="sortProperty" class="form-control">
                            <!-- <option value="name">{{sort_by}}</option> -->
                            <option value="name">{{sort_title_az}}</option>
                            <option value="name:desc">{{sort_title_za}}</option>
                            <option value="language_text">{{sort_language_text_az}}</option>
                            <option value="language_text:desc">{{sort_language_text_za}}</option>
                            <option value="category_text">{{sort_category_text_az}}</option>
                            <option value="category_text:desc">{{sort_category_text_za}}</option>
                            <option value="organization_name">{{sort_organization_name_az}}</option>
                            <option value="organization_name:desc">{{sort_organization_name_za}}</option>
                        </select>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group filter">
                        <input [(ngModel)]="searchText" placeholder="{{searchTitle}}" type="text" class="form-control"
                               aria-describedby="btnGroupAddon">
                        <div class="input-group-prepend">
                            <div id="btnGroupAddon" [ngClass]="{'input-group-text':true,'remove-pointer': searchText?true:false}" (click)="removeSearchText(searchText)">
                                <i *ngIf="!searchText" class="fas fa-search"></i>
                                <i *ngIf="searchText" class="fas fa-times-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>


    <div class="books" *ngIf="!actionInProgress && booksLength > 0">
        <div class="container-fluid">
            <div class="row">
                <div *ngFor="let i = index; let book of books | marketplaceFilter : searchText : sts | marketplaceSort: sortProperty"
                     [ngClass]="{'col-12':true,'col-md-auto':true,'book-for':true, 'lines':searchText?true:false}">
                    <div class="book my-2">
                        <div class="cover">
                            <!-- <img *ngIf="book.files.coverImage == undefined"  src="https://via.placeholder.com/100x150/{{bgColor[book.id]}}/{{textColor[book.id]}}.png?text={{noImageText}}"/> -->
                            <div class="no-cover-image" *ngIf="book.cover_image.length == 0" [style.background-color]="bgColor[book.id]" [style.color]="textColor[book.id]">{{noImageText}}</div>
                            <div class="cover-image" *ngIf="book.cover_image.length > 0" [style.backgroundImage]="book.ciurl" ></div>
                        </div>
                        <div class="book-content">
                            <p class="title" title="{{book.name}}">{{book.name}}</p>
                            <div class="language" title="{{book.language_text}}">
                                <div class="text"><!--<b>{{langTitle}}:</b> -->
                                    <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[book.language_code]" ></div>{{book.language_text}}
                                </div>
                            </div>
                            <div class="category" title="{{book.category_text}}">
                                <div class="text"><b>{{CatTitle}}:</b> {{book.category_text}}</div>
                            </div>
                            <div class="organization" title="{{book.organization_name}} ({{book.organization_email}})">
                                <div class="text"><b>{{orgTitle}}:</b> {{book.organization_name}} ({{book.organization_email}})</div>
                            </div>
                            <div class="links">
                                <a *ngIf="book.content_preview.length > 0" href="{{book.cpurl}}" class="label extract">{{extractTitle}}</a>
                                <a *ngIf="book.description.length > 0" class="label description-btn" (click)="setModalContent(book)" data-toggle="modal" data-target="#descriptionModal">{{booksDescTitle}}</a>
                            </div>
                        </div>
                        <div class="search-content">
                            <div class="">
                                {{book.description}}
                                <div class="ar-meta-container" *ngIf="book.ar_meta_grouped">
                                    <div class="ar-meta-container-title">{{book.ar_files_title}}</div>
                                    <div *ngFor="let i = index; let meta of book.ar_meta_grouped" class="text-left multi_lines_text ar-meta-row">
                                        <div class="ar-meta-title" *ngIf="meta.title">{{meta.title}}</div>
                                        <div class="ar-meta-keywords" *ngIf="meta.keywords"># {{meta.keywords}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>                              