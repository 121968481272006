import {Component, Input, Output, OnInit, EventEmitter, HostListener, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '../../../services/language.service';
import {SystemtextService} from '../../../services/systemtext.service';


@Component({
    selector: 'app-navbartop',
    templateUrl: './navbartop.component.html',
    styleUrls: ['./navbartop.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbartopComponent implements OnInit {

    userName: string;


    langItemList = [];
    langSettings = {};
    actionInProgress = false;
    mainForm: FormGroup;
    langControl: FormControl;

    settingsText:string;
    logoutText:string;
    orgname:string;

    constructor(protected fb: FormBuilder, protected ls: LanguageService, protected sts:SystemtextService) {
    }

    @Input() NavbarLeftOpen: boolean;


    ngOnInit() {
        let userData = JSON.parse(sessionStorage.getItem('userdata'));
        this.sts.st = JSON.parse(sessionStorage.getItem('basetexts')).menu[localStorage.getItem('lang')];

        this.orgname = JSON.parse(sessionStorage.getItem('orgdata'))[0]["name"];

        this.settingsText = this.sts.c('settings');
        this.logoutText = this.sts.c('logout');


        //console.log("*********** userData:",userData);
        this.userName = (userData != null) ? userData.name : '';

        this.langControl = this.fb.control('',);
        this.mainForm = this.fb.group({
            languageselector: this.langControl,
        });


        this.ls.getLanguages().subscribe(languages => {
            // console.log('languages:', languages);

            this.langItemList = languages;

            var lang = localStorage.getItem('lang');
            var selectedId;
            languages.forEach((language) => {
                if (language.code == lang) {
                    this.mainForm.controls.languageselector.setValue([language]);
                    selectedId = language.id;
                }
            });

            this.langSettings = {
                text: 'Select language',
                enableSearchFilter: false,
                singleSelection: true,
                enableFilterSelectAll: false,
                classes: 'language-selector',
                selectedId: selectedId
            };


        }, error => {
            console.log(error);
        });


    }

    OnItemDeSelect(item: any) {
    }

    @Output() update = new EventEmitter<boolean>();

    setNavbarLeftOpen() {
        this.NavbarLeftOpen = !this.NavbarLeftOpen;
        this.update.emit(this.NavbarLeftOpen);
    }

    @HostListener('dragstart', ['$event']) function (event: MouseEvent) {
        event.preventDefault(); // prevent menu dragstart event
    }
}
