import * as tslib_1 from "tslib";
import { OnInit, ElementRef, EventEmitter, AfterContentInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as WaveSurfer from 'wavesurfer.js';
import { SubtitleFile } from '../../models/SubtitleFile';
import { SubtitleItem } from '../../models/SubtitleItem';
import { TrackItem } from '../../models/Track';
import { TimelineService } from '../../services/timeline.service';
import { SubSink } from 'subsink';
import { FileLoaderService } from '../../services/file-loader.service';
import { Publication } from '../../models/Publication';
export class TimelineComponent {
    constructor(dp, timelineService, fileLoaderService) {
        this.dp = dp;
        this.timelineService = timelineService;
        this.fileLoaderService = fileLoaderService;
        this.subsink = new SubSink();
        this.showClearBtn = false;
        this.prevTime = 0;
        this.time = 0;
        this.maxTime = 0;
        this.maxtimes = [];
        this.rangeEditor = 0;
        this.currentId = 0;
        this.containerWidth = 0;
        this.indicatorContainerWidth = 0;
        this.prevSlideIndex = 0;
        this.currentSlideIndex = 0;
        this.subtitleFiles = null;
        this.subtitles = [];
        this.subtitle = '';
        this.showSubtitle = new EventEmitter();
        this.wavesurfers = [];
        this.currentAudioIndex = 0;
        //// TODO audioClipIndexArray and Next function implementation instead of nextSlide func
        this.audioFiles = [];
        this.audioTracks = [[]];
        this.audioIds = [];
        this.isAudioPlaying = false;
        this.audioOffset = 0;
        this.files = [];
        this.audioContainerWidth = 1172;
        this.isPlaying = 0;
        this.indicatorTracks = null;
    }
    ngOnInit() {
        this.CreateIndicatorTracks();
        this.subsink.add(this.timelineService.contentIndex.subscribe(contentIndex => {
            this.contentIndex = contentIndex;
        }));
        this.subsink.add(this.timelineService.volume.subscribe(vol => {
            this.SetVolume(vol);
        }));
        this.subsink.add(this.timelineService.isPlaying.subscribe(playing => {
            this.isPlaying = playing;
            switch (this.isPlaying) {
                case 0:
                    this.Stop();
                    if (this.indicator) {
                        console.log('Current time : ' + this.time);
                        console.log('Previous time : ' + this.prevTime);
                        console.log('After Stopped : ' + this.isPlaying);
                        this.SetTimelineIndicator();
                        this.time = 0;
                        this.prevTime = 0;
                        this.SetUpIndicator(0);
                        this.SetIndicatorTime();
                    }
                    break;
                case 1:
                    this.Pause();
                    break;
                case 2:
                    break;
            }
        }));
        this.subsink.add(this.timelineService.time.subscribe(t => {
            this.time = t;
            this.SetCurrentTime(t);
            if (this.indicator && this.isPlaying == 2) {
                this.SetUpIndicator(this.prevTime);
                this.SetIndicatorTime();
            }
            if (this.IsAudioTrackExists()) {
                if (!this.isAudioPlaying && this.time >= this.audioOffset && this.time < this.audioTracks[this.currentSlideIndex][1].end && this.isPlaying == 2) {
                    // console.log('TIME AT PLAY: ', this.time);
                    // console.log('OFFSET AT PLAY: ', this.audioOffset);
                    this.Play();
                }
            }
            this.prevTime = this.time;
        }));
        this.subsink.add(this.timelineService.maxTime.subscribe(m => {
            console.log('CALLED SET MAX TIME');
            this.maxTime = m;
            const width = Math.round(this.subTrack.nativeElement.offsetWidth);
            if ((width % 2) == 0) {
                this.containerWidth = width;
            }
            else {
                this.containerWidth = width - 1;
            }
            this.timelineService.SetTime(0, 'From set max time subscription');
            if (this.indicator) {
                this.SetTimelineIndicator();
                this.SetUpIndicator(0);
                this.SetIndicatorTime();
            }
        }));
    }
    ngAfterContentInit() {
        this.containerWidth = this.subTrack.nativeElement.offsetWidth;
        console.log('ContainerWidth: ', this.containerWidth);
    }
    ngOnDestroy() {
        this.subsink.unsubscribe();
    }
    /* #region INDICATOR METHODS*/
    SetUpIndicator(v) {
        const val = this.containerWidth / this.maxTime * v;
        this.indicator.Setup(val);
    }
    SetIndicatorTime() {
        const val = this.containerWidth / this.maxTime * this.time;
        this.indicator.SetTrackPosition(val);
    }
    ClearIndicatorTracks() {
        this.indicatorTracks = [];
    }
    CreateIndicatorTracks() {
        this.indicatorTracks = [];
        for (let i = 0; i < 3; i++) {
            const t = new TrackItem();
            this.indicatorTracks.push(t);
        }
    }
    SetTimelineIndicator() {
        this.indicatorTracks.forEach((track, index) => {
            track.id = index;
            switch (index) {
                case 0:
                    track.start = 0;
                    track.end = 0;
                    break;
                case 1:
                    track.start = 0;
                    track.end = 0;
                    track.isEmpty = false;
                    break;
                case 2:
                    track.start = 0;
                    track.end = this.maxTime;
                    break;
                default:
                    break;
            }
            track.range = this.GetRangePercent(track.start, track.end, this.maxTime);
        });
    }
    /* #endregion */
    Move(time) {
        this.timelineService.SetTime(time, 'From Time Indicator');
    }
    /* #region AUDIO MANAGEMENT */
    SaveAudioConfig(bookId, contentId, uid) {
        const config = {
            audio_config: [],
            subtitlefiles: this.subtitleFiles
        };
        this.audioTracks.forEach(track => {
            const offset = track[1].start;
            const duration = track[1].end - track[1].start;
            let exists = false;
            if (duration !== 0) {
                exists = true;
            }
            config.audio_config.push({ offset: offset, duration: duration, exists: exists });
        });
        const f = JSON.stringify(config);
        const b = [f];
        const file = new File(b, 'config-' + uid + '.json', { type: 'text/json' });
        const key = 'bookAr-config-' + contentId + '[]';
        const data = new FormData();
        data.append(key, file);
        this.fileLoaderService.Prepare(bookId, data).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const res = yield result;
            if (res) {
                this.fileLoaderService.Finalize(bookId, result.uuid).subscribe((r) => {
                    if (r.result) {
                        this.fileLoaderService.IsConfigUploaded(true);
                    }
                });
            }
        }));
    }
    SaveAudioFiles(bookId, contentId, uid) {
        const fd = new FormData();
        this.audioFiles.forEach((audio, index) => {
            if (audio != null) {
                const b = [audio];
                const file = new File(b, 'bookAr-audio_' + index + '-' + uid + '.mp3', { type: 'audio/mp3' });
                fd.append('bookAr-audio_' + index + '-' + contentId + '[]', file);
            }
        });
        this.fileLoaderService.Prepare(bookId, fd).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const res = yield result;
            if (res) {
                console.log(result);
                this.fileLoaderService.Finalize(bookId, result.uuid).subscribe((r) => {
                    console.log(r);
                    if (r.result) {
                        this.fileLoaderService.IsAudioUploaded(true);
                    }
                });
            }
        }));
    }
    SetVolume(newVolume) {
        this.wavesurfers.forEach(element => {
            element.setVolume(newVolume);
        });
    }
    Play() {
        console.log('---------Called Play AUDIO');
        if (this.wavesurfers[this.currentSlideIndex]) {
            this.wavesurfers[this.currentSlideIndex].play();
        }
        this.isAudioPlaying = true;
    }
    Pause() {
        console.log('---------Called Pause AUDIO');
        if (this.wavesurfers[this.currentSlideIndex]) {
            this.wavesurfers[this.currentSlideIndex].pause();
        }
        this.isAudioPlaying = false;
    }
    Stop() {
        console.log('---------Called Stop AUDIO');
        if (this.wavesurfers[this.currentSlideIndex]) {
            this.wavesurfers[this.currentSlideIndex].stop();
        }
        this.isAudioPlaying = false;
    }
    InitializeAudioTracks(count) {
        this.audioFiles = new Array(count);
        this.audioIds = [];
        this.wavesurfers = new Array(count);
        for (let i = 0; i < count; i++) {
            const element = 'audio_' + i;
            this.audioIds.push(element);
        }
        this.audioTracks = new Array(count);
        for (let index = 0; index < count; index++) {
            const trackArray = new Array();
            for (let i = 0; i < 3; i++) {
                const t = new TrackItem();
                t.id = i;
                switch (i) {
                    case 0:
                        t.start = 0;
                        t.end = 0;
                        break;
                    case 1:
                        t.start = 0;
                        t.end = 0;
                        t.isEmpty = false;
                        break;
                    case 2:
                        t.start = 0;
                        t.end = 0;
                        break;
                    default:
                        break;
                }
                t.range = 0;
                trackArray.push(t);
            }
            this.audioTracks[index] = trackArray;
        }
    }
    ResetAudioTrack(i) {
        this.audioTracks[i].forEach(track => {
            track.start = 0;
            track.end = 0;
            track.range = 0;
        });
    }
    SelectAudioFile(f) {
        const fr = new FileReader();
        fr.onloadstart = () => {
            this.audioIsLoading = true;
        };
        fr.onload = (ev) => {
            console.log(f);
            this.LoadToAudioClip(f, fr.result, false, 0);
        };
        fr.readAsDataURL(f);
    }
    LoadAudioFileFromServer(file, slideIndex, { offset, duration }) {
        console.log(file);
        const blob = new Blob([file], { type: 'audio/mp3' });
        this.Load(slideIndex, blob, true, offset, duration);
    }
    Load(slideIndex, blob, fromServer, offset, duration) {
        this.subtitle = '';
        if (duration > this.maxtimes[this.currentAudioIndex]) {
            this.timelineService.SetSlideDuration(duration);
        }
        else {
            this.timelineService.SetOriginalSlideDuration();
        }
        const containerWidth = this.containerWidth / this.maxtimes[this.currentAudioIndex] * duration;
        this.audioTracks[this.currentAudioIndex].forEach((track, index) => {
            switch (index) {
                case 0:
                    track.start = 0;
                    track.end = offset;
                    break;
                case 1:
                    track.start = offset;
                    track.end = duration;
                    track.isEmpty = false;
                    break;
                case 2:
                    track.start = duration;
                    track.end = this.maxtimes[this.currentAudioIndex];
                    break;
                default:
                    break;
            }
            track.range = this.GetRangePercent(track.start, track.end, this.maxtimes[this.currentAudioIndex]);
        });
        this.SetupWaveform(blob, containerWidth, this.currentAudioIndex, fromServer);
    }
    LoadToAudioClip(file, buffer, fromServer, offset) {
        this.audioClip.nativeElement.src = buffer;
        this.audioClip.nativeElement.oncanplaythrough = (ev) => {
            const target = this.audioClip.nativeElement.duration;
            console.log('DurationInMs', target);
            this.currentClipDuration = target;
            if (target > this.maxTime) {
                this.SetMaxTime(target, this.currentSlideIndex);
                this.timelineService.SetSlideDuration(target);
            }
            else {
                this.timelineService.SetOriginalSlideDuration();
            }
            this.audioContainerWidth = this.containerWidth / this.maxTime * target;
            console.log('AudioContainerWidth: ', this.audioContainerWidth);
            console.log('MaxTime: ', this.maxTime);
            this.audioTracks[this.currentSlideIndex].forEach((track, index) => {
                switch (index) {
                    case 0:
                        track.start = 0;
                        track.end = offset;
                        break;
                    case 1:
                        track.start = offset;
                        track.end = this.currentClipDuration;
                        track.isEmpty = false;
                        break;
                    case 2:
                        track.start = this.currentClipDuration;
                        track.end = this.maxTime;
                        break;
                    default:
                        break;
                }
                track.range = this.GetRangePercent(track.start, track.end, this.maxTime);
            });
            setTimeout(() => {
                this.SetupWaveform(file, this.audioContainerWidth, this.currentSlideIndex, fromServer);
            }, 500);
        };
    }
    SetupWaveform(file, maxWidth, slideIndex, fromServer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('LoadAudio', file);
            this.audioFiles[slideIndex] = file;
            const f = Math.round(maxWidth);
            let w = f;
            if ((w % 2) != 0) {
                w--;
            }
            console.log('MAX CANVAS WIDTH ', w);
            this.wavesurfers[slideIndex] = WaveSurfer.create({
                container: '#' + this.audioIds[slideIndex],
                waveColor: '#465372',
                progressColor: '#465372',
                cursorWidth: 0,
                height: 36,
                interact: false,
                maxCanvasWidth: w,
                hideScrollbar: true,
                barHeight: 7
            });
            console.log('wavesurfer created');
            this.wavesurfers[slideIndex].loadBlob(this.audioFiles[slideIndex]);
            const waveReady = this.OnWaveReadyPromise(slideIndex);
            yield waveReady.then(() => {
                this.OnWaveReady(fromServer, slideIndex);
            }).catch(err => {
                console.log(err);
            });
        });
    }
    OnWaveReadyPromise(slideIndex) {
        return new Promise((resolve, reject) => {
            this.wavesurfers[slideIndex].on('ready', (err, data) => {
                if (err) {
                    return reject(err);
                }
                console.log('wave ready');
                resolve();
            });
        });
    }
    OnWaveReady(fromServer, slideIndex) {
        if (fromServer) {
            this.AudioCheckEnd(slideIndex);
            if (slideIndex < this.audioFiles.length - 1) {
                this.currentAudioIndex++;
                this.AudioWrapper(this.currentAudioIndex);
            }
        }
        else {
            this.audioIsLoading = false;
        }
        this.wavesurfers[slideIndex].unAll();
        console.log('wave loaded');
    }
    AudioCheckEnd(slideIndex) {
        if (slideIndex == this.audioFiles.length - 1) {
            this.audioIsLoading = false;
            this.ready = true;
            this.fileLoaderService.IsAudioDownloaded(true);
            this.currentAudioIndex = 0;
        }
    }
    RemoveAudio() {
        console.log('bookAr-audio_' + this.currentSlideIndex);
        const audio = this.audioObjects.find(c => c.form_control_name === 'bookAr-audio_' + this.currentSlideIndex);
        if (confirm('Are you sure?')) {
            console.log(this.audioObjects);
            console.log(audio);
            if (audio != null) {
                const sub = this.fileLoaderService.DeleteAudioFile(this.publication.id, audio.form_control_uid, audio.form_control_name).subscribe(result => {
                    console.log(result);
                    if (result['result'] == 1) {
                        alert('Audio file successfully deleted!');
                    }
                    else {
                        alert('Error during delede operation!');
                    }
                    sub.unsubscribe();
                });
            }
            this.wavesurfers[this.currentSlideIndex].destroy();
            this.ResetAudioTrack(this.currentSlideIndex);
            this.audioFiles[this.currentSlideIndex] = null;
            this.timelineService.SetOriginalSlideDuration();
        }
    }
    /**
     * On move changes the wavesurfer track's current time value
     * @param value Track position value
     */
    SetAudioPosition(value) {
        this.audioOffset = this.audioTracks[this.currentAudioIndex][1].start;
        let time = this.time - this.audioOffset;
        if (time >= this.audioTracks[this.currentAudioIndex][1].end) {
            time = this.audioTracks[this.currentAudioIndex][1].end;
        }
        if (time <= 0) {
            time = 0;
        }
        // console.log('TIME: ',this.time,'OFFSET: ', this.audioOffset,'AUDIO_TIME: ',time);
        // console.log('PlayState: ',this.isPlaying,'Audio PlayState: ', this.isAudioPlaying);
        if (this.wavesurfers[this.currentAudioIndex]) {
            this.wavesurfers[this.currentAudioIndex].setCurrentTime(time);
        }
    }
    /*#endregion*/
    SetMaxTime(maxTime, currentSlideIndex) {
        this.currentSlideIndex = currentSlideIndex;
        this.currentAudioIndex = currentSlideIndex;
        this.Stop();
        if (this.IsAudioTrackExists()) {
            this.audioOffset = this.audioTracks[this.currentAudioIndex][1].start;
            console.log('NEW OFFSET: ', this.audioOffset);
            this.SetAudioPosition(0);
        }
        this.timelineService.SetMaxTime(maxTime);
    }
    IsAudioTrackExists() {
        if (this.audioTracks[this.currentSlideIndex] && this.audioTracks[this.currentSlideIndex][1]) {
            return true;
        }
        return false;
    }
    SetCurrentTime(time) {
        if (this.wavesurfers[this.currentAudioIndex] && this.isPlaying != 2 && this.IsAudioTrackExists()) {
            let audiotime = time - this.audioOffset;
            if (audiotime >= this.audioTracks[this.currentAudioIndex][1].end) {
                audiotime = this.audioTracks[this.currentAudioIndex][1].end;
            }
            if (audiotime <= 0) {
                audiotime = 0;
            }
            this.wavesurfers[this.currentAudioIndex].setCurrentTime(audiotime);
        }
        if (this.subtitleFiles) {
            this.SetText(time);
        }
    }
    // CALLED FROM UNITY PLAYER
    OnAssetsLoaded(slideCount, maxtimes) {
        console.log(maxtimes);
        const obj = JSON.parse(maxtimes);
        obj.durations.forEach(element => {
            this.maxtimes.push(element.value);
        });
        console.log(this.maxtimes);
        console.log(slideCount, 'SLIDECOUNT');
        /// Check for config file
        this.SetTimelineIndicator();
        this.InitializeAudioTracks(slideCount);
        console.log('CONTENT INDEX', this.contentIndex);
        if (this.publication.ar_files_grouped[this.contentIndex].jsons
            && this.publication.ar_files_grouped[this.contentIndex].jsons['config']) {
            const configId = this.publication.ar_files_grouped[this.contentIndex].jsons['config'].id;
            this.audioObjects = this.publication.ar_files_grouped[this.contentIndex].audios;
            console.log(configId);
            // GET Config file
            this.fileLoaderService.GetFile(configId).subscribe((config) => {
                if (config.subtitlefiles) {
                    this.subtitleFiles = config.subtitlefiles;
                }
                this.audioConfig = config.audio_config;
                if (this.audioObjects == null) {
                    this.audioIsLoading = false;
                    this.ready = true;
                    this.fileLoaderService.IsAudioDownloaded(true);
                }
                else {
                    this.GetAudioFiles();
                }
                this.timelineService.SetSaved(false, 'CONFIG ASSET LOADED');
                this.fileLoaderService.IsConfigDownloaded(true);
                this.fileLoaderService.IsSubtitleDownloaded(true);
                this.fileLoaderService.IsLabelDownloaded(true);
            });
        }
        else {
            this.subtitleFiles = [];
            this.audioObjects = [];
            for (let i = 0; i < slideCount; i++) {
                const subFile = new SubtitleFile();
                subFile.index = i;
                subFile.subtitles = [];
                this.subtitleFiles.push(subFile);
            }
            this.audioIsLoading = false;
            this.ready = true;
            this.timelineService.SetSaved(false, 'ASSET LOADED');
            this.fileLoaderService.IsAudioDownloaded(true);
            this.fileLoaderService.IsConfigDownloaded(true);
            this.fileLoaderService.IsSubtitleDownloaded(true);
            this.fileLoaderService.IsLabelDownloaded(true);
        }
    }
    GetAllAudioFiles() {
        var e_1, _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const promiseArray = new Array(this.audioFiles.length);
            try {
                for (var _b = tslib_1.__asyncValues(this.audioObjects), _c; _c = yield _b.next(), !_c.done;) {
                    const audio = _c.value;
                    const audioIndex = audio.form_control_name.split('_'[0])[1];
                    console.log('AudioIndex ', audioIndex);
                    const index = parseInt(audioIndex);
                    console.log(audio.id);
                    const a = this.fileLoaderService.GetAudioFile(audio.id);
                    promiseArray[index] = a;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) yield _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const files = yield Promise.all(promiseArray);
            return files;
        });
    }
    GetAudioFiles() {
        this.GetAllAudioFiles().then(files => {
            this.files = files;
            this.AudioWrapper(0);
        });
    }
    AudioWrapper(index) {
        if (this.files[index] != null) {
            this.LoadAudioFileFromServer(this.files[index], index, this.audioConfig[index]);
        }
        else {
            if (index < this.audioFiles.length - 1) {
                // this.timelineService.NextSlide(true);
                this.currentAudioIndex++;
                // setTimeout(() => {
                this.AudioWrapper(this.currentAudioIndex);
                //  }, 200);
            }
            this.AudioCheckEnd(this.currentAudioIndex);
        }
    }
    /* #region SUBTITLE MANAGEMENT */
    SetSubtitle() {
        if (this.subtitle != null && this.subtitle != '' && this.IsPositionValid()) {
            if (this.currentId < this.subtitleFiles[this.currentSlideIndex].subtitles.length - 1) {
                this.SplitRange(this.currentId);
            }
            else {
                this.SetFirstSubtitle();
            }
        }
    }
    ClearSubtitle() {
        this.RemoveRange();
        this.subtitle = '';
        this.showSubtitle.emit('');
    }
    SaveAllSubtitles(contentId, uid) {
        var e_2, _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const subtitleFiles = [];
            let index = 0;
            try {
                for (var _b = tslib_1.__asyncValues(this.subtitleFiles), _c; _c = yield _b.next(), !_c.done;) {
                    const f = _c.value;
                    const file = yield this.ConstructSubtitle(contentId, index, uid);
                    subtitleFiles.push(file);
                    index++;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) yield _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return subtitleFiles;
        });
    }
    ConstructSubtitle(contentId, index, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let f = '';
            let i = 1;
            this.subtitleFiles[index].subtitles.forEach((sub) => {
                if (!sub.isEmpty) {
                    const startTime = this.FormatTime(sub.start);
                    const endTime = this.FormatTime(sub.end);
                    const part = `${i}\n${startTime} --> ${endTime}\n${sub.text}\n\n`;
                    f += part;
                    i++;
                }
            });
            const b = [f];
            const file = new File(b, 'bookAr-subtitle_' + index + '-' + uid + '.srt', { type: 'text/plain' });
            return file;
        });
    }
    SaveSubtitleFiles(bookId, contentId, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const subtitles = yield this.SaveAllSubtitles(contentId, uid);
            console.log(subtitles);
            const fd = new FormData();
            subtitles.forEach((sub, index) => {
                if (sub != null) {
                    fd.append('bookAr-subtitle_' + index + '-' + contentId + '[]', sub);
                }
            });
            this.fileLoaderService.Prepare(bookId, fd).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const res = yield result;
                if (res) {
                    console.log(result);
                    this.fileLoaderService.Finalize(bookId, result.uuid).subscribe((r) => {
                        console.log(r);
                        if (r.result) {
                            this.fileLoaderService.IsSubtitleUploaded(true);
                        }
                    });
                }
            }));
        });
    }
    FormatTime(t) {
        let time = t;
        let minutes = 0;
        let hours = 0;
        if (t >= 60) {
            minutes = parseInt((t / 60).toFixed(0));
            time = t - (minutes * 60);
        }
        const timeArr = time.toString().split('.');
        const sec = parseInt(timeArr[0]);
        let minuteStr = minutes.toString();
        let secStr = sec.toString();
        if (minutes >= 60) {
            hours = parseInt((minutes / 60).toFixed(0));
            minutes = minutes - (hours * 60);
        }
        if (minutes < 10) {
            minuteStr = '0'.concat(minuteStr);
        }
        let hoursStr = hours.toString();
        if (hours < 10) {
            hoursStr = '0'.concat(hoursStr);
        }
        if (sec < 10) {
            secStr = '0'.concat(secStr);
        }
        let msec = '0000';
        if (timeArr[1]) {
            msec = timeArr[1].substring(0, 4);
        }
        const value = `${hoursStr}:${minuteStr}:${secStr}.${msec}`;
        return value;
    }
    IsPositionValid() {
        let isValid = true;
        this.subtitleFiles[this.currentSlideIndex].subtitles.forEach(sub => {
            if (sub.end == this.time || sub.start == this.time && !sub.isEmpty) {
                isValid = false;
            }
        });
        return isValid;
    }
    SetFirstSubtitle() {
        const subArr = this.subtitleFiles[this.currentSlideIndex].subtitles;
        const sub = new SubtitleItem();
        sub.start = this.time;
        sub.text = this.subtitle;
        sub.isEmpty = (this.subtitle == '') ? true : false;
        if (subArr.length > 0) {
            const last = subArr.length - 1;
            subArr[last].end = sub.start;
            subArr[last].range = this.GetRangePercent(subArr[last].start, subArr[last].end, this.maxTime);
        }
        else {
            const offsetItem = new SubtitleItem();
            offsetItem.start = 0;
            offsetItem.range = this.GetRangePercent(0, this.time, this.maxTime);
            offsetItem.end = this.time;
            offsetItem.color = 'rgb(0,0,0)';
            offsetItem.id = subArr.length;
            subArr.push(offsetItem);
        }
        sub.end = this.maxTime;
        sub.range = this.GetRangePercent(sub.start, sub.end, this.maxTime);
        sub.id = subArr.length;
        subArr.push(sub);
        this.InsertEmptyRange(subArr, sub.end);
        this.subtitleFiles[this.currentSlideIndex].subtitles = subArr;
        console.log(this.subtitleFiles[this.currentSlideIndex].subtitles);
    }
    ChangeText() {
        if (this.subtitle != null && this.subtitle != '') {
            this.subtitleFiles[this.currentSlideIndex].subtitles[this.currentId].text = this.subtitle;
            this.subtitleFiles[this.currentSlideIndex].subtitles[this.currentId].isEmpty = false;
            this.showSubtitle.emit(this.subtitle);
        }
        else {
            this.RemoveRange();
            this.showSubtitle.emit('');
        }
    }
    RemoveRange() {
        let i = 0;
        const newSubtitles = [];
        let allEmpty = true;
        this.subtitleFiles[this.currentSlideIndex].subtitles.forEach((sub, index) => {
            if (index < this.currentId || index > this.currentId + 1) {
                sub.id = i;
                if (!sub.isEmpty) {
                    allEmpty = false;
                }
                newSubtitles.push(sub);
                i++;
            }
        });
        if (allEmpty) {
            this.subtitleFiles[this.currentSlideIndex].subtitles = [];
            this.SetText(this.time);
        }
        else {
            newSubtitles.forEach((sub, index) => {
                if (index != 0 || index != newSubtitles.length - 1) {
                    const prev = newSubtitles[index - 1];
                    const next = newSubtitles[index + 1];
                    if (prev != null) {
                        sub.start = prev.end;
                    }
                    else {
                        sub.start = 0;
                    }
                    if (next != null) {
                        sub.end = next.start;
                    }
                    else {
                        sub.end = this.maxTime;
                    }
                    sub.range = this.GetRangePercent(sub.start, sub.end, this.maxTime);
                }
            });
            this.subtitleFiles[this.currentSlideIndex].subtitles = newSubtitles;
            this.SetText(this.time);
            console.log(this.subtitleFiles[this.currentSlideIndex].subtitles);
        }
    }
    SetCurrentRange(item, prevId) {
        this.rangeEditor = item.start;
        this.rangeMax = item.end;
        if (prevId >= 0) {
            this.leftOffset = this.GetRangePercent(0, this.subtitleFiles[this.currentSlideIndex].subtitles[prevId].start, this.maxTime);
            this.rangeMin = this.subtitleFiles[this.currentSlideIndex].subtitles[prevId].start;
            this.rangeWidth = item.range + this.subtitleFiles[this.currentSlideIndex].subtitles[prevId].range;
        }
        else {
            this.leftOffset = 0;
            this.rangeMin = 0;
            this.rangeWidth = item.range;
        }
    }
    SplitRange(id) {
        const current = this.subtitleFiles[this.currentSlideIndex].subtitles[id];
        const next = this.subtitleFiles[this.currentSlideIndex].subtitles[id + 1];
        const firstItem = new SubtitleItem();
        firstItem.start = current.start;
        firstItem.range = this.GetRangePercent(current.start, this.time, this.maxTime);
        firstItem.end = this.time;
        firstItem.text = current.text;
        firstItem.isEmpty = (current.text == '') ? true : false;
        firstItem.color = current.color;
        const secondtItem = new SubtitleItem();
        secondtItem.start = this.time;
        secondtItem.range = this.GetRangePercent(this.time, current.end, this.maxTime);
        secondtItem.end = current.end;
        secondtItem.text = this.subtitle;
        secondtItem.isEmpty = (this.subtitle == '') ? true : false;
        const newSubtitles = [];
        let i = 0;
        this.subtitleFiles[this.currentSlideIndex].subtitles.forEach((subtitle) => {
            if (this.currentId == i) {
                firstItem.id = i;
                newSubtitles.push(firstItem);
                if (firstItem != null && !firstItem.isEmpty) {
                    i++;
                    this.InsertEmptyRange(newSubtitles, firstItem.end);
                }
                i++;
                secondtItem.id = i;
                newSubtitles.push(secondtItem);
                if (next != null && !next.isEmpty) {
                    i++;
                    this.InsertEmptyRange(newSubtitles, secondtItem.end);
                }
            }
            else {
                subtitle.id = i;
                newSubtitles.push(subtitle);
            }
            i++;
        });
        this.subtitleFiles[this.currentSlideIndex].subtitles = newSubtitles;
        console.log(this.subtitleFiles[this.currentSlideIndex].subtitles);
    }
    InsertEmptyRange(arr, start) {
        const offsetItem = new SubtitleItem();
        offsetItem.start = start;
        offsetItem.isEmpty = true;
        offsetItem.range = this.GetRangePercent(start, start, this.maxTime);
        offsetItem.id = arr.length;
        offsetItem.end = start;
        offsetItem.color = 'rgb(0,0,0)';
        arr.push(offsetItem);
    }
    SetText(value) {
        this.subtitleFiles[this.currentSlideIndex].subtitles.forEach((item, index) => {
            if (value >= item.start && value < item.end) {
                this.subtitle = item.text;
                this.showSubtitle.emit(this.subtitle);
                this.currentId = index;
                this.SetCurrentRange(item, index - 1);
            }
        });
    }
    /* #endregion */
    GetRangePercent(start, end, maxtime) {
        return (end - start) / maxtime * 100;
    }
    GetRandomColor() {
        let color = 'rgb(0,0,0)';
        const r = Math.round(Math.random() * 255);
        const g = Math.round(Math.random() * 255);
        const b = Math.round(Math.random() * 255);
        color = 'rgb(' + r + ',' + g + ',' + b + ')';
        return color;
        // return 'grey';
    }
}
