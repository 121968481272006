<div *ngIf="permissions.Show" class="container">

    <app-gridmodal [girdModalOptions]="girdModalOptions"  [sts]="sts" (gridModalAction)="gridModalAction($event)" (passwordConfirmation)="passwordConfirmation($event)"></app-gridmodal>

    <app-gridactionbtns [actionBtnsDisplay]="actionBtnsDisplay" [permissions]="permissions" [deleteInProgress]="deleteInProgress"
                        (actionUserBySelectedRowsOutput)="actionBySelectedRows($event)" [tableName]="tableName" [formName]="formName" [sts]="sts"></app-gridactionbtns>

    <div class="col-md-12">
        <div class="ag-grid-container">
            <app-gridheader [headerDisplay]="headerDisplay" [columnDefs]="columnDefs" [tableName]="tableName" (onColumnsChangedOutput)="onColumnsChanged($event)"
                            (onPageSizeChangedOutput)="onPageSizeChanged($event)"
                            (onFilterTextBoxChangedOutput)="onFilterTextBoxChanged($event)" [sts]="sts">

            </app-gridheader>
            <ag-grid-angular
                    #agGrid
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    (gridReady)="onGridReady($event)"
                    [frameworkComponents]="frameworkComponents"

            >
            </ag-grid-angular>
        </div>
    </div>
</div>