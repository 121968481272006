import { Subtitle } from "./SubtitleItem";

export interface SubtitleFile{
    index:number
    subtitles:Subtitle[]
}

export class SubtitleFile{
    index:number=0;
    subtitles:Subtitle[]=[]
}