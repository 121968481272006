import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {SystemtextService} from '../services/systemtext.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(private authService: AuthService, private router:Router, private sts: SystemtextService) {
    }

    wt:any;

    ngOnInit() {
        this.wt = this.sts.st[localStorage.getItem('lang')].logout;

        this.authService.logout().subscribe(response => {
            // console.log('You are logged out!');
            this.router.navigate(['/']);
        }, error => {
            console.log(<any>error);
            sessionStorage.clear();
            this.router.navigate(['/']);
        });
    }

}
