import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-formheader',
    templateUrl: './formheader.component.html',
    styleUrls: ['./formheader.component.scss']
})
export class FormheaderComponent implements OnInit {

    @Input() formType;
    @Input() formName;
    @Input() id;
    @Input() sts;

    title: string;

    constructor() {
    }

    ngOnInit() {
        switch (this.formType) {
            case 'create':
                this.title = this.sts.c('form.add_new_title');
                break;
            case 'show':
                this.title = this.sts.c('form.show_title');
                break;
            case 'update':
                this.title = this.sts.c('form.update_title');
                break;
            case 'copy':
                this.title = this.sts.c('form.copy_title');
                break;
        }


    }

}
