import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LanguagecheckService {
    constructor(http) {
        this.http = http;
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }
    getItems(file) {
        // console.log('get languagecheck items');
        return this.http.get(this.url + '/languagecheck/' + file).pipe(map((response) => response), catchError(this.errorHandler));
    }
    errorHandler(error) {
        return throwError(error.error || { message: 'Server Error' });
    }
}
LanguagecheckService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguagecheckService_Factory() { return new LanguagecheckService(i0.ɵɵinject(i1.HttpClient)); }, token: LanguagecheckService, providedIn: "root" });
