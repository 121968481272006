/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./layout/navbar/navbartop.component.ngfactory";
import * as i4 from "./layout/navbar/navbartop.component";
import * as i5 from "@angular/forms";
import * as i6 from "../services/language.service";
import * as i7 from "../services/systemtext.service";
import * as i8 from "./layout/navbar/navbarleft.component.ngfactory";
import * as i9 from "./layout/navbar/navbarleft.component";
import * as i10 from "@angular/router";
import * as i11 from "./services/navbar.service";
import * as i12 from "./services/permissions.service";
import * as i13 from "../../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i14 from "@ngx-loading-bar/core";
import * as i15 from "./layout/modal/modal.component.ngfactory";
import * as i16 from "./layout/modal/modal.component";
import * as i17 from "../pages/login/login.component.ngfactory";
import * as i18 from "../pages/login/login.component";
import * as i19 from "../services/auth.service";
import * as i20 from "./services/admingrid.service";
import * as i21 from "./admin.component";
import * as i22 from "./services/message.service";
import * as i23 from "../services/flags.service";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AdminComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: {}, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { height: "0px", opacity: "0", marginTop: "-10px" }, offset: null }, options: undefined }, { type: 1, expr: "in => out", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }, { type: 1, expr: "out => in", animation: { type: 4, styles: null, timings: "400ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_AdminComponent as RenderType_AdminComponent };
function View_AdminComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "message container"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["role", "alert"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageState; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "alert alert-", _co.messageType, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 2, 0, currVal_2); }); }
export function View_AdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "container-fluid"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-navbartop", [], [[4, "display", null]], [[null, "update"], [null, "dragstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).function($event) !== false);
        ad = (pd_0 && ad);
    } if (("update" === en)) {
        var pd_1 = (_co.setNavbarLeftOpen($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NavbartopComponent_0, i3.RenderType_NavbartopComponent)), i1.ɵdid(5, 114688, null, 0, i4.NavbartopComponent, [i5.FormBuilder, i6.LanguageService, i7.SystemtextService], { NavbarLeftOpen: [0, "NavbarLeftOpen"] }, { update: "update" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-navbarleft", [], [[4, "display", null]], [[null, "update"], [null, "click"], [null, "mouseover"], [null, "mouseleave"], [null, "dragstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.target, $event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onMouseOver() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).onMouseLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragstart" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).function($event) !== false);
        ad = (pd_3 && ad);
    } if (("update" === en)) {
        var pd_4 = (_co.setNavbarLeftOpen($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i8.View_NavbarleftComponent_0, i8.RenderType_NavbarleftComponent)), i1.ɵdid(7, 114688, null, 0, i9.NavbarleftComponent, [i1.ElementRef, i1.Renderer2, i10.Router, i11.NavbarService, i12.PermissionsService], { NavbarLeftOpen: [0, "NavbarLeftOpen"], innerWidth: [1, "innerWidth"] }, { update: "update" }), (_l()(), i1.ɵeld(8, 0, null, null, 13, "main", [["class", "main"]], [[4, "padding", null]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i13.View_LoadingBarComponent_0, i13.RenderType_LoadingBarComponent)), i1.ɵdid(10, 49152, null, 0, i14.LoadingBarComponent, [i14.LoadingBarService], { includeSpinner: [0, "includeSpinner"], includeBar: [1, "includeBar"], fixed: [2, "fixed"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 6, "app-modal", [], null, [[null, "showModalChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showModalChange" === en)) {
        var pd_0 = ((_co.showLoginModal = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ModalComponent_0, i15.RenderType_ModalComponent)), i1.ɵdid(14, 114688, [["modal", 4]], 0, i16.ModalComponent, [], { showModal: [0, "showModal"], hideFooter: [1, "hideFooter"] }, { showModalChange: "showModalChange" }), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "div", [["class", "app-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Token has expired, Please login. "])), (_l()(), i1.ɵeld(17, 0, null, 1, 2, "div", [["class", "app-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-login", [], null, [[null, "hideModal"]], function (_v, en, $event) { var ad = true; if (("hideModal" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_LoginComponent_0, i17.RenderType_LoginComponent)), i1.ɵdid(19, 114688, null, 0, i18.LoginComponent, [i5.FormBuilder, i19.AuthService, i10.Router, i20.AdmingridService, i7.SystemtextService], { isModal: [0, "isModal"], cancelIsLogout: [1, "cancelIsLogout"] }, { hideModal: "hideModal" }), (_l()(), i1.ɵeld(20, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(21, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container-fluid"; var currVal_1 = (_co.NavbarLeftOpen ? "" : "navbar-left-closed"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.NavbarLeftOpen; _ck(_v, 5, 0, currVal_3); var currVal_5 = _co.NavbarLeftOpen; var currVal_6 = _co.innerWidth; _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_9 = false; var currVal_10 = true; var currVal_11 = false; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = (_co.message != null); _ck(_v, 12, 0, currVal_12); var currVal_13 = _co.showLoginModal; var currVal_14 = true; _ck(_v, 14, 0, currVal_13, currVal_14); var currVal_15 = true; var currVal_16 = true; _ck(_v, 19, 0, currVal_15, currVal_16); _ck(_v, 21, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.onlymaincontent ? "none" : ""); _ck(_v, 4, 0, currVal_2); var currVal_4 = (_co.onlymaincontent ? "none" : ""); _ck(_v, 6, 0, currVal_4); var currVal_7 = (_co.onlymaincontent ? "0px" : ""); _ck(_v, 8, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 10).fixed; _ck(_v, 9, 0, currVal_8); }); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 114688, null, 0, i21.AdminComponent, [i22.MessageService, i2.Location, i10.Router, i10.ActivatedRoute, i19.AuthService, i23.FlagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i1.ɵccf("app-admin", i21.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
