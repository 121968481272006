import {Component, OnInit} from '@angular/core';
import {SystemtextService} from '../../services/systemtext.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


    wt:any; // web text
    wtmenu:any;


    constructor(private sts: SystemtextService) {
    }

    ngOnInit() {
        this.wt = this.sts.st[localStorage.getItem('lang')].home;
        this.wtmenu = this.sts.st[localStorage.getItem('lang')].menu;
    }

    scroll(elementId) {
        document.getElementById(elementId).scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }

}
