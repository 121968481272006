/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbaritem.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/pipes/pipes";
import * as i5 from "./navbaritem.component";
var styles_NavbaritemComponent = [i0.styles];
var RenderType_NavbaritemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbaritemComponent, data: {} });
export { RenderType_NavbaritemComponent as RenderType_NavbaritemComponent };
function View_NavbaritemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_NavbaritemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "a", [["class", "nav-link"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵppd(3, 2), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], state: [1, "state"], routerLink: [2, "routerLink"] }, null), i1.ɵpod(5, { onlymaincontent: 0 }), i1.ɵpod(6, { ignoreLoadingBar: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(current)"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "nav-link"; var currVal_4 = (i1.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.navbaritem.link, _co.currentUrl)) ? "active" : ""); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 5, 0, _co.navbaritem.onlymaincontent); var currVal_6 = _ck(_v, 6, 0, (_co.navbaritem.ignoreLoadingBar ? true : false)); var currVal_7 = _co.navbaritem.link; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "navbar-item-", _co.navbaritem.id, ""); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_8 = i1.ɵinlineInterpolate(1, "", _co.navbaritem.icon, ""); _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.navbaritem.name; _ck(_v, 9, 0, currVal_9); }); }
function View_NavbaritemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "a", [["class", "nav-link"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵppd(4, 2), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(6, { ignoreLoadingBar: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "prefix"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "nav-link"; var currVal_4 = (i1.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.link, _co.currentUrl)) ? "active" : ""); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 6, 0, (_v.context.$implicit.ignoreLoadingBar ? true : false)); var currVal_6 = _v.context.$implicit.link; _ck(_v, 5, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = _v.context.$implicit.icon; _ck(_v, 8, 0, currVal_7); var currVal_8 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_8); }); }
function View_NavbaritemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0], [8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "nav flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbaritemComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.navbaritem.child; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "collapse nav-child ", _co.showClass, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "collapse-", _co.navbaritem.id, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NavbaritemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["aria-controls", "collapseExample"], ["class", "nav-link"], ["data-toggle", "collapse"], ["role", "button"]], [[8, "href", 4], [8, "id", 0], [1, "aria-expanded", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(current)"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbaritemComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_5 = (((_co.navbaritem.child == null) ? null : _co.navbaritem.child.length) > 0); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "#collapse-", _co.navbaritem.id, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "navbar-item-", _co.navbaritem.id, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.ariaExpanded, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.navbaritem.icon, ""); _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.navbaritem.name; _ck(_v, 3, 0, currVal_4); }); }
export function View_NavbaritemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.NavlinkHasSameRootPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "navbar-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbaritemComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noChild", 2]], null, 0, null, View_NavbaritemComponent_2)), (_l()(), i1.ɵand(0, [["withChild", 2]], null, 0, null, View_NavbaritemComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.navbaritem.child; var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NavbaritemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbaritem", [], null, null, null, View_NavbaritemComponent_0, RenderType_NavbaritemComponent)), i1.ɵdid(1, 114688, null, 0, i5.NavbaritemComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbaritemComponentNgFactory = i1.ɵccf("app-navbaritem", i5.NavbaritemComponent, View_NavbaritemComponent_Host_0, { navbaritem: "navbaritem" }, {}, []);
export { NavbaritemComponentNgFactory as NavbaritemComponentNgFactory };
