import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { SystemtextService } from 'src/app/services/systemtext.service';
import { FormGroup, FormControl } from '@angular/forms';
import { GridApi, IFilterParams, _ } from 'ag-grid-community';
import { environment } from '../../../../environments/environment';
import { AdmingridService } from '../../services/admingrid.service';
import { isObject } from 'util';

@Component({
    selector: 'app-dropdownfilter',
    templateUrl: './dropdownfilter.component.html',
    styleUrls: ['./dropdownfilter.component.scss']
})
export class DropdownFilter implements IFilterAngularComp {
    formGroup: FormGroup;
    settings: {};

    items: {}[] = [];
    selectedItems: [] = [];
    colDef: {} = {};
    gridApi: GridApi;
    url:string;
    tableName: string;
    colId: string;
    filterLangPrefix: string;
    lastRowId?: number = null;
    actionInProgress:boolean = true;
    gridDataVersion:Number = -1;
    lastGridFiltersJSON:string = '';

    public params: IFilterParams;

    @ViewChild('container', {read: ElementRef,static: false})
    protected container: ElementRef;

    @ViewChild('filterDropdown',{read: ElementRef,static: false})
    protected filterDopdown: ElementRef;

    @ViewChild('applyPanel',{read: ElementRef,static: false})
    protected applyPanel: ElementRef;

    flags:any;

    constructor(private sts: SystemtextService, private admingridService: AdmingridService) {
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }

    agInit(params:IFilterParams): void {
        // console.log('agInit, params:', params);

        this.flags = JSON.parse(sessionStorage.getItem("flags"));

        this.params = params;
        this.colDef = params.colDef;

        this.settings = {
            text: this.sts.c('grid.filter_select_set_items'),
            selectAllText: this.sts.c('grid.filter_select_all'),
            unSelectAllText: this.sts.c('grid.filter_deselect_all'),
            filterSelectAllText: this.sts.c('grid.filter_select_all_results'),
            filterUnSelectAllText: this.sts.c('grid.filter_deselect_all_results'),
            searchPlaceholderText: this.sts.c('grid.filter_search_placeholder_text'),
            noDataLabel: this.sts.c('grid.filter_no_data_label'),
            classes: 'dropdown-filter',
            badgeShowLimit: 1,
            singleSelection: this.colDef['filterSingleSelection'] ? true : false,
            enableSearchFilter: true,
        };

        this.formGroup = new FormGroup({
            aggridinput: new FormControl()
        });


        this.gridApi = params.api;
        this.colId = this.colDef['colId'];
        this.filterLangPrefix = this.colDef['filterLangPrefix'];

        var filterParams: {} = this.colDef['filterParams'];
        this.tableName = filterParams['tableName'];
    }

    isFilterActive(): boolean {
        return !this.isObjectEmpty(this.selectedItems);
    }
    doesFilterPass(params: import("ag-grid-community").IDoesFilterPassParams): boolean {
        // console.log('doesFilterPass', params);
        throw new Error("Method not implemented.");
    }
    getModel() {
        // console.log('getModel');
        if(!this.isFilterActive()) return null;
        return {
            type: 'set',
            filter: this.selectedItems,
            filterType: 'set'
        };
    }
    setModel(model: any): void {
        // console.log('setModel');
        if(typeof(model) === 'object')
        {
            if(!this.isObjectEmpty(model) && model['type'] != 'set') throw new Error('model.type must be equal to "set"');
            this.selectedItems = model && model.hasOwnProperty('filter') ? model.filter : [];
            // TODO: Reinitalizaition?
        }
        else
        {
            throw new Error('model is not Object');
        }
    }
    afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
        // console.log('afterGuiAttached');

        let f = this.admingridService.getFilters();
        let gridFilters = Object.assign(
            {},
            this.getProperties(f, ['quickfilter', 'filterModel']),
        );
        this.removeProperties(gridFilters['filterModel'], [this.colId]);
        let gridFiltersJSON = JSON.stringify(gridFilters);

        if(this.lastGridFiltersJSON != gridFiltersJSON) {
            // console.log(this.lastGridFiltersJSON, ' != ', gridFiltersJSON);
            this.lastGridFiltersJSON = gridFiltersJSON;
            this.updateDropdownItems();
        }
    }

    removeProperties(obj:{}, props:string[]): {} {
        if(obj == null) return obj;

        props.forEach(key => {
            if (obj.hasOwnProperty(key)) delete obj[key];
        });
        return obj;;
    }
    getProperties(obj:{}, props:string[], copy:boolean = false): {} {
        let result = {};
        props.forEach(key => {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if(copy && isObject(value))
                    result[key] = _.cloneObject(obj);
                else
                    result[key] = value;
            }
        });
        return result;
    }

    onNewRowsLoaded?(): void {
        // TODO: Update available items.
        // console.log('onNewRowsLoaded');
    }
    getFrameworkComponentInstance?() {
        return this;
    }
    // getModelAsString?(model: any): string {
    //     throw new Error("Method not implemented.");
    // }
    // onFloatingFilterChanged?(change: any): void {
    //     throw new Error("Method not implemented.");
    // }



    /* Non-interface methods */



    onItemSelect(selectedItem:{}):void
    {
        // console.log('onItemSelect', selectedItem);
        this.onSelectionChanged();
    }
    onSelectAll(currentSelection:{}[]):void
    {
        // console.log('onSelectAll', currentSelection);
        this.onSelectionChanged();
    }
    onItemDeSelect(deselectedItem:{}):void
    {
        // console.log('onItemDeSelect', deselectedItem);
        this.onSelectionChanged();
    }
    onDeSelectAll(currentSelection:{}[]):void
    {
        // console.log('onDeSelectAll', currentSelection);
        this.onSelectionChanged();
    }
    protected onSelectionChanged(): void {
        if(!this.params['applyButton']) this.params.filterChangedCallback();
    }

    protected onClearFilterButtonClick(event:MouseEvent): void {
        // console.log('onClearFilterButtonClick', event);
        this.selectedItems = [];
        this.params.filterChangedCallback();
    }

    protected onApplyFilterButtonClick(event:MouseEvent): void {
        // console.log('onApplyFilterButtonClick', event);
        this.params.filterChangedCallback();
    }

    protected onFilterMenuOpen(event: any): void {
        // console.log('onFilterMenuOpen', event);
        // console.log(this.filterDopdown);
        this.resizeFilterContainer();
    }

    protected onFilterMenuClose(event: any): void {
        // console.log('onFilterMenuClose', event);
        this.resizeFilterContainer();
    }



    protected resizeFilterContainer(): void {
        // console.log('resizeFilterContainer');
        setTimeout( () => {
            var dropDownField = this.filterDopdown.nativeElement;
            var height1 = dropDownField.getBoundingClientRect().height;
            var dropDownList = dropDownField.getElementsByClassName('dropdown-list');
            var height2 = dropDownList.length ? dropDownList[0].getBoundingClientRect().height : 0;
            var applyPanel = this.applyPanel.nativeElement;
            var height3 = applyPanel ? applyPanel.getBoundingClientRect().height : 0;

            if(applyPanel)
                applyPanel.style.marginTop = `${height2 + 8}px`;

            //this.container.nativeElement.style.height = `${height1 + height2 + height3}px`;
        }, 1);
    }


    /**
     * Updates the dropdown list items.
     */
    protected updateDropdownItems(): void {
        // console.log('updateDropdownItems');

        this.actionInProgress = true;

        this.admingridService.getUniqueValues(this.colId, this.lastRowId)
        .subscribe({
            error: error => {
                this.actionInProgress = false;
            },
            next: result => {
                this.translateData(result);
                this.items = result["items"];
                this.actionInProgress = false;
            },
        });
    }

    translateData(data:{}) {
        // console.log('translate');

        if(!data.hasOwnProperty('translateFrom') || !data.hasOwnProperty('items')) return;

        var key:string = data['translateFrom'];
        var items:{}[] = data['items'];
        items.forEach(item => {
            if(this.filterLangPrefix) {
                item['itemName'] = this.sts.c(this.filterLangPrefix+item[key]);
            } else {
                item['itemName'] = this.sts.c(item[key]);
                // console.log("item[key]",item[key]);
                if(this.colId == 'b.language') {
                    let code = item[key].split(".");
                    item['code'] = code[1];
                }
            }
        });
    }

    /* Utility functions */

    /**
     * Determines if an object is empty.
     * Returns ture when the object is null or empty.
     * @param obj
     */
    protected isObjectEmpty(obj:Object): boolean {
        if(obj == null) return true;

        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }

        return true;
    }

}
