import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-formbuttons',
  templateUrl: './formbuttons.component.html',
  styleUrls: ['./formbuttons.component.scss']
})
export class FormbuttonsComponent implements OnInit {

    @Input() formType;
    @Input() permissions;
    @Input() actionInProgress;
    @Input() mainForm;
    @Input() tableName;
    @Input() sts;
    @Input() fileUploadOptions;
    @Input() readonly;
    @Input() showCancel = true;

    @Output() cancelUploadFiles = new EventEmitter<any>();

    form_save_failed:string;
    cancel:string;
    update:string;
    save:string;
    copy:string;

  constructor() { }

  ngOnInit() {
        this.form_save_failed = this.sts.c('form.form_save_failed');
        this.cancel = this.sts.c('form.cancel');
        this.update = this.sts.c('form.update');
        this.save = this.sts.c('form.save');
        this.copy = this.sts.c('form.copy');
  }

  public cancelUploadFilesClick() {
      this.cancelUploadFiles.emit();
  }
}


