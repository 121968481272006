import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-formswitch',
    templateUrl: './formswitch.component.html',
    styleUrls: ['./formswitch.component.scss']
})
export class FormswitchComponent implements OnInit {

    @Input() parentFormGroup;
    @Input() control;
    @Input() permissions;
    @Input() inputName;
    @Input() title;
    @Input() higherRoleIsNeeded;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() sts;
    @Input() required;
    @Input() toolTipOptions: any;

    switchValue:boolean = false;

    // ui-switch properties
    @Input() size;

    constructor() {
    }

    ngOnInit() {
        this.title = this.sts.c(this.title);
        this.switchValue = this.parentFormGroup.controls[this.inputName].value === true ? true : false;
    }

    setValue() {
        this.switchValue = !this.switchValue;
        this.setControl();
    }

    setControl() {
        this.parentFormGroup.controls[this.inputName].patchValue(this.switchValue);
        // console.log(this.inputName+" setControl value: ", this.parentFormGroup.controls[this.inputName] );
    }


}
