<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <div class="menu-container">

    <div class="menu">
      <!--
      <a class="btn btn-secondary btn-sm btn-ripple mx-2" routerLink="/home">{{wt.home}}</a>
      -->
      <a class="btn btn-primary btn-sm btn-ripple mx-2" routerLink="/admin/dashboard">{{adminBtnText}}</a>
      <!--
      <a class="btn btn-light btn-sm btn-ripple mx-2" routerLink="/login">{{wt.login}}</a>
      <a class="btn btn-warning btn-sm btn-ripple mx-2" routerLink="/register">{{wt.register}}</a>
      <a class="btn btn-primary btn-sm btn-ripple mx-2" routerLink="/designtest">{{wt.design_test}}</a>
      -->
    </div>

    <div class="language-container">
      <!--
      Így volt régen, de actionInProgress nincs valamiért már.
      <app-formmultiselect [itemList]="langItemList" [itemSettings]="langSettings"
                           [title]="''" [formControlNameString]="'languageselector'"
                           [control]="langControl"
                           [parentFormGroup]="mainForm" [actionInProgress]="actionInProgress"></app-formmultiselect>
      -->
      <app-formmultiselect [itemList]="langItemList" [itemSettings]="langSettings"
                           [title]="''" [formControlNameString]="'languageselector'"
                           [control]="langControl"
                           [parentFormGroup]="mainForm" ></app-formmultiselect>

    </div>
  </div>
</nav>