import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LanguageService} from './services/language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'frontend';

    constructor(private httpClient: HttpClient, private ls: LanguageService) {

        if (!localStorage.getItem('lang')) {
            ls.getLanguageCodes().subscribe((codes) => {
                var browserLang = navigator.language;
                var subBrowserLang = browserLang.substr(0, 2);
                if (codes.indexOf(subBrowserLang) > -1) {
                    localStorage.setItem('lang', subBrowserLang);
                } else {
                    localStorage.setItem('lang', 'en');
                }
            });
        }
    }
}
