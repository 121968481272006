import {Component, OnInit} from '@angular/core';
import {PermissionsService} from '../../services/permissions.service';
import {FormArray} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {DashboardService} from "../../services/dashboard.service";
import {MessageService} from "../../services/message.service";
import {GoogleChartsModule} from 'angular-google-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public permissions;
  public tableName: string;
  public booksData: any;
  public readyToShow: boolean = false;
  public rootUrl = `${environment.backendApiUrl}${environment.backendApiVersion}`;
  public isSuperadmin = JSON.parse(sessionStorage.getItem('userroles')).indexOf("Superadmin") != -1;
  public st;

  chartTitle = ''; // 'Average Temperatures of Cities';
  chartType = 'ColumnChart';
  chartOptions = {
    bar: {
      groupWidth: 20
    },
    width: '100%',
    chartArea: {left: 20, top: 5, right: 5, bottom: 20, width: "100%", height: "100%"},
    hAxis: {
      format: 'yyyy.MM.dd.',
      textStyle: {fontSize: 10}
    },
    vAxis: {
      // title: 'Temperature'
      textStyle: {fontSize: 10},
      format: '0'
    },
    legend: {position: 'none'},
    pointSize: 10,
  };

  colNames: Array<any> = ["Date", "Piece", { role: 'tooltip', type: 'string' }]

  constructor(private permissionsService: PermissionsService, private dashboardService: DashboardService, private messageService: MessageService) {
  }

  ngOnInit() {
    this.tableName = 'dashboard';
    this.permissions = this.permissionsService.getPermissions(this.tableName);

    this.dashboardService.getBooksData().subscribe(data => {

      this.st = data.st;

      // Test data
      /*
      data = {
        'orgs': {
          'Multilearn Kft.': [
            {
              bookData: {id: 1, name: "Teszt könyv"},
              usersData:
                [
                  {only_date: "2020-10-08", piece: 1},
                  {only_date: "2020-10-09", piece: 8},
                  {only_date: "2020-10-12", piece: 2},
                  {only_date: "2020-10-13", piece: 3},
                  {only_date: "2020-10-15", piece: 4},
                  {only_date: "2020-10-19", piece: 2},
                  {only_date: "2020-10-21", piece: 7},
                  {only_date: "2020-10-22", piece: 12},
                  {only_date: "2020-10-27", piece: 1},
                  {only_date: "2020-11-08", piece: 1},
                  {only_date: "2020-11-09", piece: 8},
                  {only_date: "2020-11-12", piece: 2},
                  {only_date: "2020-11-13", piece: 3},
                  {only_date: "2020-11-15", piece: 4},
                  {only_date: "2020-11-19", piece: 2},
                  {only_date: "2020-11-21", piece: 7},
                  {only_date: "2020-11-22", piece: 12},
                  {only_date: "2020-11-27", piece: 1},
                  {only_date: "2020-12-08", piece: 3},
                  {only_date: "2020-12-09", piece: 4},
                  {only_date: "2020-12-12", piece: 1},
                  {only_date: "2020-12-13", piece: 12},
                  {only_date: "2020-12-15", piece: 2},
                  {only_date: "2020-12-19", piece: 3},
                  {only_date: "2020-12-21", piece: 1},
                  {only_date: "2020-12-22", piece: 2},
                  {only_date: "2020-12-27", piece: 7},
                  {only_date: "2021-03-09", piece: 8},
                  {only_date: "2021-05-12", piece: 2},
                  {only_date: "2021-05-13", piece: 3},
                  {only_date: "2021-07-15", piece: 4},
                  {only_date: "2021-12-15", piece: 2},
                  {only_date: "2021-12-19", piece: 3},
                  {only_date: "2021-12-21", piece: 1},
                  {only_date: "2021-12-22", piece: 2},
                  {only_date: "2021-12-27", piece: 7},
                ]
            }
          ]
        }
      };
       */

      // console.log("get books data :", data);
      var orgs = data.orgs;
      for (let key in orgs) {
        let book = orgs[key];
        book.forEach(function (bv, bi) {
          orgs[key][bi]["chartData"] = [];
          orgs[key][bi]["usersData"].forEach(function (udv, udi) {
            var dateSplit = udv.only_date.split("-");
            var tooltip = parseInt(udv.piece)+'\r\n'+udv.only_date.split("-").join(".")+".";
            orgs[key][bi]["chartData"].push([new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2])), parseInt(udv.piece), tooltip])
          })
          orgs[key][bi]["hasData"] = orgs[key][bi]["chartData"].length > 0 ? true : false;
        })
      }
      this.booksData = orgs;
      this.readyToShow = true;

    }, error => {
      console.log(<any>error);
      var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
      this.messageService.sendMessage({text: text, type: 'danger'});
    });

  }

}
