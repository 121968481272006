import { Component, OnInit } from '@angular/core';
import {AdmingridComponent} from '../../controllers/admingrid/admingrid.component';
import {DropdownFilter} from '../../controllers/admingrid/dropdownfilter.component';

@Component({
  selector: 'app-shoppings',
    templateUrl: '../grid.base.template.html',
    styleUrls: ['../grid.base.template.scss']
})
export class ShoppingsComponent extends AdmingridComponent implements OnInit {



    actionBtnsDisplay = {
        Show: false,
        Create: JSON.parse(sessionStorage.getItem('userroles'))[0] == "Superadmin" ? true :  false,
        Edit: false,
        Delete: JSON.parse(sessionStorage.getItem('userroles'))[0] == "Superadmin" ? true : false,
    };

    headerDisplay = {
        Columns: true,
        PageSize: true,
        QuickFilter: true,
    };


    colDef_id = {headerName: 'Id', field: 'id', colId: 's.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter'};
    colDef_seller_name = {headerName: '', field: 'seller_name', colId:'seller.name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_buyer_name = {headerName: '', field: 'buyer_name', colId:'buyer.name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_book_name = {headerName: '', field: 'book_name', colId:'books.name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_book_language = {headerName: '', field: 'book_language', colId:'books.language', sortable: true, resizable: true, show: true, filter: 'DropdownFilter'};
    colDef_created_at = {headerName: '', field: 'created_at', colId: 'u.created_at', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter'};

    columnDefs = [this.colDef_id, this.colDef_seller_name, this.colDef_buyer_name, this.colDef_book_name, this.colDef_book_language, this.colDef_created_at];
    firstLoadDone:boolean = false;


    ngOnInit() {

        super.ngOnInit();

        this.frameworkComponents = { DropdownFilter: DropdownFilter };

        this.tableName = 'shoppings';
        this.formName = 'Shoping';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;


        this.messageService.getStatus().subscribe(
            message => {
                if (message) {
                    if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {


                        this.extraDeleteConfirmText = "shoppings.delete_alert_text";

                        this.sts.replace('grid.add_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.show_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.modify_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.copy_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.delete_btn', [{'find': ':item', 'replace': 'users.user'}]);

                        this.setColNameByLang(this.colDef_seller_name, 'shoppings.seller', message.gridOptions);
                        this.setColNameByLang(this.colDef_buyer_name, 'shoppings.buyer', message.gridOptions);
                        this.setColNameByLang(this.colDef_created_at, 'shoppings.created', message.gridOptions);
                        this.setColNameByLang(this.colDef_book_name, 'books.book', message.gridOptions);
                        this.setColNameByLang(this.colDef_book_language, 'books.language', message.gridOptions);

                        message.gridOptions.api.refreshHeader();
                        this.onColumnsChanged();

                        this.firstLoadDone = true;

                    }
                }
            }
        );
    }

    changeValueByLang(items) {
        items.forEach((item) => {
            item.book_language = this.sts.c(item.book_language);
        });
    }


}



