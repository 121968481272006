/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ar-label/ar-label.component.ngfactory";
import * as i3 from "../ar-label/ar-label.component";
import * as i4 from "@angular/common";
import * as i5 from "./label-manager.component";
import * as i6 from "../../services/timeline.service";
import * as i7 from "../../services/file-loader.service";
var styles_LabelManagerComponent = [i0.styles];
var RenderType_LabelManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelManagerComponent, data: {} });
export { RenderType_LabelManagerComponent as RenderType_LabelManagerComponent };
function View_LabelManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ar-label", [], null, [[null, "onLabelTextChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLabelTextChange" === en)) {
        var pd_0 = (_co.ChangeLabelText($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ArLabelComponent_0, i2.RenderType_ArLabelComponent)), i1.ɵdid(1, 1163264, null, 0, i3.ArLabelComponent, [], { label: [0, "label"], index: [1, "index"], isPlaying: [2, "isPlaying"] }, { onLabelTextChange: "onLabelTextChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; var currVal_2 = _co.isPlaying; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LabelManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "nav d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabelManagerComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labels; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LabelManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabelManagerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LabelManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-label-manager", [], null, null, null, View_LabelManagerComponent_0, RenderType_LabelManagerComponent)), i1.ɵdid(1, 114688, null, 0, i5.LabelManagerComponent, [i6.TimelineService, i7.FileLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LabelManagerComponentNgFactory = i1.ɵccf("app-label-manager", i5.LabelManagerComponent, View_LabelManagerComponent_Host_0, { labelsId: "labelsId" }, { onLabelChange: "onLabelChange" }, []);
export { LabelManagerComponentNgFactory as LabelManagerComponentNgFactory };
