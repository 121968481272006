import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FileLoaderService {
    constructor(http) {
        this.http = http;
        this._downloadState = new BehaviorSubject(true);
        this.downloadState = this._downloadState.asObservable();
        this._isAudioFilesDownloaded = new BehaviorSubject(false);
        this._isSubtitleFilesDownloaded = new BehaviorSubject(false);
        this._isLabelFileDownloaded = new BehaviorSubject(false);
        this._isConfigDownloaded = new BehaviorSubject(false);
        this.isAudioFilesDownloaded = this._isAudioFilesDownloaded.asObservable();
        this.isSubtitleFilesDownloaded = this._isSubtitleFilesDownloaded.asObservable();
        this.isLabelFileDownloaded = this._isLabelFileDownloaded.asObservable();
        this.isConfigDownloaded = this._isConfigDownloaded.asObservable();
        this._uploadState = new BehaviorSubject(true);
        this.uploadState = this._uploadState.asObservable();
        this._isAudioFilesUploaded = new BehaviorSubject(true);
        this._isSubtitleFilesUploaded = new BehaviorSubject(true);
        this._isLabelFileUploaded = new BehaviorSubject(true);
        this._isConfigUploaded = new BehaviorSubject(true);
        this.isAudioFilesUploaded = this._isAudioFilesUploaded.asObservable();
        this.isSubtitleFilesUploaded = this._isSubtitleFilesUploaded.asObservable();
        this.isLabelFileUploaded = this._isLabelFileUploaded.asObservable();
        this.isConfigUploaded = this._isConfigUploaded.asObservable();
        this.url = this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
        this.isAudioFilesUploaded.subscribe();
        this.isSubtitleFilesUploaded.subscribe();
        this.isLabelFileUploaded.subscribe();
        const uploadComleted = combineLatest([
            this.isAudioFilesUploaded,
            this.isSubtitleFilesUploaded,
            this.isLabelFileUploaded,
            this.isConfigUploaded
        ]);
        const downloadComleted = combineLatest([
            this.isAudioFilesDownloaded,
            this.isSubtitleFilesDownloaded,
            this.isLabelFileDownloaded,
            this.isConfigDownloaded
        ]);
        uploadComleted.subscribe(result => {
            const comp = result.every(r => r === true);
            console.log('File upload status : ', comp);
            this._uploadState.next(comp);
        });
        downloadComleted.subscribe(result => {
            const comp = result.every(r => r === true);
            console.log('File download status : ', comp);
            this._downloadState.next(comp);
        });
    }
    Prepare(id, fd) {
        const token = sessionStorage.getItem('token');
        const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
        return this.http.post(this.url + '/books/files/' + id, fd, { headers });
    }
    Finalize(bookId, uuid) {
        const token = sessionStorage.getItem('token');
        const params = new HttpParams().append('_file_data_uuid', uuid);
        const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
        return this.http.get(this.url + '/books/finalizearadditionalfiles/' + bookId, { headers, params });
    }
    CreateZip(bookId) {
        const token = sessionStorage.getItem('token');
        const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
        return this.http.get(this.url + '/books/createzipafterfinalizearadditionalfiles/' + bookId, { headers });
    }
    GetFile(id) {
        return this.http.get(this.url + '/files/getbyid/' + id);
    }
    DeleteAudioFile(bookId, uid, name) {
        const token = sessionStorage.getItem('token');
        const params = new HttpParams().append('form_control_uid', uid).append('form_control_name', name);
        const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
        return this.http.delete(this.url + '/books/deletearadditionalfiles/' + bookId, { headers, params });
    }
    GetAudioFile(id) {
        const token = sessionStorage.getItem('token');
        const headers = new HttpHeaders('Content-Type: audio/mpeg3;audio/x-mpeg-3;video/mpeg;video/x-mpeg;text/xml');
        headers.append('Authorization', 'Bearer ' + token);
        return this.http.get(this.url + '/files/getbyid/' + id, { headers, responseType: 'arraybuffer' }).toPromise();
    }
    IsAudioUploaded(uploaded) {
        this._isAudioFilesUploaded.next(uploaded);
    }
    IsSubtitleUploaded(uploaded) {
        this._isSubtitleFilesUploaded.next(uploaded);
    }
    IsLabelUploaded(uploaded) {
        this._isLabelFileUploaded.next(uploaded);
    }
    IsConfigUploaded(uploaded) {
        this._isConfigUploaded.next(uploaded);
    }
    IsAudioDownloaded(downloaded) {
        this._isAudioFilesDownloaded.next(downloaded);
    }
    IsSubtitleDownloaded(downloaded) {
        this._isSubtitleFilesDownloaded.next(downloaded);
    }
    IsLabelDownloaded(downloaded) {
        this._isLabelFileDownloaded.next(downloaded);
    }
    IsConfigDownloaded(downloaded) {
        this._isConfigDownloaded.next(downloaded);
    }
}
FileLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileLoaderService_Factory() { return new FileLoaderService(i0.ɵɵinject(i1.HttpClient)); }, token: FileLoaderService, providedIn: "root" });
