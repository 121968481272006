<div class="form-group text-left" [formGroup]="parentFormGroup">
  <label for="{{inputName}}" class="col-lg-12 control-label">
      {{title}} <app-tooltip *ngIf="toolTipOptions && toolTipOptions.text != ''" [toolTipOptions]="toolTipOptions"></app-tooltip>
      <span *ngIf="required === true">*</span>
  </label>
  <div class="col-lg-12">
      <textarea class="form-control" id="{{inputName}}" name="{{inputName}}"
                placeholder="{{title}}" formControlName="{{inputName}}"
                [readonly]="!permissions.Edit || higherRoleIsNeeded || actionInProgress || readonly"
                [maxlength]="inputMaxLength"
      >
      </textarea>
      <div *ngIf="control.dirty && control.hasError('required')"
           class="alert alert-danger">Field is required
      </div>
      <div *ngIf="control.dirty && control.hasError('minlength')"
           class="alert alert-danger">Should have at least {{inputMinLength}} characters
      </div>
      <div *ngIf="control.hasError('validateFailed')">
          <div class="alert alert-danger" *ngFor="let message of control.errors.validateFailed">
              {{ message }}
          </div>
      </div>
  </div>
</div>
