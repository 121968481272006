/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbarleft.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbaritem.component.ngfactory";
import * as i3 from "./navbaritem.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./navbarleft.component";
import * as i7 from "../../services/navbar.service";
import * as i8 from "../../services/permissions.service";
var styles_NavbarleftComponent = [i0.styles];
var RenderType_NavbarleftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarleftComponent, data: {} });
export { RenderType_NavbarleftComponent as RenderType_NavbarleftComponent };
function View_NavbarleftComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbaritem", [], null, null, null, i2.View_NavbaritemComponent_0, i2.RenderType_NavbaritemComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavbaritemComponent, [i4.Router], { navbaritem: [0, "navbaritem"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavbarleftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nav", [["class", "sidebar"], ["data-simplebar", ""]], null, [[null, "transitionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transitionend" === en)) {
        var pd_0 = (_co.sidebarTransitionEnd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "nav flex-column overflow-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarleftComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar"; var currVal_1 = (_co.NavbarLeftOpen ? "" : "closed"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.navbaritems; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_NavbarleftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbarleft", [], null, [[null, "click"], [null, "mouseover"], [null, "mouseleave"], [null, "dragstart"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target, $event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseOver() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onMouseLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragstart" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).function($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_NavbarleftComponent_0, RenderType_NavbarleftComponent)), i1.ɵdid(1, 114688, null, 0, i6.NavbarleftComponent, [i1.ElementRef, i1.Renderer2, i4.Router, i7.NavbarService, i8.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarleftComponentNgFactory = i1.ɵccf("app-navbarleft", i6.NavbarleftComponent, View_NavbarleftComponent_Host_0, { NavbarLeftOpen: "NavbarLeftOpen", innerWidth: "innerWidth" }, { update: "update" }, []);
export { NavbarleftComponentNgFactory as NavbarleftComponentNgFactory };
