import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if(environment.type == 'dev' || environment.type == 'test') {
  document.title = environment.title;
  document.body.classList.add(environment.type);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
