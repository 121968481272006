<app-subpageheader [text]="'Ismerje meg a technológiát'" [imgfolder]="'technologia'"></app-subpageheader>

<div class="container">
  <div class="row">
    <div class="col">
      <div class="desc text-lg-left text-center">
        Tekintse meg a hibrid könyvekhez tartozó mobilapplikációnk működését windows android és apple platformokon.
      </div>
    </div>
  </div>
</div>
<!-- <app-prototyperow></app-prototyperow> -->


<div class="container">

  <div class="row">
    <div class="col-lg-4 col-xl-8 text-center d-flex align-items-center justify-content-center my-4">
      <iframe class="youtube big" src="https://www.youtube.com/embed/3UOpjIwQH9k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-8 col-xl-4 text-center d-flex align-items-center justify-content-center">
      <div class="row">
        <div class="col-lg-6 col-xl-12 text-center d-flex align-items-center justify-content-center my-4 my-lg-2">
          <iframe class="youtube" src="https://www.youtube.com/embed/6c4eG1i1-no" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-lg-6 col-xl-12 text-center d-flex align-items-center justify-content-center my-4 my-lg-2">
          <iframe class="youtube" src="https://www.youtube.com/embed/NVYiimvoRag" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p><a href="assets/download/Open_source_felhasznaloi_licensz_szerzodes.docx" target="_blank">Open Source felhasználói szerződés</a></p>
      <p><a href="https://vekop.multilearn.hu/issues/ar-rendszer/-/tree/038ae792cc3db7606f05288d93e9b81d3fc26918" target="_blank">Open Source szoftverek letöltése</a></p>
      <p><a href="assets/download/open_source_applikacio.zip" target="_blank">Open Source applikáció letöltése</a></p>
    </div>
  </div>

</div>

<app-footer></app-footer>
