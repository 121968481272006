import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {SystemtextService} from '../../services/systemtext.service';

export class messageItem {
    text: string;
    type: string;
}

export class statusItem {
    name: string;
    status: string;
    form: string;
    gridOptions: any;
}

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private router: Router, private sts:SystemtextService) {
    }

    private subject = new Subject<any>();
    private statusSubject = new Subject<any>();

    sendMessage(message: messageItem) {

        if(message.text === "The token has been blacklisted") {
            // session storage multi tab logout
            sessionStorage.clear();
            window.location.href ='/login';
        }
        var tokenHasExpired = message.text === 'Token has expired' ? true : false;
        if (message.text.indexOf('Foreign key violation') != -1) {
            console.log('Foreign key violation: ', message.text);
            message.text = this.sts.c('grid.request_failed_data_in_use');
        }
        this.subject.next({
            text: message.text,
            type: message.type,
            tokenHasExpired: tokenHasExpired,
        });
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendStatus(message: statusItem) {
        this.statusSubject.next({
            name: message.name,
            status: message.status,
            form: message.form,
            gridOptions: message.gridOptions,
        });
    }

    getStatus(): Observable<any> {
        return this.statusSubject.asObservable();
    }
}
