<header class="masthead" id="masthead">
  <div class="container">
    <div class="intro-text">
      <div class="intro-heading text-uppercase">Ismerje meg kiterjesztett <br/><span class="red-highlight">hibridkönyv</span> rendszerünket</div>
      <div class="intro-lead-in">Ismeretterjesztés, marketing, oktatás</div>
      <div class="w-100 text-lg-right text-center">
        <img class="book-png" alt="book" src="/assets/img/book.png">
      </div>
    </div>

  </div>
</header>

<div class="container">

  <section class="page-section" id="services">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-right sz2020">
          <img style="max-width: 100%"  src="assets/img/sz2020.png" >
        </div>
        <div class="col-lg-12 text-center">
          <h2 class="section-heading text-uppercase">SZOLGÁLTATÁSOK</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 my-4">
          <div class="card h-100 text-center border-0"> <!-- full height -->
            <div>
            <span class="fa-stack fa-4x">
              <i class="fas fa-circle fa-stack-2x blue-circle"></i>
              <i class="fas fa-book fa-stack-1x fa-inverse"></i>
            </span>
            </div>
            <h4 class="service-heading blue-text">Prototípus könyveink</h4>
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="text-muted">Tapasztalja meg a kiterjesztett valóság élményét</p>
            </div>
            <div class=""><a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger blue-btn" routerLink="/prototipus-konyveink">TOVÁBB</a></div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card h-100 text-center border-0"> <!-- full height -->
            <div>
            <span class="fa-stack fa-4x">
              <i class="fas fa-circle fa-stack-2x red-circle"></i>
              <i class="fas fa-cubes fa-stack-1x fa-inverse"></i>
            </span>
            </div>
            <h4 class="service-heading red-text">Kiterjesztett valóság gyártókörnyezet</h4>
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="text-muted">Szeretne hatékonyan, ipari mennyiségben kiterjesztett valóság kiadványokat készíteni?</p>
            </div>
            <div class=""><a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger red-btn" routerLink="/gyartokornyezet">TOVÁBB</a></div>
          </div>
        </div>
        <div class="col-md-4 my-4">
          <div class="card h-100 text-center border-0"> <!-- full height -->
            <div>
            <span class="fa-stack fa-4x">
              <i class="fas fa-circle fa-stack-2x yellow-circle"></i>
              <i class="fas fa-cogs fa-stack-1x fa-inverse"></i>
            </span>
            </div>
            <h4 class="service-heading yellow-text">Ismerje meg a technológiát</h4>
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="text-muted">Tekintse meg a hibridkönyvek működését, Android, Apple és Windows platformokon</p>
            </div>
            <div><a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger yellow-btn" routerLink="/technologia">TOVÁBB</a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>

