import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WebService {
    constructor(http) {
        this.http = http;
        this.lastAction = '';
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }
    getWebDatas() {
        this.lastAction = 'getItems';
        return this.http.get(this.url + '/webdatas').pipe(map((response) => response), catchError(this.errorHandler));
    }
    errorHandler(error) {
        return throwError(error.error || { message: 'Server Error' });
    }
}
WebService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebService_Factory() { return new WebService(i0.ɵɵinject(i1.HttpClient)); }, token: WebService, providedIn: "root" });
