<div class="d-flex flex-column">

  <div class="timeline">
    <span class="current-time">{{time| number:'1.4-4'}}</span>
    <span class="current-time-indicator" [ngStyle]="{'left': time/maxTime*100 +'%'}">
    </span>
    <div *ngIf="indicatorTracks" class="indicator-track mb-1">
      <div [ngStyle]="{'width': indicatorTracks[0].range+'%'}" class="track-empty d-flex flex-row">
        <div class="flex-fill">
        </div>
      </div>
      <div class="indicator d-flex flex-row">
        <div #indicator class="indicator-grab-area flex-fill" appResizeable [moveable]="isPlaying!=2" (onMove)="Move(indicatorTracks[1].start)"
          [containerwidth]="containerWidth" [track]="indicatorTracks[1]" [type]="'move'" [elements]="indicatorTracks"
          [max]="maxTime">
          <div class="time-handle">
          </div>
        </div>
      </div>
      <div [ngStyle]="{'width': indicatorTracks[2].range+'%'}" class="track-empty d-flex flex-row">
        <div class="flex-fill">
        </div>
      </div>
    </div>
    <audio #audioClip src=""></audio>
    <div class="mb-1" *ngIf="st" [ngStyle]="{'visibility': (audioFiles.length != 0 && !audioIsLoading)?'visible':'hidden'}">
      <app-fileselect *ngIf="!audioFiles[currentSlideIndex]" [acceptType]="'.mp3'" [placeholder]="st.select_audio"
        (setFile)="SelectAudioFile($event)" (clearFile)="RemoveAudio()" [multiple]="false"[disabled]="isPlaying==2 || audioIsLoading" ></app-fileselect>
      <div  [ngStyle]="{'display': audioFiles[currentSlideIndex]?'flex':'none'}"
        (mouseenter)="showClearBtn = true" (mouseleave)="showClearBtn = false">

        <div *ngFor="let audio of audioIds;index as i" class="audio-track" [ngStyle]="{'display': i==currentAudioIndex ?'flex':'none'}">
          <div  *ngFor="let trk of audioTracks[i]" [ngStyle]="{'width': trk.range+'%'}">
              <div *ngIf="!trk.isEmpty"  class="audio flex-fill" appResizeable [moveable]="true"
              [containerwidth]="containerWidth" [track]="audioTracks[i][1]" [elements]="audioTracks[i]" [type]="'move'" 
              [max]="maxTime" (onMove)="SetAudioPosition(v)" >
              <div id="{{audio}}" class="mb-1" >
                </div>
            </div>
            <div *ngIf="trk.isEmpty" class="track-empty flex-fill">
              </div>
          </div>
          <button *ngIf="showClearBtn" class="btn btn-danger" style="position: absolute;right:0;top:0;z-index: 10;"
          (click)="RemoveAudio()" [disabled]="isPlaying==2">
          <i class="fas fa-trash-alt"></i>
        </button>
        </div>
       
      </div>

    </div>

    <div #subTrack>
      <div *ngIf="subtitleFiles" class="subtitle-track">
        <div *ngFor="let item of subtitleFiles[currentSlideIndex].subtitles" [ngStyle]="{'width': item.range+'%'}"
          [ngClass]="item.text==''?'track-empty d-flex flex-row':'track d-flex flex-row'">
          <div appResizeable [moveable]="true" (onMove)="SetText(time)" [containerwidth]="containerWidth" [track]="item"
            [type]="'start'" [elements]="subtitleFiles[currentSlideIndex].subtitles" [max]="maxTime" class="start flex-shrink-0">
          </div>
          <div *ngIf="!item.isEmpty" class="track-content flex-fill" appResizeable [moveable]="true" (onMove)="SetText(time)"
            [containerwidth]="containerWidth" [track]="item" [type]="'move'" [elements]="subtitleFiles[currentSlideIndex].subtitles"
            [max]="maxTime">
            <p>{{item.text}}</p>
          </div>
          <div *ngIf="item.isEmpty" class="flex-fill">
          </div>
          <div appResizeable [moveable]="true" (onMove)="SetText(time)" [containerwidth]="containerWidth" [track]="item"
            [type]="'end'" [elements]="subtitleFiles[currentSlideIndex].subtitles" [max]="maxTime" class="end flex-shrink-0">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="subtitleFiles" class="d-flex flex-row">
    <div class="d-flex flex-column align-items-center">
      <div *ngIf="isPlaying!=2" class="d-flex flex-row">
        <button class="btn btn-primary mr-2" (click)="SetSubtitle()">
          <span *ngIf="st">{{st.set}}</span>
        </button>
        <button *ngIf="subtitleFiles[currentSlideIndex].subtitles.length >0 && st" class="btn btn-secondary mr-2" (click)="ChangeText()"
          [disabled]="subtitleFiles[currentSlideIndex].subtitles[currentId].text == subtitle || subtitleFiles[currentSlideIndex].subtitles[currentId].isEmpty">{{st.change}}</button>
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1" style="max-width: 85vw;">
      <textarea id="subtitle" class="form-control" type="text" name="currSub" [(ngModel)]="subtitle" [disabled]="isPlaying==2"></textarea>
    </div>
    <div>
      <button *ngIf="subtitleFiles[currentSlideIndex].subtitles.length >0 && !subtitleFiles[currentSlideIndex].subtitles[currentId].isEmpty" class="btn btn-warning ml-2" (click)="ClearSubtitle()">
        <span *ngIf="st">{{st.delete}}</span>
      </button>
    </div>
  </div>
</div>


