<div class="form-group">

    <div class="col-lg-12">
        <div *ngIf="mainForm.hasError('hasError')">
            <div class="alert alert-danger">
                {{form_save_failed}}
            </div>
        </div>
    </div>

    <div class="col-log-12">
        <div *ngIf="fileUploadOptions && fileUploadOptions.hasFiles">
            <div class="alert alert-{{fileUploadOptions.type}} mt-3">
                <i class="fas fa-spinner rotating"></i>
                {{fileUploadOptions.text}}
                <br/><div *ngIf="fileUploadOptions.canCancel" (click)="cancelUploadFilesClick()" class="btn-sm btn btn-danger p-0 px-1 mt-2">{{fileUploadOptions.cancelText}}</div>
            </div>

        </div>
    </div>

    <div class="col-lg-12">
        <div *ngIf="formType == 'create' || formType == 'copy'">
            <a *ngIf="showCancel" [routerLink]="['/admin/'+tableName+'/show']" [state]="{ ignoreLoadingBar: true}"
               class="btn btn-default" [class.disabled]="actionInProgress ? true: null"> {{cancel}}</a>
            <button type="submit" class="btn btn-primary" [disabled]="mainForm.invalid">
                <i *ngIf="actionInProgress" class="fas fa-spinner rotating"></i>
                <span *ngIf="formType == 'create'"> {{save}}</span>
                <span *ngIf="formType == 'copy'"> {{copy}}</span>
            </button>
        </div>
        <div *ngIf="formType == 'update'">
            <a *ngIf="showCancel" [routerLink]="['/admin/'+tableName+'/show']" [state]="{ ignoreLoadingBar: true}"
               class="btn btn-default" [class.disabled]="actionInProgress ? true: null"> {{cancel}}</a>
            <button *ngIf="permissions.Edit && !readonly" type="submit" class="btn btn-primary" [disabled]="mainForm.invalid">
                <i *ngIf="actionInProgress" class="fas fa-spinner rotating"></i><span> {{update}}</span>
            </button>
        </div>
        <div *ngIf="formType == 'show'">
            <a *ngIf="showCancel" [routerLink]="['/admin/'+tableName+'/show']" [state]="{ ignoreLoadingBar: true}"
               class="btn btn-default" [class.disabled]="actionInProgress ? true: null"> {{cancel}}</a>

        </div>
    </div>
</div>
