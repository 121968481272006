<app-subpageheader [text]="'Prototípus könyvek'" [imgfolder]="'prototipus'"></app-subpageheader>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="desc">
        <!-- Ez itt egy leírás -->
      </div>
    </div>
  </div>
</div>





<div class="container">

  <app-prototyperow [id]="'1'" [title]="'Az emberi test'" [coverSrc]="'vekop_borito_emberi_test.jpg'" [coverAlt]="'Az emberi test borító'" [pageSrc]="'Az_emberi_test-01.jpg'" [pageAlt]="'Az emberi test könyv'" [youtubeUrl]="'https://www.youtube.com/embed/fi7kYiliWj0'" ></app-prototyperow>

  <app-prototyperow [id]="'2'" [title]="'Fizikai mennyiségek'" [coverSrc]="'vekop_borito_fizikai_mennyi.jpg'" [coverAlt]="'Fizikai mennyiségek borító'" [pageSrc]="'fizikai_mennyisegek-01.jpg'" [pageAlt]="'Fizikai mennyiségek könyv'" [youtubeUrl]="'https://www.youtube.com/embed/a_P71LchEks'" ></app-prototyperow>

  <app-prototyperow [id]="'3'" [title]="'Térgeometria'" [coverSrc]="'vekop_borito_tergeometria.jpg'" [coverAlt]="'Térgeometria borító'" [pageSrc]="'Tergeometria-01.jpg'" [pageAlt]="'Térgeometria könyv'" [youtubeUrl]="'https://www.youtube.com/embed/ml2N5cEdTBc'" ></app-prototyperow>

  <app-prototyperow [id]="'4'" [title]="'Természetismeret'" [coverSrc]="'vekop_borito_termeszetismeret.jpg'" [coverAlt]="'Természetismeret borító'" [pageSrc]="'Termeszetismeret-01.jpg'" [pageAlt]="'Természetismeret könyv'" [youtubeUrl]="'https://www.youtube.com/embed/_dkgDu2rgfM'" ></app-prototyperow>

  <app-prototyperow [id]="'5'" [title]="'Fizikai jelenségek'" [coverSrc]="'vekop_borito_fizikai_jelensegek.jpg'" [coverAlt]="'Fizikai jelenségek borító'" [pageSrc]="'Fizikai_jelensegek-01.jpg'" [pageAlt]="'Fizikai jelenségek könyv'" [youtubeUrl]="'https://www.youtube.com/embed/nPZ7lMC3ZyY'" ></app-prototyperow>

  <app-prototyperow [id]="'6'" [title]="'Informatika'" [coverSrc]="'vekop_borito_excel.jpg'" [coverAlt]="'Informatika borító'" [pageSrc]="'informatika_excel-01.jpg'" [pageAlt]="'Informatika könyv'" [youtubeUrl]="'https://www.youtube.com/embed/eC6ibUS_WyQ'" ></app-prototyperow>

</div>

<app-footer></app-footer>
