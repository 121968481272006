import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminformComponent} from '../../controllers/adminform/adminform.component';
import {FormControl, Validators} from '@angular/forms';
import KeycodesCreationStatus from '../../models/KeycodesCreationStatus'

@Component({
  selector: 'app-key-codes-form',
  templateUrl: './key-codes-form.component.html',
  styleUrls: ['./key-codes-form.component.scss']
})
export class KeyCodesFormComponent extends AdminformComponent implements OnInit, OnDestroy {

  id: any;
  params: any;

  extraUsesControl: FormControl;
  keyData:any;
  kcId;
  needToDeleteRowData = null

  ngOnInit() {
    this.tableName = 'keycodes';
    this.formName = 'keycodes';
    this.setPermissions('books');
    this.sts.st = {form: this.localeText};

    this.permissions = this.permissionsService.getPermissions('books');

    // Update
    this.params = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.kcId = this.id;
        this.admingridService.getItem(this.tableName, this.id).subscribe(data => {

          // console.log('data', data);
          this.keyData = data

          //this.sts.st = data.st;
          this.sts.st = Object.assign({}, this.sts.st, data.st);


          this.replaceSystemTitle('keycodes.keycodes');
          this.replaceTexts();

          this.mainForm.controls['extra_uses'].setValue(data['item']['extra_uses']);

          this.formReadyToShow = true;

        }, error => {
          console.log(<any>error);
          var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }

      this.extraUsesControl = this.fb.control('', [Validators.required, Validators.pattern("^[0-9]*$"),  Validators.max(5000)]);


      this.mainForm = this.fb.group({
        id: this.kcId,
        extra_uses: this.extraUsesControl,
      });

    });

  }

  replaceTexts() {

  }

  showDeleteModal(cbid, name, device_name, device) {
    this.needToDeleteRowData = {cbid, name, device_name, device};
    document.getElementById("opendeleteModal").click();
  }
  
  deleteRow(cbid) {
    this.needToDeleteRowData = null;
    this.admingridService.deleteKeycodeUsage(cbid).subscribe(response => {
      // console.log("response", response);
      document.getElementById("cbid-"+cbid).remove();
    }, error => {
      console.log(<any>error);
    });
  }

  ngOnDestroy() {
    this.params.unsubscribe();
  }


}
