import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminformComponent} from '../../controllers/adminform/adminform.component';
import {PermissionsService} from '../../services/permissions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdmingridService} from '../../services/admingrid.service';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';
import {MessageService} from '../../services/message.service';

@Component({
  selector: 'app-roleform',
  templateUrl: './roleform.component.html',
  styleUrls: ['./roleform.component.scss']
})
export class RoleformComponent extends AdminformComponent implements OnInit, OnDestroy {

  roleId: any;
  params: any;
  menunames: any;

  nameControl: FormControl;
  rolePermissions: any[] = [];


  ngOnInit() {

    this.tableName = 'roles';
    this.formName = 'role';
    this.setPermissions(this.tableName);
    this.sts.st = {form: this.localeText};
    this.menunames = {};


    this.nameControl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    this.mainForm = this.fb.group({
      id: '',
      name: this.nameControl,
      rolePermissions: this.fb.array([])
    });


    // Update

    this.params = this.activatedRoute.params.subscribe(params => {
      this.roleId = params['id'];

      if (this.roleId) {

        this.admingridService.getItem(this.tableName, this.roleId).subscribe(data => {

          this.sts.st = Object.assign({}, this.sts.st, data.st);
          this.replaceSystemTitle('roles.role');

          this.createRolePermissionsArray(data);
          this.mainForm.controls['id'].setValue(data['item']['id']);
          this.mainForm.controls['name'].setValue(data['item']['name']);
          this.setSelectedRolePermission(data.item.permissions);
          this.createFormControlFromRolePermissionsArray();

          // console.log('this.mainForm:', this.mainForm.controls);
          this.generateMenuNames();
          this.formReadyToShow = true;


        }, error => {
          console.log(<any>error);
          var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }


    });


    // Create
    if (this.formType == 'create') {
      this.admingridService.createItem(this.tableName).subscribe(data => {

        this.createRolePermissionsArray(data);
        this.createFormControlFromRolePermissionsArray();
        this.sts.st = Object.assign({}, this.sts.st, data.st);
        this.replaceSystemTitle('roles.role');

        // console.log('this.mainForm.get(rolePermissions)', this.mainForm.get('rolePermissions'));
        // console.log('rolePermissions:', this.rolePermissions);
        this.generateMenuNames();
        this.formReadyToShow = true;

      }, error => {
        console.log(error);
        var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
        this.messageService.sendMessage({text: text, type: 'danger'});
      });
    }


  }

  generateMenuNames() {
    this.menunames.Areditor = this.sts.c('menu.areditor');
    this.menunames.Books = this.sts.c('menu.books');
    this.menunames.Dashboard = this.sts.c('menu.dashboard');
    this.menunames.Marketplace = this.sts.c('menu.marketplace');
    this.menunames.Organizations = this.sts.c('menu.organizations');
    this.menunames.Roles = this.sts.c('menu.roles');
    this.menunames.Shoppings = this.sts.c('menu.shoppings');
    this.menunames.Systemevents = this.sts.c('menu.system_events');
    this.menunames.Users = this.sts.c('menu.users');
    this.menunames.Keycodescreation = this.sts.c('menu.keycodes_creation');

  }


  createRolePermissionsArray(data) {
    var prevElementName = '';
    var arrayNum = -1;
    data.permissions.forEach((element) => {
      //console.log('prevElementName:', prevElementName);
      if (prevElementName != element.name.substr(0, element.name.indexOf(' '))) {
        arrayNum++;
        this.rolePermissions[arrayNum] = [];
        this.rolePermissions[arrayNum][0] = element.name.substr(0, element.name.indexOf(' ')); // menu name
        this.rolePermissions[arrayNum][1] = [];
      }

      prevElementName = element.name.substr(0, element.name.indexOf(' '));

      this.rolePermissions[arrayNum][1].push({
        id: element.id,
        name: element.name.substr(element.name.indexOf(' ') + 1), // element.name,
        controlName: element.name.replace(/\s/g, ''),
        selected: false
      });
    });
  }

  setSelectedRolePermission(selected) {


    var selectedIds = [];

    selected.forEach((selectedElement) => {
      selectedIds.push(selectedElement.id);
    });

    // console.log("selectedIds",selectedIds);

    this.rolePermissions.forEach((menuElement) => {
      menuElement[1].forEach((permission) => {
        if (selectedIds.indexOf(permission.id) > -1) {
          permission.selected = true;
        }
      });
    });

    //console.log("rolePermissions",this.rolePermissions);
  }

  get rolePermissionsArray() {
    return this.mainForm.get('rolePermissions') as FormArray;
  }

  createFormControlFromRolePermissionsArray() {

    this.rolePermissions.map(permissions => {

      this.rolePermissionsArray.controls[permissions[0]] = this.fb.array([]);
      permissions[1].map(type => {
        var fa = this.mainForm.get('rolePermissions').get(permissions[0]) as FormArray;
        fa.controls[type.name] = this.fb.control(type.selected);

      });
    });

    // console.log('this.mainForm.get(rolePermissions)', this.mainForm.get('rolePermissions'));
    // console.log('rolePermissions:', this.rolePermissions);
  }


  createOrUpdate() {
    if (!this.actionInProgress) {
      this.actionInProgress = true;
      this.itemByChild();
    }

  }

  itemByChild() {

    var rolePermissionsToSend = [];
    this.rolePermissions.forEach((element) => {
      var menuName = element[0];
      // permissions
      element[1].forEach((perm) => {
        var permName = perm.name;

        // check selected status in formArray
        if (this.mainForm.get('rolePermissions').get(menuName).get(permName).value) {
          rolePermissionsToSend.push({
            id: perm.id,
            name: menuName + ' ' + permName,
            selected: true
          });
        }
      });
    });
    //console.log("rolePermissions",rolePermissionsToSend);
    this.mainForm.value.rolePermissions = rolePermissionsToSend;

    // console.log('this.mainForm.value:', this.mainForm.value);
    if (this.formType === 'create' || this.formType === 'copy') this.createItem(this.mainForm.value);
    if (this.formType === 'update') this.updateItem(this.mainForm.value);
  }


  ngOnDestroy() {
    this.params.unsubscribe();
  }

}
