<div id="ar-editor" class="d-flex flex-column">
  <!--THREE COLUMN ROW-->
  <div class="d-flex flex-row">
    <!--LEFT SIDE-->
    <div class="d-flex flex-column flex-fill" style="width:12vw">
      <div class="dropdown mt-2">
        <button *ngIf="st" class="flex-fill btn btn-secondary dropdown-toggle"
          [ngStyle]="{'visibility':st?'visible':'hidden'}" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <span *ngIf="!publication">{{st.select_book}}</span>
          <span *ngIf="publication">
            {{publication.name}}
          </span>
          <span *ngIf="flags && publication" class="badge badge-secondary" style="top: 2px;">
            <div class="flags" [style.background-position]="flags.bgposition[publication.lang_code]"></div>
          </span>
        </button>
        <div class="dropdown-menu publications-dropdown" aria-labelledby="dropdownMenuButton">
          <button class="dropdown-item" (click)="publicationIndex = null;publication= null">...</button>
          <button class="dropdown-item" *ngFor="let pub of publications;index as i" (click)="onPublicationSelected(i)">
            <div class="d-flex flex-row align-items-baseline">
              <div class="mr-2">{{pub.name}}</div>
              <div *ngIf="flags" class="flags" [style.background-position]="flags.bgposition[pub.lang_code]"></div>
            </div>
          </button>
        </div>
      </div>
      <div *ngIf="publication!= null && editorLoaded" class="d-flex flex-column flex-fill align-content-stretch mt-2"
      data-simplebar style="width: 100%;max-height:520px">
        <div *ngFor="let group of publication.ar_files_grouped;index as i">
          <div *ngFor="let f of group.files | artype:'arWebgl';">
            <button class="btn mb-2"
              [ngClass]="{'btn-primary':loadedContentId == group.uid,'btn-outline-secondary':loadedContentId!= group.uid}"
              (click)="Load(f.id,group.uid,i);" style="width:80%" [disabled]="!gameInstance || isPlaying==2 || isLoading">
              <i *ngIf="group.audios == null || group.subtitles == null || group.jsons == null" class="fas fa-exclamation-triangle" ></i>
              <span class="flex-fill"></span>
              <span>{{group.title}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--EDITOR-->
    <div class="d-flex align-items-center" style="position: relative;width:960px">
      <div *ngIf="gameInstance" class="volume-controls p-2" (mouseenter)="showVolumeSlider = true"
        (mouseleave)="showVolumeSlider = false">
        <div class="d-flex flex-column">
          <div *ngIf="showVolumeSlider" class="input-group mb-3 vertical">
            <input type="range" name="volume" step="0.01" min="0" max="1" [(ngModel)]="volume"
              (ngModelChange)="SetVolume()" class="custom-range" aria-label="Volume">
          </div>
          <button class="btn btn-outline-primary volume-btn" style="border: none;box-shadow: none;"
            (click)="MuteVolume()">
            <i *ngIf="volume!=0" class="fas fa-volume-up"></i>
            <i *ngIf="volume==0" class="fas fa-volume-mute"></i>
          </button>
        </div>
      </div>
      <div *ngIf="gameInstance" class="reset-camera">
        <div class="d-flex flex-column">
          <button class="btn btn-outline-primary" style="border: none;box-shadow: none;" (click)="ResetCamera()">
            <i class="fas fa-camera"></i>
          </button>
        </div>
      </div>
      <div *ngIf="gameInstance" class="settings">
        <div class="d-flex flex-column">
          <button class="btn btn-outline-primary" style="border: none;box-shadow: none;" (click)="OpenSettings()">
            <i class="fas fa-cog"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!editorLoaded" class="editor-cover">

      </div>
      <button *ngIf="!gameInstance && st" class="btn btn-secondary load-btn"
        [ngStyle]="{'visibility':st?'visible':'hidden'}" (click)="LoadEditor()">
        <span>{{st.load_editor}}</span></button>
      <div [ngClass]="gameInstance?'editor-show':'editor-hide'">
        <canvas id="gameContainer" width=960 height=600 style="width: 960px; height: 600px;"></canvas>
      </div>
      <span *ngIf="gameInstance" class="subtitle">{{subtitle}}</span>
      <div *ngIf="gameInstance && !showControls" class="controls-toggle p-2">
        <button class="btn btn-outline-primary" style="border: none;box-shadow: none;" (click)="showControls = true">
          <i class="fas fa-chevron-down"></i>
        </button>
      </div>

      <div *ngIf="gameInstance && showControls && st"
        class="timeline-controls p-2 d-flex flex-fill justify-content-between">
        <button class="btn btn-outline-primary" style="border: none;box-shadow: none;" (click)="showControls = false">
          <i class="fas fa-chevron-up"></i>
        </button>
        <div class="input-group input-group-sm mb-1" style="width: auto;">
          <div class="input-group-prepend timeline-info">

            <span class="input-group-text">{{st.slide}} :</span>
            <span class="input-group-text">{{currentSlideIndex}}/{{slideCount}}</span>
            <span class="input-group-text">{{st.time}} :</span>
            <span class="input-group-text"
              style="border-radius: 0px 4px 4px 0px;">{{currentTime | number:'3.4-4'}}</span>
          </div>
        </div>

        <div class="btn-group mb-1 flex-1" role="group">
          <button class="btn btn-outline-primary" (click)="Prev()" [disabled]="!contentLoaded">
            <i class="fas fa-step-backward"></i>
          </button>
          <button *ngIf="isPlaying!=2" class="btn btn-outline-primary" (click)="Play()" [disabled]="!contentLoaded">
            <i class="fas fa-play"></i>
          </button>
          <button *ngIf="isPlaying==2" class="btn btn-outline-secondary" (click)="Pause()" [disabled]="!contentLoaded">
            <i class="fas fa-pause"></i>
          </button>
          <button class="btn btn-outline-primary" (click)="Stop()" [disabled]="!contentLoaded">
            <i class="fas fa-stop"></i>
          </button>
          <button class="btn btn-outline-primary" (click)="Next()" [disabled]="!contentLoaded">
            <i class="fas fa-step-forward"></i>
          </button>
        </div>
        <div class="input-group input-group-sm mb-1" style="width: auto;">
          <div class="input-group-prepend timeline-info">
            <span *ngIf="st" class="input-group-text"
              [ngClass]="{'bg-primary text-white': autoPlay}">{{st.autoplay}}</span>
            <div class="input-group-text" style="border-radius: 0px 4px 4px 0px;">
              <input type="checkbox" [(ngModel)]="autoPlay" (ngModelChange)="ToggleAutoplay()"
                aria-label="Automatikus lejátszás" [disabled]="!contentLoaded">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--RIGHT SIDE-->
    <div class="d-flex flex-column flex-fill" style="width:12vw">
      <div class="d-flex flex-row mt-2">
        <span class="flex-fill"></span>
        <button *ngIf="st" class="btn btn-primary flex-grow-0" [ngStyle]="{'visibility':st?'visible':'hidden'}"
          (click)="Save()" [disabled]="saved || isPlaying==2">
          <i class="fas fa-save"></i> {{st.save}}
        </button>
      </div>
      <nav class="d-flex flex-column flex-fill mt-2 pl-2 pr-2" data-simplebar style="width: 100%;max-height:520px">
        <app-label-manager [labelsId]="labelsId" (onLabelChange)="ChangeLabelText($event)"></app-label-manager>
      </nav>
    </div>
  </div>
  <!--TIMELINE-->
  <div class="d-flex flex-row flex-fill" [ngStyle]="{'visibility': contentLoaded?'visible':'hidden'}">
    <app-timeline (showSubtitle)="subtitle = $event" [publication]="publication" class="flex-fill" [st]="st"></app-timeline>
  </div>
</div>
<div *ngIf="isLoading">
  <app-preloader></app-preloader>
</div>

<div *ngIf="st" class="sidenav-right shadow-lg animated faster"
  [ngClass]="{'slideInRight': showSidenav,'slideOutRight': !showSidenav,'out': sidenavOut}">
  <div class="modal-header">
    <h5 class="modal-title" id="settingsModalLabel">{{st.settings}}</h5>
    <button type="button" class="close" (click)="CloseSettings()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border: none;padding: 0;" [formGroup]="settingsFg" id="accord">
    <div class="card">
      <div class="card-header clickable" id="headingOne" data-toggle="collapse" data-target="#collapseBackground"
        aria-expanded="true" aria-controls="collapseOne">
        <h5 class="mb-0">
          {{st.background}}
        </h5>
      </div>
      <div id="collapseBackground" class="collapse show" aria-labelledby="headingOne" data-parent="#accord">
        <div class="card-body">
          <div class="d-flex flex-row">
            <app-formcolorpicker #colorpicker *ngIf="settingsFg.controls['topColor']" [parentFormGroup]="settingsFg"
              [inputType]="'hidden'" [control]="topColor" [inputName]="'topColor'" [title]="st.top_color" [sts]="sts"
              [permissions]="{'Edit':true}" [higherRoleIsNeeded]="higherRoleIsNeeded"
              [actionInProgress]="actionInProgress">
            </app-formcolorpicker>
            <app-formcolorpicker #colorpicker *ngIf="settingsFg.controls['bottomColor']" [parentFormGroup]="settingsFg"
              [inputType]="'hidden'" [control]="bottomColor" [inputName]="'bottomColor'" [title]="st.bottom_color"
              [sts]="sts" [permissions]="{'Edit':true}" [higherRoleIsNeeded]="higherRoleIsNeeded"
              [actionInProgress]="actionInProgress">
            </app-formcolorpicker>
          </div>
          <div>
            <span id="size">{{st.size}}</span>
          </div>
          <div class="input-group mb-3">
            <input type="range" name="size" step="0.01" min="0" max="4" formControlName="size"
              class="form-control custom-range slider" aria-label="Size">
            <div class="input-group-append">
              <span class="input-group-text" id="size" style="width:50px;">
                {{settingsFg.controls['size'].value}}</span>
            </div>
          </div>
          <div>
            <span id="offset" >{{st.offset}}</span>
          </div>
          <div class="input-group mb-3">
            <input type="range" name="offset" step="0.01" min="-2" max="2" formControlName="offset"
              class="form-control custom-range slider" aria-label="Offset">
            <div class="input-group-append">
              <span class="input-group-text" id="offset" style="width:50px;">
                {{settingsFg.controls['offset'].value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header clickable" id="headingOne" data-toggle="collapse" data-target="#collapseGrid"
        aria-expanded="true" aria-controls="collapseOne">
        <h5 class="mb-0">
          {{st.grid}}
        </h5>
      </div>
      <div id="collapseGrid" class="collapse" aria-labelledby="headingOne" data-parent="#accord">
        <div class="card-body">
          <div class="d-flex flex-row">
            <app-formcolorpicker #colorpicker *ngIf="settingsFg.controls['gridColor']" [parentFormGroup]="settingsFg"
              [inputType]="'hidden'" [control]="gridColor" [inputName]="'gridColor'" [title]="st.grid_color" [sts]="sts"
              [permissions]="{'Edit':true}" [higherRoleIsNeeded]="higherRoleIsNeeded"
              [actionInProgress]="actionInProgress">
            </app-formcolorpicker>
          </div>
          <div>
              <span id="thickness" >{{st.thickness}}</span>
            </div>
          <div class="input-group mb-3">
            <input type="range" name="thickness" step="0.001" min="0" max="1" formControlName="thickness"
              class="form-control custom-range slider" aria-label="Thickness">
            <div class="input-group-append">
              <span class="input-group-text" id="thickness" style="width:68px;">
                {{settingsFg.controls['thickness'].value}}</span>
            </div>
          </div>
          <div class="input-group input-group-sm mb-1">
              <div class="input-group-prepend">
                <span class="input-group-text" >{{st.show_grid}}</span>
                <div class="input-group-text" style="border-radius: 0px 4px 4px 0px;">
                  <input type="checkbox" formControlName="showGrid"
                    aria-label="st.show_grid">
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SaveSettings()">{{st.save}}</button>
  </div>
</div>


