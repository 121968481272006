import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'app-gridactionbtns',
    templateUrl: './gridactionbtns.component.html',
    styleUrls: ['./gridactionbtns.component.scss']
})
export class GridactionbtnsComponent implements OnInit {

    @Input() tableName: string;
    @Input() formName: string;
    @Input() permissions: any;
    @Input() actionBtnsDisplay: any;
    @Input() deleteInProgress: boolean;
    @Input() sts: any;


    @Output() actionUserBySelectedRowsOutput = new EventEmitter<any>();

    addBtnTitle: string;
    showBtnTitle: string;
    modifyBtnTitle: string;
    copyBtnTitle: string;
    maintenanceBtnTitle: string;
    wegbglZipBtnTitle: string;
    deleteBtnTitle: string;

    constructor(private messageService: MessageService) {
        this.messageService.getStatus().subscribe(
            message => {
                if (message) {
                    if (message.name == 'grid' && message.status == 'ready') {
                        this.addBtnTitle = this.sts.c('grid.add_btn');
                        this.showBtnTitle = this.sts.c('grid.show_btn');
                        this.modifyBtnTitle = this.sts.c('grid.modify_btn');
                        this.copyBtnTitle = this.sts.c('grid.copy_btn');
                        this.maintenanceBtnTitle = this.sts.c('grid.maintenance_btn');
                        this.wegbglZipBtnTitle = this.sts.c('grid.webgl_zip_btn');
                        this.deleteBtnTitle = this.sts.c('grid.delete_btn');
                    }
                }
            }
        );
    }

    ngOnInit() {
        this.permissions = this.permissions;
    }

    actionUserBySelectedRows(type: string) {
        this.actionUserBySelectedRowsOutput.emit(type);
    }

}
