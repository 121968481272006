/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./languagecheck.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../modules/displayobject/displayobject.component.ngfactory";
import * as i5 from "../../modules/displayobject/displayobject.component";
import * as i6 from "./languagecheck.component";
import * as i7 from "../../services/languagecheck.service";
import * as i8 from "../../../services/systemtext.service";
import * as i9 from "@angular/platform-browser";
var styles_LanguagecheckComponent = [i0.styles];
var RenderType_LanguagecheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguagecheckComponent, data: {} });
export { RenderType_LanguagecheckComponent as RenderType_LanguagecheckComponent };
function View_LanguagecheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner rotating"]], null, null, null, null, null))], null, null); }
export function View_LanguagecheckComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["files", 1]], null, 8, "select", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeFile(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "option", [["value", "mobile_app"]], null, null, null, null, null)), i1.ɵdid(4, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(5, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["mobile_app.php"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "option", [["value", "validation"]], null, null, null, null, null)), i1.ɵdid(8, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(9, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["validation.php"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn btn-info btn-sm"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguagecheckComponent_1)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "file-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "displayobject", [], null, null, null, i4.View_DisplayobjectComponent_0, i4.RenderType_DisplayobjectComponent)), i1.ɵdid(18, 638976, null, 0, i5.DisplayobjectComponent, [], { myObject: [0, "myObject"], level: [1, "level"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mobile_app"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "mobile_app"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "validation"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "validation"; _ck(_v, 9, 0, currVal_3); var currVal_5 = _co.actionInProgress; _ck(_v, 13, 0, currVal_5); var currVal_7 = _co.langValues; var currVal_8 = 0; _ck(_v, 18, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.actionInProgress ? true : null); _ck(_v, 11, 0, currVal_4); var currVal_6 = _co.load; _ck(_v, 15, 0, currVal_6); }); }
export function View_LanguagecheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-languagecheck", [], null, null, null, View_LanguagecheckComponent_0, RenderType_LanguagecheckComponent)), i1.ɵdid(1, 114688, null, 0, i6.LanguagecheckComponent, [i7.LanguagecheckService, i8.SystemtextService, i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguagecheckComponentNgFactory = i1.ɵccf("app-languagecheck", i6.LanguagecheckComponent, View_LanguagecheckComponent_Host_0, {}, {}, []);
export { LanguagecheckComponentNgFactory as LanguagecheckComponentNgFactory };
