import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguagecheckService {

  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
  }

  getItems(file): Observable<any> {
    // console.log('get languagecheck items');
    return this.http.get<any>(this.url + '/languagecheck/'+file).pipe(map((response) => response), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || {message: 'Server Error'});
  }
}
