<div class="form-group text-left" [formGroup]="parentFormGroup">
    <label for="{{inputName}}" class="col-lg-12 control-label">
        {{title}} <app-tooltip *ngIf="toolTipOptions && toolTipOptions.text != ''" [toolTipOptions]="toolTipOptions"></app-tooltip>
        <span *ngIf="required === true">*</span>
    </label>
    <div class="col-lg-12">
        <div *ngIf="prefixOutside"
             class="prefix-outside"
             [style.width.px]="prefixOutsideWidth">
             {{prefixOutside}}
        </div>
        <input *ngIf="mask"
               [mask]="mask" [prefix]="prefix" [suffix]="suffix" [showMaskTyped]="showMaskTyped"
               type="{{inputType}}" class="form-control" id="{{inputName}}" name="{{inputName}}"
               placeholder="{{title}}" formControlName="{{inputName}}"
               [readonly]="!permissions.Edit || higherRoleIsNeeded || actionInProgress || readonly">

        <input *ngIf="!mask" [style.width]="prefixOutsideInputStyle"
               type="{{inputType}}" class="form-control" id="{{inputName}}" name="{{inputName}}"
               placeholder="{{title}}" formControlName="{{inputName}}"
               [readonly]="!permissions.Edit || higherRoleIsNeeded || actionInProgress || readonly">

        <div class="clearfix" *ngIf="prefixOutside"></div>

        <div *ngIf="control.dirty && control.hasError('required')"
             class="alert alert-danger">Field is required
        </div>
        <div *ngIf="control.dirty && control.hasError('minlength')"
             class="alert alert-danger">Should have at least {{inputMinLength}} characters
        </div>
        <div *ngIf="control.dirty && control.hasError('max')"
             class="alert alert-danger">Nem lehet több mint  {{inputMaxLength}}
        </div>
        <div *ngIf="control.dirty && control.hasError('invalidEmail')"
             class="alert alert-danger">Not a valid email
        </div>
        <div *ngIf="inputName === 'password_confirmation' && control.dirty && parentFormGroup.hasError('invalidPassword')"
             class="alert alert-danger">The passwords you enter must match
        </div>
        <div *ngIf="control.hasError('validateFailed')">
            <div class="alert alert-danger" *ngFor="let message of control.errors.validateFailed">
                {{ message }}
            </div>
        </div>
    </div>
</div>


