export interface Track{
    id:number,
    start:number,
    range:number,
    end:number,
    isEmpty:boolean
}
export class TrackItem{
    id = 0;
    start=0;
    range=0;
    end=0;
    isEmpty=true;
}