<div class="container">
  <div class="form">
    <select #files (change)="changeFile(files.value)">
      <option value="mobile_app">mobile_app.php</option>
      <option value="validation">validation.php</option>
    </select>
    <button class="btn btn-info btn-sm" [class.disabled]="actionInProgress ? true: null" (click)="getFile()" >
      <i *ngIf="actionInProgress" class="fas fa-spinner rotating"></i><span> {{load}}</span>
    </button>
  </div>

  <div class="file-content">
    <displayobject [myObject]="langValues" [parentString]="" [level]="0"></displayobject>
  </div>
</div>
