<div class="container">
    <div class="col-md-12 ">
        <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
        <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }}</div>
        <div class="card card-body bg-light">
            <form novalidate class="form-horizontal" (ngSubmit)="login()" [formGroup]="userForm">
                <fieldset>
                    <legend>{{wt.title}}</legend>
                    <div class="form-group">
                        <label for="loginemail" class="col-lg-12 control-label">{{wt.email}}</label>
                        <div class="col-lg-12">
                            <input type="email" class="form-control" id="loginemail" name="loginemail"
                                   placeholder="Email of the user" formControlName="loginemail">
                            <div *ngIf="loginemailControl.dirty && loginemailControl.hasError('required')"
                                 class="alert alert-danger">{{wt.email_is_required}}
                            </div>
                            <div *ngIf="loginemailControl.dirty && loginemailControl.hasError('minlength')"
                                 class="alert alert-danger">{{wt.email_should_have_at_least_3}}
                            </div>
                            <div *ngIf="loginemailControl.dirty && loginemailControl.hasError('invalidEmail')"
                                 class="alert alert-danger">{{wt.not_valid_email}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" class="col-lg-12 control-label">{{wt.password}}</label>
                        <div class="col-lg-12">
                            <input type="password" class="form-control" id="password" name="password"
                                   placeholder="Password" formControlName="password">
                            <div *ngIf="passwordControl.dirty && passwordControl.hasError('required')"
                                 class="alert alert-danger">{{wt.password_is_required}}
                            </div>
                            <div *ngIf="passwordControl.dirty && passwordControl.hasError('minlength')"
                                 class="alert alert-danger">{{password_should_have_at_least_6}}
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="col-lg-12">
                            <a [routerLink]="['/']" class="btn btn-default"
                               [class.disabled]="actionInProgress ? true: null"> {{cancelText}}</a>
                            <button type="submit" class="btn btn-primary" [disabled]="userForm.invalid">
                                <i *ngIf="actionInProgress" class="fas fa-spinner rotating"></i><span> {{wt.login}}</span>
                            </button>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>
    </div>
</div>
