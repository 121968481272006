import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Label } from '../../models/Label';
import { TimelineService } from '../../services/timeline.service';
import { FormGroup } from '@angular/forms';
import { FileLoaderService } from '../../services/file-loader.service';

@Component({
  selector: 'app-label-manager',
  templateUrl: './label-manager.component.html',
  styleUrls: ['./label-manager.component.scss']
})
export class LabelManagerComponent implements OnInit {
  @Input('labelsId') labelsId: number;
  labels: Label[] = [];
  _labels: Label[] = [];
  public loaded = false;
  ready = false;
  isPlaying: boolean;
  @Output() onLabelChange: EventEmitter<string> = new EventEmitter<string>();
  constructor(private timelineService: TimelineService, private fileLoaderService: FileLoaderService) { }

  ngOnInit() {
    this.timelineService.isPlaying.subscribe(playing => {
      this.isPlaying = (playing == 2) ? true : false;
    });

  }
  SaveLabels(bookid: number, contentId: string, uid: number) {
    const f = JSON.stringify(this.labels);
    const b: BlobPart[] = [f];
    const file: File = new File(b, 'labels-' + uid + '.json', {type: 'text/json'});
    /// bookAr-labels save
    const key = 'bookAr-labels-' + contentId + '[]';
    const data: FormData = new FormData();
    data.append(key, file);
    /// Send Data To Server
    this.fileLoaderService.Prepare(bookid, data).subscribe(async result => {
      const res = await result;
      if (res) {
        // console.log(result);
        this.fileLoaderService.Finalize(bookid, result.uuid).subscribe((r: any) => {
          // console.log(r);
          if (r.result) {
            this.fileLoaderService.IsLabelUploaded(true);
          }
        });
      }
    });
    // console.log(f);
    // console.log(file);
  }
  OnAssetsLoaded(slideCount: number) {
    console.log('ASSET LOADED');

    this.loaded = true;
  }
 /// Called from unity///
  SetLabelBySlide(index: number, txt: string, guid:string) {
    this.ready = false;
    // console.log(txt);
    const uid = this.labels.length + '_' + index;
    const lab: Label = {
      uid: uid,
      index: index,
      txt: txt,
      guid:guid
    };
    this._labels.push(lab);
  }
  OnLabelsReady() {
    if (this.labelsId == null) {
      this.labels = Array.from(this._labels);
      this.ready = true;
    } else {
      this.fileLoaderService.GetFile(this.labelsId).subscribe((labels: Label[]) => {
        // console.log('Labels count ',this._labels.length);

        if (labels.length > 0) {
          this.labels = labels;
          this.labels .forEach(label => {
            this.onLabelChange.emit(label.index + '_' + label.txt);
          });
        }
        // console.log(labels);
        this.ready = true;
      });
    }
  }
  ClearLabels() {
    this._labels = [];
    this.labels = [];
  }
  ChangeLabelText(str: any) {
    const obj = JSON.parse(str);
   // console.log(obj);
    this.labels[obj.index].txt = obj.text;
    this.onLabelChange.emit(obj.index + '_' + obj.text);
  }
}
