import { EventEmitter } from '@angular/core';
import { DraggableDirective } from './draggable.directive';
export class ResizeableDirective extends DraggableDirective {
    constructor() {
        super(...arguments);
        this.onMove = new EventEmitter();
        this.position = { x: 0, y: 0 };
    }
    Setup(v) {
        let dragStartPos = this.GetRangePercentOnScreen(v, this.containerwidth);
        let offsetStart = this.GetRangePercent(0, this.track.start);
        let offsetEnd = this.GetRangePercent(0, this.track.end);
        this.offsetFromStart = dragStartPos - offsetStart;
        this.offsetFromEnd = dragStartPos - offsetEnd;
    }
    onDragStart(event) {
        event.preventDefault();
        if (this.moveable) {
            let dragStartPos = this.GetRangePercentOnScreen(event.clientX, this.containerwidth);
            let offsetStart = this.GetRangePercent(0, this.track.start);
            let offsetEnd = this.GetRangePercent(0, this.track.end);
            this.offsetFromStart = dragStartPos - offsetStart;
            this.offsetFromEnd = dragStartPos - offsetEnd;
        }
    }
    onDragMove(event) {
        event.preventDefault();
        if (this.moveable) {
            switch (this.type) {
                case 'start':
                    this.SetCurrentRangeStart(event.clientX);
                    break;
                case 'end':
                    this.SetCurrentRangeEnd(event.clientX);
                    break;
                case 'move':
                    this.SetTrackPosition(event.clientX);
                    break;
            }
            this.onMove.emit();
        }
    }
    onDragEnd(event) {
        event.preventDefault();
    }
    SetTrackPosition(v) {
        let current = this.track;
        let perc = this.GetRangePercentOnScreen(v, this.containerwidth);
        let value = this.GetValueFromPercent(perc - this.offsetFromStart);
        let previous = this.elements[this.track.id - 1];
        let next = this.elements[this.track.id + 1];
        if (value + this.GetValueFromPercent(current.range) >= next.end) {
            value = next.end - this.GetValueFromPercent(current.range);
        }
        if (value <= previous.start) {
            value = previous.start;
        }
        current.start = value;
        current.end = value + this.GetValueFromPercent(current.range);
        next.start = current.end;
        previous.end = current.start;
        current.range = this.GetRangePercent(current.start, current.end);
        previous.range = this.GetRangePercent(previous.start, previous.end);
        next.range = this.GetRangePercent(next.start, next.end);
    }
    SetCurrentRangeStart(v) {
        let perc = this.GetRangePercentOnScreen(v, this.containerwidth);
        let value = this.GetValueFromPercent(perc - this.offsetFromStart);
        let current = this.track;
        if (current.id > 0) {
            let previous = this.elements[this.track.id - 1];
            value = Math.max(value, previous.start);
            if (previous.isEmpty && value >= previous.start && value < current.end) {
                current.start = value;
                current.range = this.GetRangePercent(current.start, current.end);
                previous.end = value;
                previous.range = this.GetRangePercent(previous.start, previous.end);
            }
        }
    }
    SetCurrentRangeEnd(v) {
        let perc = this.GetRangePercentOnScreen(v, this.containerwidth);
        let value = this.GetValueFromPercent(perc - this.offsetFromEnd);
        let current = this.track;
        if (current.id < this.elements.length - 1) {
            let next = this.elements[this.track.id + 1];
            value = Math.min(value, next.end);
            if (next.isEmpty && value <= next.end && value > current.start) {
                current.end = value;
                current.range = this.GetRangePercent(current.start, current.end);
                next.start = value;
                next.range = this.GetRangePercent(next.start, next.end);
            }
        }
    }
    GetRangePercent(start, end) {
        return (end - start) / this.max * 100;
    }
    GetValueFromPercent(percent) {
        return this.max / 100 * percent;
    }
    GetRangePercentOnScreen(start, max) {
        return start / max * 100;
    }
}
