import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../models/user';
import {AdmingridService} from '../../services/admingrid.service';
import {Router, ActivatedRoute} from '@angular/router';
import {MessageService} from '../../services/message.service';
import {GridOptions} from 'ag-grid-community';
import {PermissionsService} from '../../services/permissions.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AdmingridComponent} from '../../controllers/admingrid/admingrid.component';
import {DropdownFilter} from "../../controllers/admingrid/dropdownfilter.component";

@Component({
    selector: 'app-users',
    templateUrl: '../grid.base.template.html',
    styleUrls: ['../grid.base.template.scss']
})
export class UsersComponent extends AdmingridComponent implements OnInit {
    isSuperadmin = JSON.parse(sessionStorage.getItem('userroles')).indexOf("Superadmin") != -1;
    users: User[];

    colDef_id = {headerName: 'Id', field: 'id', colId: 'u.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter'};
    colDef_name = {headerName: '', field: 'name', colId: 'u.name', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter'};
    colDef_email = {headerName: '', field: 'email', colId: 'u.email', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_organization_name = {headerName: '', field: 'organization_name', colId: 'organizations.name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter'};
    colDef_anonymized = {headerName: '', field: 'anonymized', colId: 'u.anonymized', sortable: true, resizable: true, show:false, filterLangPrefix:'grid.', filter: 'DropdownFilter'};
    colDef_created_at = {headerName: '', field: 'created_at', colId: 'u.created_at', sortable: true, resizable: true, show: false, filter: 'agDateColumnFilter'};
    colDef_updated_at = {headerName: '', field: 'updated_at', colId: 'u.updated_at', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter'};
    colDef_user_roles = {headerName: '', field: 'user_roles', colId: '*STRING_AGG(roles.name,\', \')', sortable: true, resizable: true, cellRenderer: this.rolesCellRenderer, show: true, filter: 'agTextColumnFilter'};

    firstLoadDone:boolean = false;

    rolesCellRenderer(param) {
        let roles = String(param.value).split(',');
        var html = '';
        roles.forEach((role) => {
            if (role !== 'null' && role != 'undefined') {
                html += '<span style="padding: 2px 4px 2px 4px; margin-right:4px; border-radius: 2px;background: #e7e7e7;">' + role + '</span>';
            }
        });
        return html;
    }

    columnDefs = [this.colDef_id, this.colDef_name, this.colDef_email, this.colDef_user_roles, this.colDef_organization_name, this.colDef_anonymized, this.colDef_created_at, this.colDef_updated_at ];


    ngOnInit() {
        super.ngOnInit();

        if(!this.isSuperadmin) {
          // Not superadmin, remove anonymized column
          this.columnDefs.splice(5, 1);
        }

        this.frameworkComponents = { DropdownFilter: DropdownFilter };

        this.tableName = 'users';
        this.formName = 'User';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;


        this.messageService.getStatus().subscribe(
            message => {
                if (message) {
                    if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {


                        this.sts.replace('grid.add_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.show_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.modify_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.copy_btn', [{'find': ':item', 'replace': 'users.user'}]);
                        this.sts.replace('grid.delete_btn', [{'find': ':item', 'replace': 'users.user'}]);

                        this.setColNameByLang(this.colDef_name, 'users.name', message.gridOptions);
                        this.setColNameByLang(this.colDef_email, 'users.email', message.gridOptions);
                        this.setColNameByLang(this.colDef_user_roles, 'roles.role', message.gridOptions);
                        this.setColNameByLang(this.colDef_organization_name, 'orgs.organization', message.gridOptions);
                        this.setColNameByLang(this.colDef_created_at, 'users.created', message.gridOptions);
                        this.setColNameByLang(this.colDef_updated_at, 'users.updated', message.gridOptions);
                        if(this.isSuperadmin) {
                          this.setColNameByLang(this.colDef_anonymized, 'users.anonymized', message.gridOptions);
                        }

                        message.gridOptions.api.refreshHeader();
                        this.onColumnsChanged();

                        this.firstLoadDone = true;

                    }
                }
            }
        );

    }

  changeValueByLang(items) {
    if(this.isSuperadmin) {
      items.forEach((item) => {
        item.anonymized = item.anonymized == '1' ? this.sts.c('grid.true') : this.sts.c('grid.false');
      });
    }
  }


}
