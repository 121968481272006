<div
    #container
    class="filter-container"
>
    <p
        *ngIf="actionInProgress"
        class="updating"
    >
        <i class="fas fa-spinner rotating"></i>
        {{sts.c('grid.filter_loading_please_wait')}}
    </p>
    <angular2-multiselect
        #filterDropdown
        *ngIf="!actionInProgress"
        [data]="items"
        [settings]="settings"
        [(ngModel)]="selectedItems"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
        (onDeSelect)="onItemDeSelect($event)"
        (onDeSelectAll)="onDeSelectAll($event)"
        (onOpen)="onFilterMenuOpen($event)"
        (onClose)="onFilterMenuClose($event)">


        <div *ngIf="colId == 'b.language'" class="language-dropdown">
            <c-badge>
                <ng-template let-item="item">
                    <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[item.code]" ></div>
                    <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                </ng-template>
            </c-badge>
            <c-item>
                <ng-template let-item="item">
                    <div class="language-c-item">
                        <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[item.code]" ></div>
                        <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                    </div>
                </ng-template>
            </c-item>
        </div>

    </angular2-multiselect>

    <div
        #applyPanel
        *ngIf="!actionInProgress && (params['applyButton'] || params['clearButton'])"
        class="ag-filter-apply-panel"
    >

        <button
            *ngIf="params.clearButton"
            type="button"
            (click)="onClearFilterButtonClick($event)"
        >
            {{sts.c('grid.clearFilter')}}
        </button>

        <button
            *ngIf="params['applyButton']"
            type="button"
            (click)="onApplyFilterButtonClick($event)"
        >
            {{sts.c('grid.applyFilter')}}
        </button>

    </div>
</div>