import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CanActivate} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'

})
export class AuthGuardService implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate() {
        if (!this.auth.loggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }
        /*
        let userroles =  JSON.parse(sessionStorage.getItem('userroles'));
        if ( userroles == 'Client') {
            this.router.navigate(['/']);
            return false;
        }
        */
        return true;
    }

    // canActivate(): Observable<boolean> {
    //     return this.auth.map(authState => {
    //         if (!authState) this.router.navigate(['/login']);
    //         console.log('activate?', !!authState);
    //         return !!authState;
    //     }).take(1);
    // }
}