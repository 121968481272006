import { FormBuilder } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class BookarfilesService {
    constructor(fb) {
        this.fb = fb;
    }
    generateUid() {
        var uid = new Date().valueOf();
        if (uid != this.lastUid) {
            this.lastUid = uid;
            return uid;
        }
        else {
            return this.generateUid();
        }
    }
    initBookAr() {
        return this.fb.group({
            type: 'ar',
            uid: this.generateUid(),
            title: [''],
            keywords: [''],
            arAndroid: [''],
            arIos: [''],
            arWebgl: [''],
            arTargetImage: [''],
        });
    }
    initBookVideo() {
        return this.fb.group({
            type: 'video',
            uid: this.generateUid(),
            title: [''],
            keywords: [''],
            video: [''],
            videoTargetImage: [''],
        });
    }
}
BookarfilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookarfilesService_Factory() { return new BookarfilesService(i0.ɵɵinject(i1.FormBuilder)); }, token: BookarfilesService, providedIn: "root" });
