import { Component, OnInit } from '@angular/core';
import {SystemtextService} from "../../../services/systemtext.service";
import {DomSanitizer} from "@angular/platform-browser";
import {LanguagecheckService} from "../../services/languagecheck.service";

@Component({
  selector: 'app-languagecheck',
  templateUrl: './languagecheck.component.html',
  styleUrls: ['./languagecheck.component.scss']
})
export class LanguagecheckComponent implements OnInit {

  constructor(private languagecheckService: LanguagecheckService, protected sts: SystemtextService, private sanitizer: DomSanitizer) {
  }

  actionInProgress:boolean = false;
  langValues = [];
  load:string;
  currentFile = "mobile_app";

  ngOnInit() {
    this.load = JSON.parse(sessionStorage.getItem('basetexts')).form[localStorage.getItem('lang')].load;
  }

  changeFile(file) {
    this.currentFile = file;
  }
  getFile() {
    this.actionInProgress = true;
    this.languagecheckService.getItems(this.currentFile).subscribe(items => {
      // console.log('items:', items);
      this.langValues = items['lang_values'];
      this.actionInProgress = false;

    }, error => {
      console.log('error:', error);
    });

  }

}
