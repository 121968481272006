import {Component, OnInit, Input, Output, EventEmitter, HostListener} from '@angular/core';

@Component({
    selector: 'app-gridheader',
    templateUrl: './gridheader.component.html',
    styleUrls: ['./gridheader.component.scss']
})
export class GridheaderComponent implements OnInit {

    @Input() columnDefs: any;
    @Input() headerDisplay: any;
    @Input() sts: any;
    @Input() tableName: string;
    @Output() onColumnsChangedOutput = new EventEmitter<any>();
    @Output() onPageSizeChangedOutput = new EventEmitter<any>();
    @Output() onFilterTextBoxChangedOutput = new EventEmitter<any>();

    showColumnChangeList: boolean = false;

    filterText: string;
    columnsText: string;
    defaultColumnsText: string;
    pageSizeText: string;

    selected:number = 10;
    showDefaultColumnsBtn:boolean = false;

    constructor() {

    }

    ngOnInit() {
        this.filterText = this.sts.c('grid.filter') + '...';
        this.columnsText = this.sts.c('grid.columns');
        this.defaultColumnsText = this.sts.c('grid.default_columns');
        this.pageSizeText = this.sts.c('grid.page_size') + ':';


        var gridOptions = JSON.parse(localStorage.getItem("gridOptions"));
        if(gridOptions != null && gridOptions[this.tableName+"-size"] != undefined) {
            this.selected = gridOptions[this.tableName+"-size"];
        }
        this.setShowHideDefaultColumnsBtn();

    }
    setDefaultColumns() {
        // console.log("setDefaultColumns");
        this.showDefaultColumnsBtn = false;
        var gridOptions = JSON.parse(localStorage.getItem("gridOptions"));
        delete gridOptions[this.tableName+"-columns"];

        gridOptions = JSON.stringify(gridOptions);
        localStorage.setItem('gridOptions',gridOptions);

        this.onColumnsChangedOutput.emit(true);
    }

    setShowHideDefaultColumnsBtn() {
        var gridOptions = JSON.parse(localStorage.getItem("gridOptions"));
        if(gridOptions != null && gridOptions[this.tableName+"-columns"] != undefined) {
            this.showDefaultColumnsBtn = true;
        } else {
            this.showDefaultColumnsBtn = false;
        }
    }

    toggleColumns() {
        this.showColumnChangeList = !this.showColumnChangeList;
    }

    onColumnsChanged() {
        this.onColumnsChangedOutput.emit();
        this.setShowHideDefaultColumnsBtn();
    }

    onPageSizeChanged() {
        this.onPageSizeChangedOutput.emit();
    }

    onFilterTextBoxChanged($event) {
        this.onFilterTextBoxChangedOutput.emit($event);
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(event.target.classList.contains('page-columns-btn') ||  event.target.closest(".page-columns") != null ) {
            // console.log("btn or checkbox click");
        } else {
            this.showColumnChangeList = false;
        }
    }

}
