/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./web.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i3 from "@ngx-loading-bar/core";
import * as i4 from "./layout/menu/menutop.component.ngfactory";
import * as i5 from "./layout/menu/menutop.component";
import * as i6 from "@angular/forms";
import * as i7 from "./services/language.service";
import * as i8 from "./services/systemtext.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "./web.component";
import * as i12 from "./services/web.service";
import * as i13 from "./services/flags.service";
var styles_WebComponent = [i0.styles];
var RenderType_WebComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_WebComponent, data: {} });
export { RenderType_WebComponent as RenderType_WebComponent };
function View_WebComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i2.View_LoadingBarComponent_0, i2.RenderType_LoadingBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.LoadingBarComponent, [i3.LoadingBarService], { includeSpinner: [0, "includeSpinner"], includeBar: [1, "includeBar"], fixed: [2, "fixed"] }, null)], function (_ck, _v) { var currVal_1 = false; var currVal_2 = true; var currVal_3 = false; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).fixed; _ck(_v, 1, 0, currVal_0); }); }
function View_WebComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i2.View_LoadingBarComponent_0, i2.RenderType_LoadingBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingBarComponent, [i3.LoadingBarService], { includeSpinner: [0, "includeSpinner"], includeBar: [1, "includeBar"], fixed: [2, "fixed"] }, null)], function (_ck, _v) { var currVal_1 = false; var currVal_2 = true; var currVal_3 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0); }); }
function View_WebComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-menutop", [], null, [[null, "dragstart"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).function($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MenutopComponent_0, i4.RenderType_MenutopComponent)), i1.ɵdid(3, 114688, null, 0, i5.MenutopComponent, [i6.FormBuilder, i7.LanguageService, i8.SystemtextService, i9.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "main", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebComponent_3)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.isDataLoaded; _ck(_v, 6, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_WebComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDataLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isDataLoaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_WebComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-web", [], null, null, null, View_WebComponent_0, RenderType_WebComponent)), i1.ɵdid(1, 114688, null, 0, i11.WebComponent, [i12.WebService, i8.SystemtextService, i13.FlagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebComponentNgFactory = i1.ɵccf("app-web", i11.WebComponent, View_WebComponent_Host_0, {}, {}, []);
export { WebComponentNgFactory as WebComponentNgFactory };
