<div class="form-group text-left" [formGroup]="parentFormGroup" #fileInputContainer>

    <label class="col-lg-12 control-label" *ngIf="title">{{ title }} <app-tooltip *ngIf="toolTipOptions && toolTipOptions.text != ''" [toolTipOptions]="toolTipOptions"></app-tooltip></label>

    <div class="col-lg-12">
        <div *ngIf="actionInProgress || readonlyProp" class="disable-all"></div>
        <div>
            <template #singleFileIntputTemplate></template>
        </div>

        <label
            *ngIf="!readonly && (!maxFileCount || fileControls.length < maxFileCount)"
            class="filearea {{ hasDragDropSupport ? 'droptarget' : '' }} {{ isDragOver ? 'drag-over' : '' }} {{ parentComponent != '' ? 'display-block' : '' }}"
            (drag) = "handle($event)"
            (dragstart) = "handle($event)"
            (dragend) = "onDragOut($event)"
            (dragover) = "onDragOver($event)"
            (dragenter) = "onDragOver($event)"
            (dragleave) = "onDragOut($event)"
            (drop) = "onDrop($event)"
            >

            <input #fileinput type="file" (change)="onFileChange($event)" accept="{{_acceptStr}}" [attr.multiple]="maxFileCount > 1 ? 'multiple' : ''">

            <div class="label flex-width-col no-selection">
                {{ fileAreaCaption }}
                <span *ngIf="maxFileSize" class="size-limit">({{getSizeLimitMessage()}})</span>
            </div>

            <div class="fixed-width-col">
                <button
                    type="button"
                    class="browseButton"
                    (click)="onBrowse($event)">

                    {{ browseButtonTitle || defaultBrowseButtonTitle }}

                </button>
            </div>

        </label>

        <div
            *ngIf="control.dirty && control.hasError('required')"
            class="alert alert-danger">

            {{getErrorMessage()}}
        </div>

    </div>

</div>
