import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
export class OrganizationsComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 'o.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_fullname = { headerName: '', field: 'fullname', colId: 'o.fullname', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_name = { headerName: '', field: 'name', colId: 'o.name', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_email = { headerName: '', field: 'email', colId: 'o.email', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_description = { headerName: '', field: 'description', colId: 'o.description', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_public = { headerName: '', field: 'public', colId: 'o.public', sortable: true, resizable: true, show: true, filterLangPrefix: 'grid.', filterSingleSelection: true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer };
        this.colDef_qrurl = { headerName: '', field: 'qrurl', colId: 'o.qrurl', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.columnDefs = [this.colDef_id, this.colDef_fullname, this.colDef_name, this.colDef_email, this.colDef_description, this.colDef_public, this.colDef_qrurl];
        this.firstLoadDone = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableName = 'organizations';
        this.formName = 'Organization';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.sts.replace('grid.add_btn', [{ 'find': ':item', 'replace': 'orgs.organization' }]);
                    this.sts.replace('grid.show_btn', [{ 'find': ':item', 'replace': 'orgs.organization' }]);
                    this.sts.replace('grid.modify_btn', [{ 'find': ':item', 'replace': 'orgs.organization' }]);
                    this.sts.replace('grid.copy_btn', [{ 'find': ':item', 'replace': 'orgs.organization' }]);
                    this.sts.replace('grid.delete_btn', [{ 'find': ':item', 'replace': 'orgs.organization' }]);
                    this.setColNameByLang(this.colDef_fullname, 'orgs.full_name', message.gridOptions);
                    this.setColNameByLang(this.colDef_name, 'orgs.name', message.gridOptions);
                    this.setColNameByLang(this.colDef_email, 'orgs.email', message.gridOptions);
                    this.setColNameByLang(this.colDef_description, 'orgs.description', message.gridOptions);
                    this.setColNameByLang(this.colDef_public, 'orgs.public', message.gridOptions);
                    this.setColNameByLang(this.colDef_qrurl, 'orgs.qrurl', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
}
