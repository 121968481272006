import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  template: `
  <div class="not-found-wrapper">
       <h1 style="text-align: center;font-size: 4em;">404</h1>
       <h4 style="text-align: center;">Page Not Found</h4>
       <button class="btn btn-primary" style="margin:auto;display:block" routerLink="/">Home</button>
</div>
  `,
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
