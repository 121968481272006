<div class="ag-grid-header">
    <div *ngIf="headerDisplay.Columns" class="page-columns-container" [ngClass]="showColumnChangeList ? 'show-columns-change-list' : ''">
        <div class="page-columns-btn btn-light btn-sm btn" (click)="toggleColumns()">
            {{columnsText}}
        </div>
        <div class="page-columns">
            <label *ngFor="let columnDef of columnDefs">
                <input type="checkbox"
                       [checked]="columnDef.show === false ? null : 'true'"
                       (change)="onColumnsChanged()"
                       [disabled]="columnDef.alwaysShow ? 'disabled' : null"
                       id="cid-{{columnDef.field}}" />
                {{columnDef.headerName}}
            </label>

        </div>
        <div *ngIf="showDefaultColumnsBtn" class="page-default-columns-btn btn-light btn-sm btn" (click)="setDefaultColumns()">
            {{defaultColumnsText}}
        </div>
    </div>

    <div *ngIf="headerDisplay.PageSize" class="page-size">
        {{pageSizeText}}
        <select (change)="onPageSizeChanged()" [(value)]="selected" id="page-size">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
        </select>
    </div>

    <div *ngIf="headerDisplay.QuickFilter" class="quick-filter">
        <input type="text" id="filter-text-box" placeholder="{{filterText}}"
               (ngModelChange)="onFilterTextBoxChanged($event)"
               [(ngModel)]="quickFilterText"/>
    </div>
</div>