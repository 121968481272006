<div class="form-group {{singleSelectionClass}} text-left" [formGroup]="parentFormGroup">
    <label *ngIf="title" class="col-lg-12 control-label">
        {{title}}
        <span *ngIf="required === true">*</span>
    </label>
    <div class="col-lg-12" [ngClass]="actionInProgress || readonly || disabled ? 'disabled' : null">
        <i *ngIf="loading" class="fas fa-spinner rotating"></i>
        <angular2-multiselect [data]="itemList" [settings]="itemSettings" formControlName="{{formControlNameString}}"
                              (onSelect)="onItemSelect($event)"
                              (onDeSelect)="OnItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">

            <div *ngIf="formControlNameString ==  'languageselector'">
                <c-badge>
                    <ng-template let-item="item">
                        <div [ngStyle]="flags.style" [style.background-position]="flags.bgposition[item.code]" ></div>
                    </ng-template>
                </c-badge>
                <c-item>
                    <ng-template let-item="item">
                        <div [ngStyle]="flags.style" [style.background-position]="flags.bgposition[item.code]" ></div>
                        <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                    </ng-template>
                </c-item>
            </div>

            <div *ngIf="formControlNameString == 'language'" class="language-dropdown">
                <c-badge>
                    <ng-template let-item="item">
                        <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[item.code]" ></div>
                        <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                    </ng-template>
                </c-badge>
                <c-item>
                    <ng-template let-item="item">
                        <div [ngStyle]="flags.styleInline" [style.background-position]="flags.bgposition[item.code]" ></div>
                        <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                    </ng-template>
                </c-item>
            </div>
            <div *ngIf="formControlNameString == 'qrlogo'" >
              <c-badge>
                <ng-template let-item="item">
                  <img *ngIf="item.image" [src]="item.image" style="width: 30px; border: 1px solid #efefef;margin-right: 20px;" />
                  <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                </ng-template>
              </c-badge>
              <c-item>
                <ng-template let-item="item">
                  <img *ngIf="item.image" [src]="item.image" style="width: 30px; border: 1px solid #efefef;margin-right: 20px;" />
                  <span class="list-item-name" style="font-size: 12px;">{{item.itemName}}</span>
                </ng-template>
              </c-item>
            </div>
        </angular2-multiselect>
        <div *ngIf="control.hasError('validateFailed')">
            <div class="alert alert-danger" *ngFor="let message of control.errors.validateFailed">
                {{ message }}
            </div>
        </div>
    </div>
</div>
