import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AdmingridService} from '../../../services/admingrid.service';
@Component({
    selector: 'app-formmultiselect',
    templateUrl: './formmultiselect.component.html',
    styleUrls: ['./formmultiselect.component.scss']
})
export class FormmultiselectComponent implements OnInit {

    @Input() parentFormGroup;
    @Input() itemList;
    @Input() itemSettings;
    @Input() formControlNameString;
    @Input() permanentDatas;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() disabled;
    @Input() loading;
    @Input() title;
    @Input() required;
    @Input() sts;
    @Input() control;

    @Output() itemSelect: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemDeSelect: EventEmitter<any> = new EventEmitter<any>();

    singleSelectionClass: string = '';

    flags:any;

    constructor(private router: Router, private ags: AdmingridService) {
    }

    ngOnInit() {
        if (this.itemSettings.singleSelection) this.singleSelectionClass = 'single-selection';

        // this.itemSettings.lazyLoading = true;

        this.title = this.title ? this.sts.c(this.title) : '';

        this.flags = JSON.parse(sessionStorage.getItem("flags"));
    }

    OnItemDeSelect(item: any) {

        if (this.itemSettings.emitOutput) {
            this.itemDeSelect.emit(item);
        }

        if (this.formControlNameString == 'languageselector') {
            this.keepSelectedValue();
            return;
        }
        if (typeof this.permanentDatas === 'undefined') return;

        if (this.permanentDatas.length > 0) {
            var removeDelete = false;
            this.permanentDatas.forEach((pdata) => {
                if (item.id == pdata) removeDelete = true;
            });

            if (removeDelete) {
                var prevSelectedItems: any = [];
                this.parentFormGroup.controls[this.formControlNameString].value.forEach((sitem) => {
                    prevSelectedItems.push(sitem);
                });
                prevSelectedItems.push(item);

                this.parentFormGroup.controls[this.formControlNameString].patchValue(prevSelectedItems);
            }
        }
    }

    onDeSelectAll(item: any) {
        // console.log('item:', this.itemList);
        if (typeof this.permanentDatas == 'undefined') return;

        var permanentItems: any = [];
        this.itemList.forEach((items) => {
            this.permanentDatas.forEach((pdata) => {
                if (items.id == pdata) permanentItems.push(items);
            });
        });
        this.parentFormGroup.controls[this.formControlNameString].setValue(permanentItems);
    }

    onItemSelect(item: any) {

        if (this.itemSettings.emitOutput) {
            this.itemSelect.emit(item);
        }

        if (this.formControlNameString == 'languageselector') {
            this.actionInProgress = true;
            localStorage.setItem('lang', item.code);
            var userdata = JSON.parse(sessionStorage.getItem('userdata'));

            if (userdata != undefined && userdata.id && this.router.url.indexOf('admin') > -1) {
                var uitem = {userid: userdata.id, lang: item.code};
                this.ags.setLanguage('setlanguage', uitem).subscribe((item) => {
                    //console.log("item:",item);
                    this.pageReload();
                });
            } else {
                // this.pageReload();
                window.location.reload();
            }
        }
    }

    pageReload() {
        var currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.actionInProgress = false;
            this.router.navigate([currentUrl]);
        });
    }

    keepSelectedValue() {
        var selectedId = this.itemSettings.selectedId;
        var selectedItem = this.itemList.filter(obj => {
            return obj.id === selectedId;
        });
        this.parentFormGroup.controls[this.formControlNameString].patchValue(selectedItem);
    }
}
