<div class="container" *ngIf="formReadyToShow">
  <div class="col-md-12 my-4">
    <div class="card card-body bg-light">
      <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm" autocomplete="off">
        <fieldset>

          <app-formheader [formType]="formType" [formName]="formName" [id]="userId" [sts]="sts"></app-formheader>



          <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="fullNameControl"
                         [inputName]="'fullname'" [title]="'orgs.full_name'" [sts]="sts"
                         [parentFormGroup]="mainForm" [permissions]="permissions" [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"></app-forminput>


          <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="nameControl"
                         [inputName]="'name'" [title]="'orgs.name'" [sts]="sts"
                         [parentFormGroup]="mainForm" [permissions]="permissions" [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"></app-forminput>

          <app-forminput [inputType]="'text'" [required]="true" [control]="emailControl"
                         [inputName]="'email'" [title]="'orgs.email'" [sts]="sts"
                         [parentFormGroup]="mainForm" [permissions]="permissions" [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"></app-forminput>


          <app-forminput [inputType]="'text'" [required]="true" [control]="qrurlControl"
                         [inputName]="'qrurl'" [title]="'orgs.qrurl'" [sts]="sts"
                         [parentFormGroup]="mainForm" [permissions]="permissions" [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"></app-forminput>


          <app-formmultiselect [itemList]="qrlogosItemList" [itemSettings]="qrlogosSettings"
                               [title]="'orgs.qrlogo'" [sts]="sts" [formControlNameString]="'qrlogo'"
                               [required]="true"
                               [control]="qrlogoControl"
                               [parentFormGroup]="mainForm"
                               [readonly]="readonly"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>

          <app-formtextarea [inputMinLength]="'3'" [control]="descriptionControl"
                            [inputName]="'description'" [title]="'orgs.description'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions" [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"></app-formtextarea>


          <app-formswitch [control]="publicControl" [inputName]="'public'"
                          [title]="'orgs.public'" [sts]="sts"
                          [parentFormGroup]="mainForm" [permissions]="permissions"
                          [higherRoleIsNeeded]="higherRoleIsNeeded"
                          [readonly]="readonly"
                          [actionInProgress]="actionInProgress">

          </app-formswitch>

          <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                           [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                           [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

        </fieldset>
      </form>
    </div>
  </div>
</div>
