import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
import { DropdownFilter } from '../../controllers/admingrid/dropdownfilter.component';
export class SystemeventsComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 's.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_event_source_langkey = { headerName: '', field: 'event_source_langkey', colId: 's.event_source_langkey', sortable: true, resizable: true, show: true, filter: 'DropdownFilter' };
        this.colDef_event_type_langkey = { headerName: '', field: 'event_type_langkey', colId: 's.event_type_langkey', sortable: true, resizable: true, show: true, filter: 'DropdownFilter' };
        this.colDef_data_name = { headerName: '', field: 'data_name', colId: 's.data_name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter', cellRenderer: this.dataNameRenderer };
        this.colDef_url = { headerName: '', field: 'url', colId: 's.url', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter', cellRenderer: function (params) {
                return params.value ? '<a href="#/' + params.value + '" target="_blank">' + params.value + '</a>' : '';
            } };
        this.colDef_ip = { headerName: '', field: 'ip', colId: 's.ip', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_domain = { headerName: '', field: 'domain', colId: 's.domain', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_created_at = { headerName: '', field: 'created_at', colId: 's.created_at', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter' };
        this.colDef_insert_user_name = { headerName: '', field: 'insert_user_name', colId: 'insert_user_name', sortable: true, resizable: true, show: false, filter: 'agDateColumnFilter', cellRenderer: function (params) {
                return params.value;
            } };
        this.columnDefs = [
            this.colDef_id,
            this.colDef_event_source_langkey,
            this.colDef_event_type_langkey,
            this.colDef_data_name,
            this.colDef_url,
            this.colDef_ip,
            this.colDef_domain,
            this.colDef_created_at,
            this.colDef_insert_user_name
        ];
        this.firstLoadDone = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.frameworkComponents = { DropdownFilter: DropdownFilter };
        this.tableName = 'systemevents';
        this.formName = 'Systemevent';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.setColNameByLang(this.colDef_event_source_langkey, 'systemevents.event_source', message.gridOptions);
                    this.setColNameByLang(this.colDef_event_type_langkey, 'systemevents.event_type', message.gridOptions);
                    this.setColNameByLang(this.colDef_data_name, 'systemevents.data_name', message.gridOptions);
                    this.setColNameByLang(this.colDef_url, 'systemevents.url', message.gridOptions);
                    this.setColNameByLang(this.colDef_ip, 'systemevents.ip', message.gridOptions);
                    this.setColNameByLang(this.colDef_domain, 'systemevents.domain', message.gridOptions);
                    this.setColNameByLang(this.colDef_created_at, 'systemevents.created', message.gridOptions);
                    this.setColNameByLang(this.colDef_insert_user_name, 'systemevents.createdBy', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
    changeValueByLang(items) {
        items.forEach((item) => {
            item.event_source_langkey = this.sts.c(item.event_source_langkey);
            item.event_type_langkey = this.sts.c(item.event_type_langkey);
        });
    }
    dataNameRenderer(param) {
        if (param.value === undefined)
            return;
        return param.node.data.url ? '<a href="#/' + param.node.data.url + '" target="_blank">' + param.value + '</a>' : param.value;
    }
}
