import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { SystemtextService } from '../../services/systemtext.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/systemtext.service";
export class messageItem {
}
export class statusItem {
}
export class MessageService {
    constructor(router, sts) {
        this.router = router;
        this.sts = sts;
        this.subject = new Subject();
        this.statusSubject = new Subject();
    }
    sendMessage(message) {
        if (message.text === "The token has been blacklisted") {
            // session storage multi tab logout
            sessionStorage.clear();
            window.location.href = '/login';
        }
        var tokenHasExpired = message.text === 'Token has expired' ? true : false;
        if (message.text.indexOf('Foreign key violation') != -1) {
            console.log('Foreign key violation: ', message.text);
            message.text = this.sts.c('grid.request_failed_data_in_use');
        }
        this.subject.next({
            text: message.text,
            type: message.type,
            tokenHasExpired: tokenHasExpired,
        });
    }
    clearMessage() {
        this.subject.next();
    }
    getMessage() {
        return this.subject.asObservable();
    }
    sendStatus(message) {
        this.statusSubject.next({
            name: message.name,
            status: message.status,
            form: message.form,
            gridOptions: message.gridOptions,
        });
    }
    getStatus() {
        return this.statusSubject.asObservable();
    }
}
MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SystemtextService)); }, token: MessageService, providedIn: "root" });
