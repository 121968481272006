<div class="img-full" [ngClass]="{'show': showId === id }" (click)="hideImg()">
    <i class="fas fa-times" (click)="hideImg()"></i>
    <img class="" [src]="pageSrc" alt="{{pageAlt}}" />
</div>

<div class="row position-relative ">
  <div class="bg-line"></div>
  <div class="col-lg-4 text-center d-flex align-items-center justify-content-center my-2">
    <div class="cover-bg-line"></div>
    <div class="mobile-title">{{title}}</div>
    <img class="cover"  [src]="coverSrc" alt="{{coverAlt}}" />
  </div>
  <div class="col-lg-4 text-center  d-flex align-items-center justify-content-center my-3 mb-5 mb-lg-3">
    <div class="img-container position-relative">
      <img class="page" (click)="showImg(id)" [src]="pageSrc" alt="{{pageAlt}}" />
      <div class="zoom" (click)="showImg(id)" >
        <i class="fas fa-search"></i>
      </div>
    </div>
    <div class="subtitle">minta oldalpár</div>

  </div>
  <div class="col-lg-4 text-center d-flex align-items-center justify-content-center my-3 mb-5 mb-lg-3">
    <div class="monitor-title">{{title}}</div>
    <iframe class="youtube"[src]="safeYoutubeUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div class="subtitle">youtube videó</div>
  </div>
</div>
