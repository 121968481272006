<footer class="footer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12">
        <span class="copyright">Minden jog fenntartva © 2020</span>
      </div>
      <!--
      <div class="col-md-4">
        <ul class="list-inline social-buttons">
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="#">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul class="list-inline quicklinks">
          <li class="list-inline-item">
            <a href="#">Adatvédelmi irányelvek</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Használati feltételek</a>
          </li>
        </ul>
      </div
      -->
    </div>
  </div>
</footer>
