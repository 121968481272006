import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    status: string;
    message: string;
    errors: any;

    userForm: FormGroup;
    passwordForm: FormGroup;
    passwordControl: FormControl;
    confirmationControl: FormControl;
    nameControl: FormControl;
    emailControl: FormControl;

    constructor(fb: FormBuilder, private authService: AuthService, private router: Router) {
        this.passwordControl = fb.control('', [Validators.required, Validators.minLength(6)]);
        this.confirmationControl = fb.control('', [Validators.required, Validators.minLength(6)]);
        this.nameControl = fb.control('', [Validators.required, Validators.minLength(3)]);
        this.emailControl = fb.control('', [Validators.required, RegisterComponent.isValidEmail]);
        this.passwordForm = fb.group({
            password: this.passwordControl,
            password_confirmation: this.confirmationControl
        }, {validator: RegisterComponent.samePassword});
        this.userForm = fb.group({email: this.emailControl, name: this.nameControl, passwordForm: this.passwordForm});
    }

    ngOnInit() {
    }

    static isValidEmail(control: FormControl) {
        let email_regexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        return email_regexp.test(control.value) ? null : {invalidEmail: true};
    }

    static samePassword(group: FormGroup) {
        const password = group.get('password').value;
        const password_confirmation = group.get('password_confirmation').value;
        return password === password_confirmation ? null : {invalidPassword: true};

    }

    registerUser() {
        this.authService.register(this.userForm.value)
            .subscribe(response => {
                    const user = response.user;
                    if (user) {
                        console.log('A new user has been created');
                        this.status = 'success';
                        this.message = response['nessage'];
                        this.router.navigate(['/admin']);
                    } else {
                        this.errors = response.errors;
                    }

                },
                error => console.log(<any>error)
            );
    }
}