import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
import { DropdownFilter } from '../../controllers/admingrid/dropdownfilter.component';
import KeycodesCreationStatus from '../../models/KeycodesCreationStatus';
import { downloadQrCodesZipComponent } from "./download-qr-codes-zip.component";
export class KeyCodesCreationComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        this.statusItemList = JSON.parse(JSON.stringify(KeycodesCreationStatus));
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 'kc.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_quantity = { headerName: '', field: 'quantity', colId: 'kc.quantity', sortable: true, resizable: true, alwaysShow: false, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_bookname = { headerName: '', field: 'bookname', colId: 'bookname', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false };
        this.colDef_uses = { headerName: '', field: 'uses', colId: 'kc.uses', sortable: true, resizable: true, alwaysShow: false, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_status = { headerName: '', field: 'status', colId: 'kc.status', sortable: true, resizable: true, alwaysShow: false, show: true };
        this.colDef_comment = { headerName: '', field: 'comment', colId: 'kc.comment', sortable: true, resizable: true, alwaysShow: false, show: true, filter: 'agTextColumnFilter' };
        this.colDef_qrurl = { headerName: '', field: 'qrurl', colId: 'o.qrurl', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_download = { headerName: '', field: 'download', colId: 'kc.download', sortable: false, resizable: false, width: 80, alwaysShow: false, show: true, cellRendererFramework: downloadQrCodesZipComponent };
        this.colDef_created_at = { headerName: '', field: 'created_at', colId: 'kc.created_at', sortable: false, resizable: true, show: false, filter: 'agDateColumnFilter' };
        this.colDef_create_user_id = { headerName: '', field: 'create_user_id', colId: 'create_user_id', sortable: true, resizable: true, show: false, filter: 'agDateColumnFilter', cellRenderer: function (params) {
                return params.value;
            } };
        this.colDef_updated_at = { headerName: '', field: 'updated_at', colId: 'kc.updated_at', sortable: false, resizable: true, show: false, filter: 'agDateColumnFilter' };
        this.colDef_update_user_id = { headerName: '', field: 'update_user_id', colId: 'update_user_id', sortable: true, resizable: true, show: false, filter: 'agDateColumnFilter', cellRenderer: function (params) {
                return params.value;
            } };
        this.columnDefs = [this.colDef_id, this.colDef_bookname, this.colDef_quantity, this.colDef_uses, this.colDef_status, this.colDef_comment, this.colDef_qrurl, this.colDef_download, this.colDef_created_at, this.colDef_create_user_id, this.colDef_updated_at, this.colDef_update_user_id];
        this.firstLoadDone = false;
        this.headerDisplay = {
            Columns: true,
            PageSize: true,
            QuickFilter: false,
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.frameworkComponents = { DropdownFilter: DropdownFilter };
        this.tableName = 'keycodescreation';
        this.formName = 'Keycodescreation';
        this.setPermissions("books");
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.sts.replace('grid.add_btn', [{ 'find': ':item', 'replace': 'keycodescreation.keycodescreation' }]);
                    this.sts.replace('grid.modify_btn', [{ 'find': ':item', 'replace': 'keycodescreation.keycodescreation' }]);
                    this.sts.replace('grid.copy_btn', [{ 'find': ':item', 'replace': 'keycodescreation.keycodescreation' }]);
                    this.sts.replace('grid.delete_btn', [{ 'find': ':item', 'replace': 'keycodescreation.keycodescreation' }]);
                    this.setColNameByLang(this.colDef_bookname, 'books.book', message.gridOptions);
                    this.setColNameByLang(this.colDef_quantity, 'keycodescreation.quantity', message.gridOptions);
                    this.setColNameByLang(this.colDef_uses, 'keycodescreation.uses', message.gridOptions);
                    this.setColNameByLang(this.colDef_status, 'keycodescreation.status', message.gridOptions);
                    this.setColNameByLang(this.colDef_comment, 'keycodescreation.comment', message.gridOptions);
                    this.setColNameByLang(this.colDef_qrurl, 'keycodescreation.qrurl', message.gridOptions);
                    this.setColNameByLang(this.colDef_download, 'keycodescreation.download', message.gridOptions);
                    this.setColNameByLang(this.colDef_created_at, 'keycodescreation.created_at', message.gridOptions);
                    this.setColNameByLang(this.colDef_create_user_id, 'keycodescreation.create_user_id', message.gridOptions);
                    this.setColNameByLang(this.colDef_updated_at, 'keycodescreation.updated_at', message.gridOptions);
                    this.setColNameByLang(this.colDef_update_user_id, 'keycodescreation.update_user_id', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
    changeValueByLang(items) {
        const statusItemList = JSON.parse(JSON.stringify(KeycodesCreationStatus));
        items.forEach((item) => {
            const status = statusItemList.filter(i => i.id == item.status)[0];
            item.status = this.sts.c(`keycodescreation.statuses.${status.name}`);
        });
    }
}
