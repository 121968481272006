import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './services/auth.service';
import {catchError, map, flatMap} from 'rxjs/operators';
import {environment} from '../environments/environment';
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';


@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const reqWithLangHeader = req.clone({ headers: req.headers.set('lang', localStorage.getItem('lang')) });
        const intialRequest = req.clone();
        // console.log("xxx");

        return next.handle(reqWithLangHeader).pipe(
            catchError((error) => {
                console.log("MyHttpInterceptor error: ",error);

                const err = (typeof error !== 'object') ? JSON.parse(error) : error;
                // console.log('err:', err);
                if (err.status === 401 && err.error.message === 'Token has expired') {
                    console.log('Http Interceptor error: Token has expired');

                    const http = this.injector.get(HttpClient);
                    const url = `${environment.backendApiUrl}${environment.backendApiVersion}`;

                    return http.post(url + '/refresh', {}).pipe(flatMap(response => {
                            //console.log('Response: ' + response);
                            const token: any = response['access_token'];
                            sessionStorage.setItem('token', token);
                            console.log('New token: ' + token);

                            var expire_at =  Date.now() + (response['expires_in'] * 1000);
                            sessionStorage.setItem('token_expire_at', expire_at.toString());


                            const cloneRequest = intialRequest.clone({
                                headers: new HttpHeaders({
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer' + token
                                })
                            });

                            return next.handle(cloneRequest);
                        }), catchError((refresherror) => {
                            //console.log('refresherror', refresherror);
                            const refresherr = (typeof refresherror !== 'object') ? JSON.parse(refresherror) : refresherror;

                            if (refresherr.status === 500 && refresherr.error.message === 'Token has expired and can no longer be refreshed') {
                                console.log('Http Interceptor error: Token has expired and can no longer be refreshed');
                                return next.handle(intialRequest);
                            }
                        })
                    );

                }
                else if (err.status === 500 && err.error.message === 'Token has expired and can no longer be refreshed') {
                    //console.log('Http Interceptor error: Token has expired and can no longer be refreshed');
                    return next.handle(intialRequest);
                }
                else if (err.status === 401 && err.statusText === 'Unauthorized') {
                    // Access denied
                    return throwError(err);
                }
                else if (err.url.indexOf("keycodescreation") >= 0) {
                    // Should not be repeated because it would generate the keys again
                    return throwError(err);
                }
                else {
                    return next.handle(req);
                }

            })
        );

    }


}

