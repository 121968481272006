import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggableDirective } from './draggable.directive';
import { MoveableDirective } from './moveable.directive';
import { ResizeableDirective } from './resizeable.directive';
import { SelectorDirective } from './selector.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DraggableDirective, MoveableDirective,ResizeableDirective, SelectorDirective],
  exports:[DraggableDirective,MoveableDirective,ResizeableDirective,SelectorDirective]
})
export class DraggableModule { }
