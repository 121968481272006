import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'displayobject',
  templateUrl: './displayobject.component.html',
  styleUrls: ['./displayobject.component.scss']
})
export class DisplayobjectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @Input() myObject;
  @Input() parentString;
  @Input() level;
  public propertyList;
  public parentStrings;
  public currentLevel;
  public backgrondColors = ["", "#cbcbcb", "#d8d8d8", "#eeeeee"];

  ngOnChanges() {
    this.propertyList = Object.keys(this.myObject);
    this.parentStrings = this.parentStrings+" - "+this.parentString;
    this.currentLevel = this.level+1;
    // console.log("this.propertyList",this.propertyList);
    // console.log("this.parentStrings",this.parentStrings);
  }

  isObject(value) {
    return typeof value === 'object' && value !== null
  }

  isString(value) {
    return typeof value === 'string' && value !== null
  }

}
