/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formbuttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./formbuttons.component";
var styles_FormbuttonsComponent = [i0.styles];
var RenderType_FormbuttonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormbuttonsComponent, data: {} });
export { RenderType_FormbuttonsComponent as RenderType_FormbuttonsComponent };
function View_FormbuttonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form_save_failed; _ck(_v, 2, 0, currVal_0); }); }
function View_FormbuttonsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "btn-sm btn btn-danger p-0 px-1 mt-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelUploadFilesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileUploadOptions.cancelText; _ck(_v, 1, 0, currVal_0); }); }
function View_FormbuttonsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-spinner rotating"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.fileUploadOptions.canCancel; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "alert alert-", _co.fileUploadOptions.type, " mt-3"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.fileUploadOptions.text; _ck(_v, 3, 0, currVal_1); }); }
function View_FormbuttonsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "btn btn-default"]], [[2, "disabled", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { ignoreLoadingBar: 0 }), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, true); var currVal_4 = _ck(_v, 3, 0, (("/admin/" + _co.tableName) + "/show")); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.actionInProgress ? true : null); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.cancel; _ck(_v, 4, 0, currVal_5); }); }
function View_FormbuttonsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner rotating"]], null, null, null, null, null))], null, null); }
function View_FormbuttonsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.save; _ck(_v, 1, 0, currVal_0); }); }
function View_FormbuttonsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copy; _ck(_v, 1, 0, currVal_0); }); }
function View_FormbuttonsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_8)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCancel; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.actionInProgress; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.formType == "create"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.formType == "copy"); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mainForm.invalid; _ck(_v, 3, 0, currVal_1); }); }
function View_FormbuttonsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "btn btn-default"]], [[2, "disabled", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { ignoreLoadingBar: 0 }), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, true); var currVal_4 = _ck(_v, 3, 0, (("/admin/" + _co.tableName) + "/show")); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.actionInProgress ? true : null); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.cancel; _ck(_v, 4, 0, currVal_5); }); }
function View_FormbuttonsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner rotating"]], null, null, null, null, null))], null, null); }
function View_FormbuttonsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_12)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.actionInProgress; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainForm.invalid; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.update; _ck(_v, 4, 0, currVal_2); }); }
function View_FormbuttonsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_11)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCancel; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.permissions.Edit && !_co.readonly); _ck(_v, 4, 0, currVal_1); }, null); }
function View_FormbuttonsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "btn btn-default"]], [[2, "disabled", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { state: [0, "state"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { ignoreLoadingBar: 0 }), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 2, 0, true); var currVal_4 = _ck(_v, 3, 0, (("/admin/" + _co.tableName) + "/show")); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.actionInProgress ? true : null); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.cancel; _ck(_v, 4, 0, currVal_5); }); }
function View_FormbuttonsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_14)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCancel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormbuttonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-log-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_9)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormbuttonsComponent_13)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainForm.hasError("hasError"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.fileUploadOptions && _co.fileUploadOptions.hasFiles); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.formType == "create") || (_co.formType == "copy")); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.formType == "update"); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.formType == "show"); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_FormbuttonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formbuttons", [], null, null, null, View_FormbuttonsComponent_0, RenderType_FormbuttonsComponent)), i1.ɵdid(1, 114688, null, 0, i4.FormbuttonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormbuttonsComponentNgFactory = i1.ɵccf("app-formbuttons", i4.FormbuttonsComponent, View_FormbuttonsComponent_Host_0, { formType: "formType", permissions: "permissions", actionInProgress: "actionInProgress", mainForm: "mainForm", tableName: "tableName", sts: "sts", fileUploadOptions: "fileUploadOptions", readonly: "readonly", showCancel: "showCancel" }, { cancelUploadFiles: "cancelUploadFiles" }, []);
export { FormbuttonsComponentNgFactory as FormbuttonsComponentNgFactory };
