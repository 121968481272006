import { Track, TrackItem } from "./Track";

export interface Subtitle extends Track{
    slideId?:number
    text:string
    color?:string
}

export class SubtitleItem extends TrackItem{
    slideId=0;
    text="";
    color="";
}