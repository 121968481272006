import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {sampleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  private _time = new BehaviorSubject<number>(0);
  private _maxTime = new BehaviorSubject<number>(0);
  private _isPlaying = new BehaviorSubject<number>(0);
  private _saved = new BehaviorSubject<boolean>(true);
  private _volume = new BehaviorSubject<number>(0.7);
  private _duration = new BehaviorSubject<number>(0);
  private _resetDuration = new BehaviorSubject<boolean>(false);
  private _nextSlide = new BehaviorSubject<boolean>(false);
  private _contentIndex = new BehaviorSubject<number>(null);
  time: Observable<number> = this._time.asObservable();
  maxTime: Observable<number> = this._maxTime.asObservable();
  isPlaying: Observable<number> = this._isPlaying.asObservable();
  saved: Observable<boolean> = this._saved.asObservable();
  volume: Observable<number> = this._volume.asObservable().pipe(sampleTime(200));
  duration: Observable<number> = this._duration.asObservable();
  resetDuration: Observable<boolean> = this._resetDuration.asObservable();
  nextSlide: Observable<boolean> = this._nextSlide.asObservable();
  contentIndex: Observable<number> = this._contentIndex.asObservable();

  constructor() { }
  SetContentIndex(i: number) {
    this._contentIndex.next(i);
  }
  SetSaved(saved: boolean, from: string) {
    console.log(from);
    this._saved.next(saved);
  }
  SetTime(time: number, from: string) {
    // console.log(from);
    this._time.next(time);
  }
  SetVolume(vol: number) {
    this._volume.next(vol);
  }
  SetMaxTime(mTime: number) {
    this._maxTime.next(mTime);
  }
  Play() {
    console.log('---------Called Play in service');
    this._isPlaying.next(2);
  }
  Pause() {
    this._isPlaying.next(1);
  }
  Stop() {
    this._isPlaying.next(0);
    setTimeout(() => {
    this._time.next(0);
    }, 0);
  }
  SetSlideDuration(duration: number) {
    this._duration.next(duration);
  }
  SetOriginalSlideDuration() {
    this._resetDuration.next(true);
  }
  NextSlide(next: boolean) {
    this._nextSlide.next(next);
  }

}
