import {Component, OnInit} from '@angular/core';
import {MarketplaceService} from '../../services/marketplace.service';
import {SystemtextService} from '../../../services/systemtext.service';
import {environment} from '../../../../environments/environment';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

    constructor(private marketplaceService: MarketplaceService, protected sts: SystemtextService, private sanitizer: DomSanitizer) {
    }

    actionInProgress:boolean = true;

    downloadUrl:string = `${environment.backendApiUrl}${environment.backendApiVersion}/files/getbyid/`;

    books: any;
    booksLength:number = 0;
    langTitle: string;
    CatTitle: string;
    orgTitle: string;
    booksDescTitle: string;
    extractTitle: string;
    searchTitle: string;
    noImageText: string;
    closeText: string;

    there_are_no_books:string;

    sort_by:string;
    sort_title_az:string;
    sort_title_za:string;
    sort_language_text_az:string;
    sort_language_text_za:string;
    sort_category_text_az:string;
    sort_category_text_za:string;
    sort_organization_name_az:string;
    sort_organization_name_za:string;

    bgColor: any = [];
    textColor: any = [];

    sortProperty = 'name';


    modal_title:string;
    modal_body:string;
    modal_ar_meta:any;
    modal_language:string;
    modal_languagecode:string;
    modal_category:string;
    modal_organization:string;

    modal_ar_files_title:string;

    modal_has_ar_meta_grouped:boolean = false;

    flags:any;

    searchText;

    ngOnInit() {

        this.flags = JSON.parse(sessionStorage.getItem("flags"));


        this.marketplaceService.getItems().subscribe(books => {

            // console.log('items:', books);

            this.books = books.items;
            this.sts.st = Object.assign({}, this.sts.st, books.st);

            this.langTitle = this.sts.c('books.language');
            this.CatTitle = this.sts.c('books.category');
            this.orgTitle = this.sts.c('orgs.organization');
            this.booksDescTitle = this.sts.c('books.description');
            this.extractTitle = this.sts.c('marketplace.extract');
            this.searchTitle = this.sts.c('marketplace.search');
            this.noImageText = this.sts.c('marketplace.no_image');
            this.closeText = this.sts.c('marketplace.close');

            this.modal_ar_files_title = this.sts.c('books.ar_files');

            this.there_are_no_books = this.sts.c('marketplace.there_are_no_books');

            this.sort_by = this.sts.c('marketplace.sort_by');
            this.sort_title_az = this.sts.c('marketplace.sort_title_az');
            this.sort_title_za = this.sts.c('marketplace.sort_title_za');
            this.sort_language_text_az = this.sts.c('marketplace.sort_language_text_az');
            this.sort_language_text_za = this.sts.c('marketplace.sort_language_text_za');
            this.sort_category_text_az = this.sts.c('marketplace.sort_category_text_az');
            this.sort_category_text_za = this.sts.c('marketplace.sort_category_text_za');
            this.sort_organization_name_az = this.sts.c('marketplace.sort_organization_name_az');
            this.sort_organization_name_za = this.sts.c('marketplace.sort_organization_name_za');


            this.books.forEach((book) => {
                var c = this.getRandomBg(book);
                var t = this.getTextColor(c);
                this.bgColor[book.id] = c;

                this.textColor[book.id] = t;
                book.language_text = this.sts.c(book.language);

                var lang = book.language.split('.');
                book.language_code = lang[1];

                book.category_text = this.sts.c(book.category);
                var text = this.sts.c('books.ar_files_piece');

                book.ar_files_title = text.replace(':item', book.ar_meta_grouped.length);


                if(book.content_preview != undefined && book.content_preview.length > 0) {
                    book.cpurl = this.downloadUrl+book.content_preview[0].id;
                }
                if(book.cover_image != undefined && book.cover_image.length > 0) {
                    var url = this.downloadUrl+book.cover_image[0].id;
                    book.ciurl = this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
                }
            });

            this.booksLength = this.books.length;
            this.actionInProgress = false;

        }, error => {
            console.log('error:', error);
            // var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
            // this.messageService.sendMessage({text: text, type: 'danger'});
        });

    }

    getRandomBg(book) {
        //return Math.floor(Math.random()*16777215).toString(16);
        var d = '0.' + book.id  * book.name.length;
        return "#"+Math.floor(parseFloat(d) * 16777215).toString(16);
    }

    getTextColor(color) {
        color = color.slice(1);
        var r, g, b, hsp;
        var bigint = parseInt(color, 16);
        r = (bigint >> 16) & 255;
        g = (bigint >> 8) & 255;
        b = bigint & 255;

        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        if (hsp>127.5) {
            //'light';
            return '#000';
        }
        else {
            // 'dark';
            return '#fff';
        }

    }

    setModalContent(book) {
        this.modal_title = book.name;
        this.modal_language = book.language_text;
        this.modal_languagecode = book.language_code;
        this.modal_category = book.category_text;
        this.modal_organization = book.organization_name+" ("+book.organization_email+")";
        this.modal_body = book.description;
        this.modal_ar_meta = book.ar_meta_grouped;
        this.modal_has_ar_meta_grouped = book.ar_meta_grouped.length > 0 ? true : false;
        this.modal_ar_files_title = book.ar_files_title;
    }

    nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    removeSearchText(text) {
        text ? this.searchText = "" : null;
    }

}


