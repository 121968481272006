<div class="form-singlefileupload control">

    <div class="fixed-width-col">
        <a *ngIf="url" href="{{url}}" target="_blank" title="{{ filename }}">
            <span *ngIf="!isVideo" [class]="thumbnailClass.join(' ')"  [style.backgroundImage]="thumbnailBgImageStyle"></span>
            <video *ngIf="isVideo" class="thumbnail-video"  [src]="_downloadUrl" type="video/mp4"></video>
        </a>
        <span *ngIf="!url" [class]="thumbnailClass.join(' ')"  [style.backgroundImage]="thumbnailBgImageStyle"></span>
    </div>

    <div class="flex-width-col">
        <span class="label">
            <span *ngIf="!url">{{ filename }}</span>
            <a *ngIf="url" href="{{url}}" target="_blank" title="{{ filename }}">{{ filename }}</a>
        </span>
    </div>

    <div class="fixed-width-col"></div>
        <button
            *ngIf="!readonly"
            type="button"
            class="destroy-button delete-btn"
            (click)="onRemoveButtonClick($event)">
            <i class="fas fa-trash-alt"></i>
            <!--{{ destroyButtonTitle || defaultDestroyButtonTitle }}-->

        </button>

</div>
