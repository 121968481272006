<nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
  <div id="nav-icon" (click)="setNavbarLeftOpen()" [ngClass]="NavbarLeftOpen ? '' : 'closed'">
    <span></span>
    <span></span>
    <span></span>
  </div>

  <div class="logo">
    <!-- <img src="https://via.placeholder.com/25/FFFFFF/000000?text=img"> -->
    <a class="navbar-brand d-none d-sm-block" href="#">{{orgname}}</a>
  </div>



  <div class="user-container">
    <div class="language-container">
      <app-formmultiselect [itemList]="langItemList" [itemSettings]="langSettings"
                           [title]="''" [formControlNameString]="'languageselector'"
                           [control]="langControl"
                           [parentFormGroup]="mainForm" [actionInProgress]="actionInProgress"></app-formmultiselect>

    </div>
    <div class="user-name">
      {{userName}}
    </div>

    <div class="adminbuttons">
      <div class="btn-group" role="group">
        <div class="btn-user" id="adminUserMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-user"></i></div>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="adminUserMenu">
          <!-- <a class="nav-link" href="javascript:void(0)"><i class="fas fa-cog"></i> {{settingsText}}</a> -->
          <a class="nav-link" [routerLink]="['/logout']"><i class="fas fa-sign-out-alt"></i> {{logoutText}}</a>
        </div>
      </div>
    </div>
  </div>


</nav>