import { OnDestroy, OnInit } from '@angular/core';
import { AdminformComponent } from '../../controllers/adminform/adminform.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
export class UserformComponent extends AdminformComponent {
    constructor() {
        super(...arguments);
        this.rolesItemList = [];
        this.organizationsItemList = [];
        // rolesSelectedItems = [];
        this.rolesSettings = {};
        this.organizationsSettings = {};
        this.rolesFetched = false;
        this.selectedItemHasSuperadminRole = false;
        this.selectedItemHasAdminRole = false;
        this.permanentDatas = [];
    }
    ngOnInit() {
        this.tableName = 'users';
        this.formName = 'user';
        this.setPermissions(this.tableName);
        this.sts.st = { form: this.localeText };
        this.permissions = this.permissionsService.getPermissions(this.tableName);
        // Common
        this.rolesSettings = {
            text: 'Select Roles',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            classes: 'roles-list',
            enableSearchFilter: true,
            disabled: !this.permissions.Edit
        };
        this.organizationsSettings = {
            text: 'Select Organization',
            classes: 'organizations-list',
            enableSearchFilter: true,
            singleSelection: true,
            enableFilterSelectAll: false,
            disabled: !this.permissions.Edit
        };
        // Update
        this.params = this.activatedRoute.params.subscribe(params => {
            this.userId = params['id'];
            if (this.userId) {
                this.admingridService.getItem(this.tableName, this.userId).subscribe(data => {
                    if (data['client_data']) {
                        this.clientData = data['client_data'];
                    }
                    if (data['client_book']) {
                        this.clientBook = data['client_book'];
                    }
                    this.sts.st = Object.assign({}, this.sts.st, data.st);
                    this.replaceSystemTitle('users.user');
                    this.replaceTexts();
                    this.mainForm.controls['name'].setValue(data['item']['name']);
                    this.mainForm.controls['email'].setValue(data['item']['email']);
                    this.mainForm.controls['id'].setValue(data['item']['id']);
                    this.mainForm.controls['organization'].setValue(data['item']['userorganization']);
                    //console.log('data[\'item\'][\'roles\']', data['item']['roles']);
                    this.createItemList(data['roles'], data['item']['roles'], 'roles', 'roles');
                    this.createItemList(data['organizations'], [data['organization']], 'organizations', 'organization');
                    // restrict permission
                    if ((this.selectedItemHasAdminRole && !this.permissions.isSuperadmin && !this.permissions.isAdmin)
                        ||
                            (this.selectedItemHasSuperadminRole && !this.permissions.isSuperadmin)) {
                        if (this.selectedItemHasAdminRole)
                            this.permanentDatas.push(2); // Admin id
                        if (this.selectedItemHasSuperadminRole)
                            this.permanentDatas.push(1); // Superadmin id
                        this.higherRoleIsNeeded;
                        this.higherRoleIsNeeded = true;
                    }
                    this.formReadyToShow = true;
                }, error => {
                    console.log(error);
                    var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                    this.messageService.sendMessage({ text: text, type: 'danger' });
                });
            }
            this.passwordControl = this.fb.control('', [this.passwordValidator(this.formType)]);
            this.confirmationControl = this.fb.control('', this.passwordValidator(this.formType));
            this.nameControl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
            this.emailControl = this.fb.control('', [Validators.required, this.isValidEmail]);
            this.roleControl = this.fb.control('');
            this.organizationControl = this.fb.control('', [Validators.required]);
            this.passwordForm = this.fb.group({
                password: this.passwordControl,
                password_confirmation: this.confirmationControl
            }, { validator: this.samePassword });
            this.mainForm = this.fb.group({
                id: '',
                email: this.emailControl,
                name: this.nameControl,
                passwordForm: this.passwordForm,
                roles: this.roleControl,
                organization: this.organizationControl,
            });
            // console.log('this.mainForm:', this.mainForm.controls);
        });
        // Create
        if (this.formType == 'create') {
            this.admingridService.createItem(this.tableName).subscribe(datas => {
                this.sts.st = Object.assign({}, this.sts.st, datas.st);
                this.replaceSystemTitle('users.user');
                this.replaceTexts();
                this.roles = datas.roles;
                this.createItemList(datas.roles, false, 'roles', 'roles');
                this.organizations = datas.organizations;
                this.createItemList(datas.organizations, false, 'organizations', 'organization');
                this.formReadyToShow = true;
            }, error => {
                console.log(error);
                var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                this.messageService.sendMessage({ text: text, type: 'danger' });
            });
        }
    }
    createItemList(items, selectedItems, listname, controlname) {
        var itemListArray = [];
        items.forEach((item) => {
            itemListArray.push({ 'id': item.id, 'itemName': item.name });
            if (selectedItems && listname == 'roles') {
                if (item.name === 'Superadmin')
                    this.selectedItemHasSuperadminRole = true;
                if (item.name === 'Admin')
                    this.selectedItemHasAdminRole = true;
            }
        });
        this[listname + 'ItemList'] = itemListArray;
        if (listname === 'roles') {
            this.rolesFetched = true;
        }
        if (selectedItems) {
            const selectedItemListArray = [];
            selectedItems.forEach((item) => {
                selectedItemListArray.push({ 'id': item.id, 'itemName': item.name });
            });
            this.mainForm.controls[controlname].setValue(selectedItemListArray);
        }
    }
    ngOnDestroy() {
        this.params.unsubscribe();
    }
    replaceTexts() {
        this.sts.replace('form.select_item', [{ 'find': ':item', 'replace': 'roles.role' }]);
        this.rolesSettings['text'] = this.sts.c('form.select_item');
        this.rolesSettings['selectAllText'] = this.sts.c('form.select_all');
        this.rolesSettings['unSelectAllText'] = this.sts.c('form.un_select_all');
        this.rolesSettings['searchPlaceholderText'] = this.sts.c('form.search');
        var rolesRole = this.sts.c('roles.role');
        this.sts.replace('form.select_item', [{ 'find': rolesRole, 'replace': 'orgs.organization' }]);
        this.organizationsSettings['text'] = this.sts.c('form.select_item');
        this.organizationsSettings['searchPlaceholderText'] = this.sts.c('form.search');
    }
}
