import { Directive, HostBinding, Output,EventEmitter, OnInit, HostListener } from '@angular/core';
import { Subject,Subscription, fromEvent } from 'rxjs';
import { switchMap,takeUntil,repeat,take, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit {
  @HostBinding('class.draggable') draggable = true;
  @HostBinding('class.grabbing') grabbing = false;

  @Output() dragStart = new EventEmitter<PointerEvent>();
  @Output() dragMove = new EventEmitter<PointerEvent>();
  @Output() dragEnd = new EventEmitter<PointerEvent>();

  private pointerDown = new Subject<PointerEvent>();
  private pointerMove = new Subject<PointerEvent>();
  private pointerUp = new Subject<PointerEvent>();
  pointerMoveSub: Subscription;
  pointerUpSub: Subscription;
  pMoveSub: Subscription;
  pUpSub: Subscription;
  @HostListener('pointerdown',['$event']) onPointerDown(event :PointerEvent):void{
    this.pointerDown.next(event);
  }
  ngOnDestroy() {
    if(this.pointerMoveSub){
    this.pointerMoveSub.unsubscribe();
    }
    if(this.pointerUpSub){
      this.pointerUpSub.unsubscribe();
      }
    if(this.pMoveSub){
      this.pMoveSub.unsubscribe();
      }
      if(this.pMoveSub){
        this.pUpSub.unsubscribe();
        }
  }
  ngOnInit(){
    this.pointerDown.asObservable().subscribe(event=> {
      this.grabbing = true;
      this.dragStart.emit(event);
      this.pMoveSub = fromEvent(document, 'pointermove').subscribe((e:PointerEvent) => {
        this.pointerMove.next(e)});
      this.pUpSub = fromEvent(document, 'pointerup').subscribe((e:PointerEvent) => {
        this.pointerUp.next(e);});
      this.pointerMoveSub = this.pointerMove.subscribe(event=>{
        this.grabbing = true;
        this.dragMove.emit(event);
      });
      this.pointerUpSub = this.pointerUp.subscribe(event=>{
        this.grabbing = false;
        this.dragEnd.emit(event);
        this.pointerMoveSub.unsubscribe();
        this.pointerUpSub.unsubscribe();
        this.pMoveSub.unsubscribe();
        this.pUpSub.unsubscribe();
      });
    });
  }
}
