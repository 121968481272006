import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, combineLatest, forkJoin, concat } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileLoaderService {
  url: string;

  _downloadState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  downloadState: Observable<boolean> = this._downloadState.asObservable();

  _isAudioFilesDownloaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isSubtitleFilesDownloaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isLabelFileDownloaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _isConfigDownloaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAudioFilesDownloaded: Observable<boolean> = this._isAudioFilesDownloaded.asObservable();
  isSubtitleFilesDownloaded: Observable<boolean> = this._isSubtitleFilesDownloaded.asObservable();
  isLabelFileDownloaded: Observable<boolean> = this._isLabelFileDownloaded.asObservable();
  isConfigDownloaded: Observable<boolean> = this._isConfigDownloaded.asObservable();

  _uploadState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  uploadState: Observable<boolean> = this._uploadState.asObservable();

  _isAudioFilesUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  _isSubtitleFilesUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  _isLabelFileUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  _isConfigUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isAudioFilesUploaded: Observable<boolean> = this._isAudioFilesUploaded.asObservable();
  isSubtitleFilesUploaded: Observable<boolean> = this._isSubtitleFilesUploaded.asObservable();
  isLabelFileUploaded: Observable<boolean> = this._isLabelFileUploaded.asObservable();
  isConfigUploaded: Observable<boolean> = this._isConfigUploaded.asObservable();
  constructor(private http: HttpClient) {
    this.url = this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    this.isAudioFilesUploaded.subscribe();
    this.isSubtitleFilesUploaded.subscribe();
    this.isLabelFileUploaded.subscribe();
    const uploadComleted = combineLatest([
      this.isAudioFilesUploaded,
       this.isSubtitleFilesUploaded,
        this.isLabelFileUploaded,
         this.isConfigUploaded]);
    const downloadComleted = combineLatest([
      this.isAudioFilesDownloaded,
       this.isSubtitleFilesDownloaded,
        this.isLabelFileDownloaded,
         this.isConfigDownloaded]);
    uploadComleted.subscribe(result => {
      const comp = result.every(r => r === true);
        console.log('File upload status : ', comp);
        this._uploadState.next(comp);
    });
    downloadComleted.subscribe(result => {
      const comp = result.every(r => r === true);
      console.log('File download status : ', comp);
      this._downloadState.next(comp);
    });
   }

  Prepare(id: number, fd: FormData): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this.http.post<any>(this.url + '/books/files/' + id, fd, {headers});
  }

 Finalize(bookId: number, uuid: string) {
    const token = sessionStorage.getItem('token');
    const params = new HttpParams().append('_file_data_uuid', uuid);
    const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this.http.get(this.url + '/books/finalizearadditionalfiles/' + bookId, {headers, params});
  }
  CreateZip(bookId: number ){
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this.http.get(this.url + '/books/createzipafterfinalizearadditionalfiles/' + bookId, {headers});
  }
  GetFile(id: number) {
    return this.http.get(this.url + '/files/getbyid/' + id);
  }
  DeleteAudioFile(bookId: number, uid: string, name: string) {
    const token = sessionStorage.getItem('token');
    const params = new HttpParams().append('form_control_uid', uid).append('form_control_name', name);
    const headers = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this.http.delete(this.url + '/books/deletearadditionalfiles/' + bookId, {headers, params});
  }
   GetAudioFile(id: number) {
    const token = sessionStorage.getItem('token');
    const headers = new HttpHeaders('Content-Type: audio/mpeg3;audio/x-mpeg-3;video/mpeg;video/x-mpeg;text/xml');
    headers.append('Authorization', 'Bearer ' + token);
    return this.http.get(this.url + '/files/getbyid/' + id, {headers, responseType: 'arraybuffer'}).toPromise();
  }

  IsAudioUploaded(uploaded: boolean) {
    this._isAudioFilesUploaded.next(uploaded);
  }
  IsSubtitleUploaded(uploaded: boolean) {
    this._isSubtitleFilesUploaded.next(uploaded);
  }
  IsLabelUploaded(uploaded: boolean) {
    this._isLabelFileUploaded.next(uploaded);
  }
  IsConfigUploaded(uploaded: boolean) {
    this._isConfigUploaded.next(uploaded);
  }

  IsAudioDownloaded(downloaded: boolean) {
    this._isAudioFilesDownloaded.next(downloaded);
  }
  IsSubtitleDownloaded(downloaded: boolean) {
    this._isSubtitleFilesDownloaded.next(downloaded);
  }
  IsLabelDownloaded(downloaded: boolean) {
    this._isLabelFileDownloaded.next(downloaded);
  }
  IsConfigDownloaded(downloaded: boolean) {
    this._isConfigDownloaded.next(downloaded);
  }
}
