import { Directive, HostListener, ElementRef ,Input, EventEmitter, Output} from '@angular/core';
import { DraggableDirective } from './draggable.directive';
import { Subtitle } from '../../models/SubtitleItem';
import { Track } from '../../models/Track';

@Directive({
  selector: '[appResizeable]'
})
export class ResizeableDirective extends DraggableDirective {
  /**Container width of the parent */
  @Input('containerwidth') containerwidth : number;
  /**Current track item*/
  @Input('track') track : Track;
  /**Track type, 
   * start: controls the start point of the track item, 
   * end: controls the end point of the track item, 
   * move: controls the grabbable area of the track item, */
  @Input('type') type : 'start' | 'end' | 'move';
  /**Maximum time in seconds*/
  @Input('max') max : number;
  /**Track item array*/
  @Input('elements') elements : Track[];
  /**If true, the user can move the element */
  @Input('moveable') moveable : boolean;
  @Output() onMove = new EventEmitter<any>();
  public position = {x:0,y:0};
  private offsetFromStart:number;
  private offsetFromEnd:number;

  Setup(v:number){
    let dragStartPos = this.GetRangePercentOnScreen(v,this.containerwidth);
      let offsetStart = this.GetRangePercent(0,this.track.start);
      let offsetEnd = this.GetRangePercent(0,this.track.end);
      this.offsetFromStart= dragStartPos-offsetStart;
      this.offsetFromEnd= dragStartPos-offsetEnd;
  }
  
  @HostListener('dragStart', ['$event']) onDragStart(event:PointerEvent){
    event.preventDefault();
    if(this.moveable){
      let dragStartPos = this.GetRangePercentOnScreen(event.clientX,this.containerwidth);
      let offsetStart = this.GetRangePercent(0,this.track.start);
      let offsetEnd = this.GetRangePercent(0,this.track.end);
      this.offsetFromStart= dragStartPos-offsetStart;
      this.offsetFromEnd= dragStartPos-offsetEnd;
    }
  }

  @HostListener('dragMove', ['$event']) onDragMove(event:PointerEvent){
    event.preventDefault();
    if(this.moveable){
      switch(this.type){
        case 'start':
        this.SetCurrentRangeStart(event.clientX);
        break;
        case 'end':
        this.SetCurrentRangeEnd(event.clientX);
        break;
        case 'move':
        this.SetTrackPosition(event.clientX);
        
        break;
      }
      this.onMove.emit();
    }
  }
  @HostListener('dragEnd', ['$event']) onDragEnd(event:PointerEvent){
    event.preventDefault();
  }
  SetTrackPosition(v){
    let current = this.track;
    let perc =  this.GetRangePercentOnScreen(v,this.containerwidth);
    let value = this.GetValueFromPercent(perc-this.offsetFromStart);
    let previous = this.elements[this.track.id-1];
    let next = this.elements[this.track.id+1];
    if(value+this.GetValueFromPercent(current.range) >= next.end){
      value = next.end -this.GetValueFromPercent(current.range) ;
    }
    if(value <= previous.start){
      value = previous.start;
    }
        current.start = value;
        current.end = value+this.GetValueFromPercent(current.range);
        next.start = current.end;
        previous.end = current.start;
        current.range = this.GetRangePercent(current.start,current.end);
        previous.range = this.GetRangePercent(previous.start,previous.end);
        next.range = this.GetRangePercent(next.start,next.end);
    }
  SetCurrentRangeStart(v){
    let perc =  this.GetRangePercentOnScreen(v,this.containerwidth);
    let value = this.GetValueFromPercent(perc-this.offsetFromStart);
    let current = this.track;
      if(current.id >0){
        let previous = this.elements[this.track.id-1];
        value = Math.max(value,previous.start);
        if(previous.isEmpty && value >= previous.start && value < current.end){
          current.start = value;
          current.range = this.GetRangePercent(current.start,current.end);
          previous.end = value;
          previous.range = this.GetRangePercent(previous.start,previous.end );
        }
      }
    }
    SetCurrentRangeEnd(v){
      let perc =  this.GetRangePercentOnScreen(v,this.containerwidth);
      let value = this.GetValueFromPercent(perc-this.offsetFromEnd);
     let current = this.track;
       if(current.id < this.elements.length-1){
          let next = this.elements[this.track.id+1];
          value = Math.min(value,next.end);
          if(next.isEmpty && value <= next.end && value > current.start){
            current.end = value;
            current.range = this.GetRangePercent(current.start,current.end);
            next.start = value;
            next.range = this.GetRangePercent(next.start,next.end );
          }
        }
      }
      GetRangePercent(start:number,end:number):number{
        return (end-start)/this.max *100;
      }
      GetValueFromPercent(percent:number):number{
        return this.max /100*percent;
      }
      GetRangePercentOnScreen(start:number,max:number):number{
        return start/max *100;
      }
}
