<div class="container-fluid" [ngClass]="NavbarLeftOpen ? '' : 'navbar-left-closed'">
    <div class="row">
        <app-navbartop  [style.display]="onlymaincontent ? 'none' : ''" [NavbarLeftOpen]="NavbarLeftOpen" (update)="setNavbarLeftOpen($event)"></app-navbartop>
        <app-navbarleft [style.display]="onlymaincontent ? 'none' : ''" [NavbarLeftOpen]="NavbarLeftOpen" [innerWidth]="innerWidth"
                        (update)="setNavbarLeftOpen($event)"></app-navbarleft>
        <main class="main" [style.padding]="onlymaincontent ? '0px' : ''">
            <ngx-loading-bar [includeSpinner]="false" [includeBar]="true" [fixed]="false"></ngx-loading-bar>

            <div *ngIf="message != null" class="message container" [@slideInOut]="messageState">
                <div class="alert alert-{{messageType}}" role="alert">
                    {{message}}
                </div>
            </div>

            <!-- <button type="button" (click)="modal.show()">Modal test</button> -->
            <app-modal #modal [(showModal)]="showLoginModal" [hideFooter]="true">
                <div class="app-modal-header">
                    Token has expired, Please login.
                </div>
                <div class="app-modal-body">
                    <app-login [isModal]="true" [cancelIsLogout]="true" (hideModal)="modal.hide()"></app-login>
                </div>
            </app-modal>

            <router-outlet></router-outlet>
        </main>
    </div>
</div>
