import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LanguageService {
    constructor(http) {
        this.http = http;
    }
    getLanguages() {
        var webdatas = JSON.parse(sessionStorage.getItem("webdatas"));
        var lang = localStorage.getItem("lang");
        return of([
            { 'id': 1, 'itemName': webdatas[lang]['site_languages']['Hungarian'], 'code': 'hu', 'image': '' },
            { 'id': 2, 'itemName': webdatas[lang]['site_languages']['English'], 'code': 'en', 'image': '' }
        ]);
    }
    getLanguageCodes() {
        return of(['hu', 'en']);
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.HttpClient)); }, token: LanguageService, providedIn: "root" });
