/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formsinglefile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./formsinglefile.component";
import * as i4 from "../../../../../services/systemtext.service";
import * as i5 from "@angular/platform-browser";
var styles_FormsinglefileComponent = [i0.styles];
var RenderType_FormsinglefileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormsinglefileComponent, data: {} });
export { RenderType_FormsinglefileComponent as RenderType_FormsinglefileComponent };
function View_FormsinglefileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0], [4, "backgroundImage", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnailClass.join(" "); var currVal_1 = _co.thumbnailBgImageStyle; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FormsinglefileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "video", [["class", "thumbnail-video"], ["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._downloadUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_FormsinglefileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isVideo; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.isVideo; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.url, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.filename, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FormsinglefileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0], [4, "backgroundImage", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnailClass.join(" "); var currVal_1 = _co.thumbnailBgImageStyle; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FormsinglefileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filename; _ck(_v, 1, 0, currVal_0); }); }
function View_FormsinglefileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.url, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.filename, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.filename; _ck(_v, 1, 0, currVal_2); }); }
function View_FormsinglefileComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "destroy-button delete-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-trash-alt"]], null, null, null, null, null))], null, null); }
export function View_FormsinglefileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "form-singlefileupload control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "fixed-width-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "flex-width-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "fixed-width-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormsinglefileComponent_7)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.url; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.url; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.url; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.readonly; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_FormsinglefileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formsinglefileupload", [], null, null, null, View_FormsinglefileComponent_0, RenderType_FormsinglefileComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormsinglefileComponent, [i4.SystemtextService, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormsinglefileComponentNgFactory = i1.ɵccf("app-formsinglefileupload", i3.FormsinglefileComponent, View_FormsinglefileComponent_Host_0, { url: "url", fileObj: "fileObj", file: "file", readonly: "readonly" }, { destroyRequest: "destroyRequest", setThumbnailIconByMimeTypeRequest: "setThumbnailIconByMimeTypeRequest" }, []);
export { FormsinglefileComponentNgFactory as FormsinglefileComponentNgFactory };
