/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formheader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./formheader.component";
var styles_FormheaderComponent = [i0.styles];
var RenderType_FormheaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormheaderComponent, data: {} });
export { RenderType_FormheaderComponent as RenderType_FormheaderComponent };
function View_FormheaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "form-control"], ["id", "id"], ["name", "id"], ["type", "hidden"]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_FormheaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormheaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.formType == "update"); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormheaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formheader", [], null, null, null, View_FormheaderComponent_0, RenderType_FormheaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormheaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormheaderComponentNgFactory = i1.ɵccf("app-formheader", i3.FormheaderComponent, View_FormheaderComponent_Host_0, { formType: "formType", formName: "formName", id: "id", sts: "sts" }, {}, []);
export { FormheaderComponentNgFactory as FormheaderComponentNgFactory };
