<div *ngIf="permissions.Show" class="container-fluid">


  <div class="row">
    <div class="col align-self-center mt-4">

      <div *ngIf="readyToShow">

        <div *ngFor="let booksDataItem of booksData | keyvalue">

          <div class="org-title" *ngIf="isSuperadmin">{{booksDataItem.key}}</div>

          <div class="books row">
            <div class="col-md-6" *ngFor="let bookItem of booksDataItem.value | keyvalue">
              <div class="card py-2">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <!-- <img style="max-width: 100%;width: 50px;padding: 10px" src='{{rootUrl}}/files/getbyid/{{booksData[booksDataItem.key][bookItem.key]["bookData"]["cover_image_id"]}}'/> -->
                      <div class="book-title">{{booksData[booksDataItem.key][bookItem.key]["bookData"]["name"]}}</div>
                      <div class="book-counter">{{st['grid']['all_activations']}}: {{booksData[booksDataItem.key][bookItem.key]['userCount']}}</div>
                    </div>
                    <div class="col-md-12" *ngIf="booksData[booksDataItem.key][bookItem.key]['hasData']">
                      <google-chart #chart
                                    [title]="chartTitle"
                                    [type]="chartType"
                                    [data]="booksData[booksDataItem.key][bookItem.key]['chartData']"
                                    [options]="chartOptions"
                                    [width]="'100%'"
                                    [height]="200"
                                    [dynamicResize]="true"
                                    [columns]="colNames"
                                    style="width:100%;padding: 10px; margin: 0 auto"

                      >
                      </google-chart>
                    </div>
                    <div class="no-data" *ngIf="!booksData[booksDataItem.key][bookItem.key]['hasData']">{{st['grid']['no_data']}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

