<!-- Button trigger modal -->
<button id="openGridModal" [hidden]="true" type="button" data-toggle="modal" data-target="#gridModal">
  Open Grid Modal
</button>

<!-- Modal -->
<div class="modal fade" #gridModal id="gridModal" tabindex="-1" role="dialog" aria-labelledby="gridModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="gridModalLabel">{{girdModalOptions.title}}</h5>
        <button *ngIf="girdModalOptions.close.showx"  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-left">
        {{girdModalOptions.body}}
        <div [hidden]="!girdModalOptions.passwordConfirmation.show">
          <hr>
          {{passwordText}}:<br/>
          <input type="password"  autocomplete="off" id="modalpasswordconfirmation" name="modalpasswordconfirmation" class="form-control" (input)="onPasswordConfirmationChange($event.target.value)">
          <div *ngIf="girdModalOptions.passwordConfirmation.errorEmpty" class="alert alert-danger">{{passwordErrorEmpty}}</div>
          <div *ngIf="girdModalOptions.passwordConfirmation.errorBadPw" class="alert alert-danger">{{passwordErrorBad}}</div>

        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="girdModalOptions.actionInProgress ? true: null" [hidden]="!girdModalOptions.action1.show" type="button" class="btn {{girdModalOptions.action1.class}}" (click)="gridModalActionEmit(girdModalOptions.action1.params)">
          <i *ngIf="girdModalOptions.actionInProgress" class="fas fa-spinner rotating"></i><span> {{girdModalOptions.action1.text}}</span>
        </button>
        <button [disabled]="girdModalOptions.actionInProgress ? true: null" [hidden]="!girdModalOptions.action2.show" type="button" class="btn {{girdModalOptions.action2.class}}" (click)="gridModalActionEmit(girdModalOptions.action2.params)">
          <i *ngIf="girdModalOptions.actionInProgress" class="fas fa-spinner rotating"></i><span> {{girdModalOptions.action2.text}}</span>
        </button>
        <button [disabled]="girdModalOptions.actionInProgress ? true: null" [hidden]="!girdModalOptions.close.show" id="closeGridModal" type="button" class="btn {{girdModalOptions.close.class}}" data-dismiss="modal">
          {{girdModalOptions.close.text}}
        </button>
        <button [hidden]="true" id="closeGridModalHidden" type="button" class="btn {{girdModalOptions.close.class}}" data-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
