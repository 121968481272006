<div class="container" *ngIf="formReadyToShow">
  <div class="col-md-12 my-4">
    <div class="card card-body bg-light">
      <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm"
            autocomplete="off">
        <fieldset>

          <app-formheader [formType]="formType" [formName]="formName" [id]="kcId"
                          [sts]="sts"></app-formheader>

          <div class="text-left p-2">
            <div class="m-2">
              <div>{{sts.st.keycodes.keycode}}</div>
              <div>{{keyData.item.key}}</div>
            </div>
            <div class="m-2">
              <div>{{sts.st.keycodes.uses}}</div>
              <div>{{keyData.kc.uses + keyData.item.extra_uses }} ({{keyData.kc.uses}} + {{keyData.item.extra_uses }})</div>
            </div>
            <div class="m-2">
              <div>{{sts.st.keycodes.used}}</div>
              <div>{{keyData.users.length}}</div>
            </div>
            <div class="m-2">
              <div>{{sts.st.keycodes.users}}</div>
              <table>
                <thead>
                  <tr>
                    <td>{{sts.st.keycodes.user_name}}</td>
                    <td>{{sts.st.keycodes.user_device}}</td>
                    <td>{{sts.st.keycodes.user_created_at}}</td>
                    <td>{{sts.st.keycodes.delete}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of keyData.users" id="cbid-{{user.cbid}}">
                    <td>{{user.name}}</td>
                    <td>{{user.device_name}} ({{user.device}})</td>
                    <td>{{user.created_at}}</td>
                    <td><div title="{{sts.st.keycodes.delete}}" class="delete-icon" (click)="showDeleteModal(user.cbid, user.name, user.device_name, user.device)"><i class="fas fa-trash-alt"></i></div></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          <app-forminput [inputType]="'number'" [required]="true" [inputMinLength]="'1'"  [inputMaxLength]="'5000'" [control]="extraUsesControl"
                         [inputName]="'extra_uses'" [title]="'keycodes.extra_uses'" [sts]="sts"
                         [parentFormGroup]="mainForm" [permissions]="permissions"
                         [higherRoleIsNeeded]="higherRoleIsNeeded"
                         [actionInProgress]="actionInProgress"></app-forminput>


          <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                           [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                           [readonly]="readonly"
                           [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

        </fieldset>
      </form>
    </div>
  </div>

  <!-- Button trigger modal -->
  <button [hidden]="true" id="opendeleteModal" type="button" data-toggle="modal" data-target="#deleteModal">
    Open Grid Modal
  </button>

  <!-- Modal -->
  <div class="modal fade" #deleteModal id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteModalLabel">{{sts.st.keycodes.delete}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-left">
          {{sts.st.keycodes.delete_question}}
          <br>
          {{needToDeleteRowData?.name}} - {{needToDeleteRowData?.device_name}} ({{needToDeleteRowData?.device}})
        </div>
        <div class="modal-footer">
          <button id="okdeleteModalHidden" (click)="deleteRow(needToDeleteRowData.cbid)" type="button" class="btn btn-danger" data-dismiss="modal">{{sts.st.keycodes.delete}}</button>
          <button id="closedeleteModalHidden" type="button" class="btn btn-secondary" data-dismiss="modal">{{sts.st.keycodes.cancel}}</button>
        </div>
      </div>
    </div>
  </div>

</div>



