<div class="container" *ngIf="formReadyToShow">
    <div class="col-md-12 my-4">
        <div class="card card-body bg-light">
            <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm"
                  autocomplete="off">
                <fieldset>

                    <app-formheader [formType]="formType" [formName]="formName" [id]="userId"
                                    [sts]="sts"></app-formheader>

                    <!-- Chrome autocomplete workaround -->
                    <input style="opacity: 0; height: 0" type="password" name="fakepasswordremembered"/>
                    <input style="opacity: 0; height: 0" type="email" name="loginemail"/>

                    <app-forminput [inputType]="'email'" [required]="true" [inputMinLength]="'3'"
                                   [control]="emailControl" [inputName]="'email'" [title]="'users.email'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions"
                                   [higherRoleIsNeeded]="higherRoleIsNeeded"
                                   [actionInProgress]="actionInProgress"></app-forminput>
                    <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="nameControl"
                                   [inputName]="'name'" [title]="'users.full_name'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions"
                                   [higherRoleIsNeeded]="higherRoleIsNeeded"
                                   [actionInProgress]="actionInProgress"></app-forminput>


                    <div formGroupName="passwordForm">
                        <app-forminput [inputType]="'password'" [required]="true" [inputMinLength]="'6'"
                                       [control]="passwordControl" [inputName]="'password'" [title]="'users.password'"
                                       [sts]="sts"
                                       [parentFormGroup]="passwordForm" [permissions]="permissions"
                                       [higherRoleIsNeeded]="higherRoleIsNeeded"
                                       [actionInProgress]="actionInProgress"></app-forminput>
                        <app-forminput [inputType]="'password'" [required]="true" [inputMinLength]="'6'"
                                       [control]="confirmationControl" [inputName]="'password_confirmation'"
                                       [title]="'users.password_confirmation'" [sts]="sts"
                                       [parentFormGroup]="passwordForm" [permissions]="permissions"
                                       [higherRoleIsNeeded]="higherRoleIsNeeded"
                                       [actionInProgress]="actionInProgress"></app-forminput>

                    </div>

                    <app-formmultiselect [itemList]="rolesItemList" [itemSettings]="rolesSettings"
                                         [title]="'roles.role'" [sts]="sts" [formControlNameString]="'roles'"
                                         [control]="roleControl"
                                         [parentFormGroup]="mainForm" [permanentDatas]="permanentDatas"
                                         [actionInProgress]="actionInProgress"></app-formmultiselect>

                    <app-formmultiselect [itemList]="organizationsItemList" [itemSettings]="organizationsSettings"
                                         [title]="'orgs.organization'"  [sts]="sts" [formControlNameString]="'organization'"
                                         [required]="true"
                                         [control]="organizationControl"
                                         [parentFormGroup]="mainForm"
                                         [actionInProgress]="actionInProgress"></app-formmultiselect>


                    <div *ngIf="clientBook && clientBook.length > 0" class="text-left m-3 mb-5">
                      <div><b>{{this.sts.c('users.client_books')}}</b></div>
                      <ul>
                          <li *ngFor="let book of clientBook; let i = index">
                            {{book.name}}  ({{book.created_at.replaceAll('-', '.').slice(0,-3)}})
                            <span  *ngIf="book.device_name && book.device"> | {{sts.c('users.user_device')}}: {{book.device_name}} ({{book.device}})</span>
                          </li>
                      </ul>
                    </div>
                    <!--
                    <div *ngIf="clientData" class="text-left m-3 mb-5">
                        <div><b>{{this.sts.c('users.additional_client_information')}}</b></div>
                        <div>{{this.sts.c('users.phonenumber')}}: {{clientData['phonenumber'] ? clientData['phonenumber'] : '-'}}</div>
                        <br/>
                        <div><b>{{this.sts.c('users.billinformations')}}</b></div>
                        <div>{{this.sts.c('users.billname')}}: {{clientData['billname'] ? clientData['billname'] : '-'}}</div>
                        <div>{{this.sts.c('users.billcountry')}}: {{clientData['billcountry'] ? clientData['billcountry'] : '-'}}</div>
                        <div>{{this.sts.c('users.billzip')}}: {{clientData['billzip'] ? clientData['billzip'] : '-'}}</div>
                        <div>{{this.sts.c('users.billcity')}}: {{clientData['billcity'] ? clientData['billcity'] : '-'}}</div>
                        <div>{{this.sts.c('users.billaddress')}}: {{clientData['billaddress'] ? clientData['billaddress'] : '-'}}</div>
                        <div>{{this.sts.c('users.billtaxnumber')}}: {{clientData['billtaxnumber'] ? clientData['billtaxnumber'] : '-'}}</div>
                        <div>{{this.sts.c('users.billbankaccountnumber')}}: {{clientData['billbankaccountnumber'] ? clientData['billbankaccountnumber'] : '-'}}</div>
                    </div>
                    -->

                    <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                                     [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                                     [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

                </fieldset>
            </form>
        </div>
    </div>
</div>
