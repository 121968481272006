<div class="container" *ngIf="formReadyToShow">
  <div class="col-md-12 my-4">
    <div class="card card-body bg-light">
      <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm" autocomplete="off">
        <fieldset>

          <app-formheader [formType]="formType" [formName]="formName" [id]="userId" [sts]="sts"></app-formheader>



          <app-formmultiselect [itemList]="sellersItemList" [itemSettings]="sellersSettings"
                               (itemSelect)="sellerSelect($event);"
                               (itemDeSelect)="sellerDeSelect($event);"
                               [title]="'shoppings.seller'"  [sts]="sts" [formControlNameString]="'seller'"
                               [required]="true"
                               [control]="sellerControl"
                               [parentFormGroup]="mainForm"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>

          <app-formmultiselect [itemList]="booksItemList" [itemSettings]="booksSettings" [disabled]="booksDisabled" [loading]="booksLoading"
                               [title]="'books.book'"  [sts]="sts" [formControlNameString]="'book'"
                               [required]="true"
                               [control]="bookControl"
                               [parentFormGroup]="mainForm"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>


          <app-formmultiselect [itemList]="buyersItemList" [itemSettings]="buyersSettings" [disabled]="buyersDisabled" [loading]="buyersLoading"
                               [title]="'shoppings.buyer'"  [sts]="sts" [formControlNameString]="'buyer'"
                               [required]="true"
                               [control]="buyerControl"
                               [parentFormGroup]="mainForm"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>




          <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                           [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                           [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

        </fieldset>
      </form>
    </div>
  </div>
</div>