import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { SystemtextService } from '../../services/systemtext.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/systemtext.service";
const adminMenu = of({ id: 1, name: 'Irányítópult', icon: 'fas fa-home', link: 'dashboard' }, { id: 2, name: 'Felhasználók', icon: 'fas fa-users', link: 'users' }, { id: 3, name: 'Rendelések', icon: 'fas fa-file-alt', link: 'orders' }, {
    id: 4, name: 'Riportok', icon: 'fas fa-chart-bar',
    child: [
        { id: 5, name: 'Könyvek', icon: 'K', link: 'books' },
        { id: 6, name: 'AR Objektumok', icon: 'AR', link: 'objects' }
    ]
}, { id: 7, name: 'Termékek', icon: 'fas fa-shopping-cart', link: 'products' });
export class NavbarService {
    constructor(http, sts) {
        this.http = http;
        this.sts = sts;
    }
    getNavbarLeft() {
        this.sts.st = JSON.parse(sessionStorage.getItem('basetexts')).menu[localStorage.getItem('lang')];
        return of([
            { name: this.sts.c('dashboard'), icon: 'fas fa-home', link: 'dashboard', permissions: ['Dashboard'], ignoreLoadingBar: true },
            { name: this.sts.c('system_events'), icon: 'fas fa-clipboard-list', link: 'systemevents/show', permissions: ['Users'], ignoreLoadingBar: true },
            { name: this.sts.c('system_message'), icon: 'far fa-comment-alt', link: 'systemmessage/edit', permissions: ['Books'], ignoreLoadingBar: true },
            { name: this.sts.c('books'), icon: 'fas fa-book', link: 'books/show', permissions: ['Books'] },
            { name: this.sts.c('keycodes_creation'), icon: 'far fa-file-code', link: 'keycodescreation/show', permissions: ['Books'] },
            { name: this.sts.c('keycodes'), icon: 'fas fa-key', link: 'keycodes/show', permissions: ['Books'] },
            {
                name: this.sts.c('manage_users'), icon: 'fas fa-users',
                permissions: ['Users', 'Roles'],
                child: [
                    { name: this.sts.c('users'), icon: this.sts.c('users_abbrev'), link: 'users/show', permissions: ['Users'], ignoreLoadingBar: true },
                    { name: this.sts.c('roles'), icon: this.sts.c('roles_abbrev'), link: 'roles/show', permissions: ['Roles'] },
                ]
            },
            { name: this.sts.c('organizations'), icon: 'fas fa-building', link: 'organizations/show', permissions: ['Organizations'] },
            { name: this.sts.c('marketplace'), icon: 'fas fa-shopping-bag', link: 'marketplace', permissions: ['Marketplace'] },
            { name: this.sts.c('shoppings'), icon: 'fas fa-shopping-basket', link: 'shoppings/show', permissions: ['Shoppings'] },
            { name: this.sts.c('language_check'), icon: 'fas fa-flag', link: 'languagecheck', permissions: ['Books'] },
            { name: this.sts.c('areditor'), icon: 'fas fa-cube', link: 'areditor', permissions: ['Areditor'], onlymaincontent: true },
        ]);
        // return this.http.get<Navbaritem>(url);
    }
}
NavbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavbarService_Factory() { return new NavbarService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SystemtextService)); }, token: NavbarService, providedIn: "root" });
