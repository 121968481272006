import * as i0 from "@angular/core";
export class FlagsService {
    constructor() {
    }
    getFlagsInfo() {
        const positions = {
            'hu': '-32px -66px',
            'en': '-176px -44px',
            'enus': '-144px -154px',
            'de': '-80px -33px',
        };
        const flags = {
            //style:"{'width': '16px', 'height': '11px', 'text-align':'center', 'margin':'0 auto'}",
            style: {
                'width': '16px',
                'height': '11px',
                'text-align': 'center',
                'margin': '0 auto',
                'background-image': 'url(assets/img/flags.png)'
            },
            styleInline: {
                'width': '16px',
                'height': '11px',
                'background-image': 'url(assets/img/flags.png)',
                'display': 'inline-flex',
                'margin-right': '3px'
            },
            styleInlineGrid: "width: 16px; height: 11px; background-image: url(assets/img/flags.png); display: inline-flex; margin-right: 3px;",
            bgposition: {
                'hu': positions.hu,
                'hu-hu': positions.hu,
                'en': positions.en,
                'en-us': positions.enus,
                'en-gb': positions.en,
                'de-de': positions.de,
            },
        };
        return flags;
    }
}
FlagsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FlagsService_Factory() { return new FlagsService(); }, token: FlagsService, providedIn: "root" });
