import {Injectable} from '@angular/core';
import {Observable, throwError, of} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Navbaritem} from '../admin/models/navbaritem';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    token: any = null;
    url: string;


    constructor(private http: HttpClient, public jwtHelper: JwtHelperService, private router: Router) {
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }

    register(user: Object): Observable<any> {
        return this.http.post(this.url + '/register', user).pipe(map((response) => response), catchError(this.errorHandler));
    }

    login(user: Object): Observable<any> {

        return this.http.post(this.url + '/login', user).pipe(map((response) => {

            const token: any = response['access_token'];

            // console.log('Response token:' + token);
            // console.log('Token expires_in:' + response['expires_in']);
            var expire_at = Date.now() + (response['expires_in'] * 1000); // 120 perc, backend-ből


            if (token) {
                this.token = token;
                sessionStorage.setItem('token', this.token);
                sessionStorage.setItem('token_expire_at', expire_at.toString());
                sessionStorage.setItem('api_url', this.url);
                sessionStorage.setItem('userdata', response['user_data']);
                sessionStorage.setItem('orgdata', response['org_data']);
                sessionStorage.setItem('userpermissions', response['user_permissions']);
                sessionStorage.setItem('userroles', response['user_roles']);
                sessionStorage.setItem('basetexts', response['base_texts']);


                return true;
            } else {
                return false;
            }
        }), catchError(this.errorHandler));
    }

    loggedIn() {
        //return sessionStorage.getItem('token');
        //return !this.jwtHelper.isTokenExpired(); // nem működik
        // var isTokenExpired = this.jwtHelper.isTokenExpired(); // működik ez, csak küldeni kell az expires_in-t
        const token_expire_at = sessionStorage.getItem('token_expire_at');
        const isTokenExpired = !token_expire_at || Number(Date.now()) > Number(token_expire_at);

        //console.log('Date.now():',Number(Date.now()));
        //console.log('token_expire_at:',Number(token_expire_at));

        if (isTokenExpired) {
            this.removeItemsFromSessionStorage();
        }
        return !isTokenExpired;
    }

    logout(): Observable<any> {
        return this.http.post(this.url + '/logout', {}).pipe(map((response) => {
            // console.log('Response:', response);
            this.token = null;
            this.removeItemsFromSessionStorage();
        }), catchError(this.errorHandler));

    }

    removeItemsFromSessionStorage() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token_expire_at');
        sessionStorage.removeItem('api_url');
        sessionStorage.removeItem('userdata');
        sessionStorage.removeItem('orgdata');
        sessionStorage.removeItem('userpermissions');
        sessionStorage.removeItem('basetexts');
        sessionStorage.removeItem('userroles');
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.error || {message: 'Server Error'});
    }


}
