<div class="parent-string">{{parentString}}</div>
<div class="elements">
  <div *ngFor="let property of propertyList ">
      <div [style.background-color]="backgrondColors[currentLevel]" class="level-div level-{{currentLevel}}">
        <!-- <div>Level - {{backgrondColors[currentLevel]}}</div> -->
        <div *ngIf="isString(myObject[property])">
         <div class="flag {{ property }}"></div>
        </div>
        <span *ngIf="isString(myObject[property])"  [ngClass]="myObject[property] == 'MISSING' ? 'missing' : ''">
          {{ myObject[property] }}
        </span>
        <displayobject *ngIf="isObject(myObject[property])" [myObject]="myObject[property]" [parentString]="property" [level]="currentLevel"></displayobject>
      </div>
  </div>
</div>
