import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Navbaritem} from '../models/navbaritem';
import {SystemtextService} from '../../services/systemtext.service';

const adminMenu = of(
    {id: 1, name: 'Irányítópult', icon: 'fas fa-home', link: 'dashboard'},
    {id: 2, name: 'Felhasználók', icon: 'fas fa-users', link: 'users'},
    {id: 3, name: 'Rendelések', icon: 'fas fa-file-alt', link: 'orders'},
    {
        id: 4, name: 'Riportok', icon: 'fas fa-chart-bar',
        child: [
            {id: 5, name: 'Könyvek', icon: 'K', link: 'books'},
            {id: 6, name: 'AR Objektumok', icon: 'AR', link: 'objects'}
        ]
    },
    {id: 7, name: 'Termékek', icon: 'fas fa-shopping-cart', link: 'products'}

);



@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    constructor(private http: HttpClient, private  sts: SystemtextService) {
    }

    getNavbarLeft(): Observable<Navbaritem[]> {

        this.sts.st = JSON.parse(sessionStorage.getItem('basetexts')).menu[localStorage.getItem('lang')];

        return of<Navbaritem[]>([

            {name: this.sts.c('dashboard'), icon: 'fas fa-home', link: 'dashboard', permissions: ['Dashboard'], ignoreLoadingBar:true},
            {name: this.sts.c('system_events'), icon: 'fas fa-clipboard-list', link: 'systemevents/show', permissions: ['Users'], ignoreLoadingBar:true},
            {name: this.sts.c('system_message'), icon: 'far fa-comment-alt', link: 'systemmessage/edit', permissions: ['Books'], ignoreLoadingBar:true},
            {name: this.sts.c('books'), icon: 'fas fa-book', link: 'books/show', permissions: ['Books']},
            {name: this.sts.c('keycodes_creation'), icon: 'far fa-file-code', link: 'keycodescreation/show', permissions: ['Books']},
            {name: this.sts.c('keycodes'), icon: 'fas fa-key', link: 'keycodes/show', permissions: ['Books']},
            {
                name: this.sts.c('manage_users'), icon: 'fas fa-users',
                permissions: ['Users', 'Roles'],
                child: [
                    {name: this.sts.c('users'), icon: this.sts.c('users_abbrev'), link: 'users/show', permissions: ['Users'], ignoreLoadingBar:true},
                    {name: this.sts.c('roles'), icon: this.sts.c('roles_abbrev'), link: 'roles/show', permissions: ['Roles']},
                ]
            },
            {name: this.sts.c('organizations'), icon: 'fas fa-building', link: 'organizations/show', permissions: ['Organizations']},
            {name: this.sts.c('marketplace'), icon: 'fas fa-shopping-bag', link: 'marketplace', permissions: ['Marketplace']},
            {name: this.sts.c('shoppings'), icon: 'fas fa-shopping-basket', link: 'shoppings/show', permissions: ['Shoppings']},
            {name: this.sts.c('language_check'), icon: 'fas fa-flag', link: 'languagecheck', permissions: ['Books']},
            {name: this.sts.c('areditor'), icon: 'fas fa-cube', link: 'areditor', permissions: ['Areditor'], onlymaincontent:true},


            /*
            {id: 5, name: 'Rendelések', icon: 'fas fa-file-alt', link: 'orders'},
            {
                id: 6, name: 'Riportok', icon: 'fas fa-chart-bar',
                child: [
                    {id: 7, name: 'Könyvek', icon: 'K', link: 'books'},
                    {id: 8, name: 'AR Objektumok', icon: 'AR', link: 'objects'}
                ]
            },
            {id: 9, name: 'Termékek', icon: 'fas fa-shopping-cart', link: 'products'},
            */
        ]);

        // return this.http.get<Navbaritem>(url);
    }
}
