import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {WebService} from './services/web.service';
import {SystemtextService} from './services/systemtext.service';
import {FlagsService} from './services/flags.service';

@Component({
    selector: 'app-web',
    templateUrl: './web.component.html',
    styleUrls: ['./web.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WebComponent implements OnInit {

    isDataLoaded = false;

    constructor(private ws: WebService, private sts: SystemtextService, private flagsService:FlagsService) {
    }

    ngOnInit() {

        if (!sessionStorage.getItem('webdatas')) {
            this.ws.getWebDatas().subscribe(items => {

                sessionStorage.setItem('webdatas', JSON.stringify(items.texts));
                this.sts.st = items.texts;
                this.isDataLoaded = true;

            }, error => {
                // console.log(error);
                var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                //this.messageService.sendMessage({text: text, type: 'danger'});
            });
        } else {
            this.sts.st = JSON.parse(sessionStorage.getItem('webdatas'));
            this.isDataLoaded = true;
        }

        if (!sessionStorage.getItem('flags')) {
            let flags = this.flagsService.getFlagsInfo();
            sessionStorage.setItem('flags', JSON.stringify(flags));
        }

    }

}
