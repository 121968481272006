import {NgModule} from '@angular/core';
import {CapitalizePipe, ArTypePipe} from './pipes';
import {NavlinkHasSameRootPipe} from './pipes';
@NgModule({
    imports: [],
    declarations: [CapitalizePipe, NavlinkHasSameRootPipe,ArTypePipe],
    exports: [CapitalizePipe, NavlinkHasSameRootPipe,ArTypePipe],
})

export class MainPipe {

    static forRoot() {
        return {
            ngModule: MainPipe,
            providers: [],
        };
    }
}