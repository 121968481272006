import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';


@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {


    constructor() {
    }

    ngOnInit() {

    }

    @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input()
    set showModal(showModal: boolean) {
        if (showModal) {
            this.show();
        } else {
            this.hide();
        }
    }

    @Input() hideFooter:boolean = false;

    public visible = false;
    public visibleAnimate = false;

    public show(): void {
        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    public hide(): void {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
        this.showModalChange.emit(false);
    }

    public onContainerClicked(event: MouseEvent): void {
        if ((<HTMLElement>event.target).classList.contains('modal')) {
            this.hide();
        }
    }

}
