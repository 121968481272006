import {Component, Input, OnInit, Directive, ElementRef, Renderer2, HostListener, EventEmitter, Output} from '@angular/core';
import {style, state, animate, transition, trigger} from '@angular/animations';
import {Navbaritem} from '../../models/navbaritem';
import {Routes, Router, ActivatedRoute} from '@angular/router';
import {NavbarService} from '../../services/navbar.service';
import {PermissionsService} from '../../services/permissions.service';

@Component({
    selector: 'app-navbarleft',
    templateUrl: './navbarleft.component.html',
    styleUrls: ['./navbarleft.component.scss']
})
export class NavbarleftComponent implements OnInit {

    permissions;
    closeingByClickTimeout: any;
    navbarKeyToRemove: any = [];
    navbarChildeKeyToRemove: any = [];

    @Input() NavbarLeftOpen: boolean;
    @Input() innerWidth: any;
    @Output() update = new EventEmitter<boolean>();

    navbaritems: Navbaritem[];

    constructor(private el: ElementRef, private renderer: Renderer2, private router: Router, private navbarService: NavbarService, private permissionsService: PermissionsService) {
    }


    log(val) {
        console.log(val);
    }

    ngOnInit() {

       
        
        
        this.navbarService.getNavbarLeft().subscribe(navbaritems => {
            this.navbaritems = navbaritems;

            // set open property after first page load
            var currentLink = this.router.url.replace('/admin/', '');
            for (let key in this.navbaritems) {

                var value = this.navbaritems[key];

                var open = false;
                if (value.child) {
                    for (let ckey in value.child) {
                        let cvalue = value.child[ckey];

                        // permissions
                        //this.showMenuByPermission(value.child, ckey);
                        if (cvalue.hasOwnProperty('permissions')) {
                            if (!this.permissionsService.getShowPermissions(cvalue.permissions)) {
                                this.navbarChildeKeyToRemove.push(ckey);
                            }
                        }

                        if (
                            cvalue.link &&
                            (cvalue.link == currentLink)
                            ||
                            (cvalue.link.split('/')[0] == currentLink.split('/')[0]) // same root
                        ) {
                            open = true;
                        }
                        value.open = open;
                    }
                }

                this.removeKeyFromNavbar(this.navbarChildeKeyToRemove,value.child);
                this.navbarChildeKeyToRemove = [];
                if ((value.child && value.child.length == 0) || (value.child == undefined && value.permissions != undefined && !this.permissionsService.getShowPermissions(value.permissions))) {
                    this.navbarKeyToRemove.push(key);
                }
            }


            this.removeKeyFromNavbar(this.navbarKeyToRemove,this.navbaritems);
            this.navbarKeyToRemove = [];


        });
    }

    removeKeyFromNavbar(array, value:any) {
        // remove childe items without permission
        array.sort(function (a, b) {
            return b - a;
        });
        array.forEach((key) => {
            if(value) value.splice(key, 1);
        });
    }


    /*
    showMenuByPermission(navbaritem, key) {
        if (navbaritem[key].hasOwnProperty('permissions')) {
            var hasShowPermission = false;
            navbaritem[key].permissions.forEach((permission) => {
                if (this.permissionsService.getShowPermissions(permission)) {
                    hasShowPermission = true;
                }
            });
            if (!hasShowPermission) {
                this.navbarChildeKeyToRemove.push(key);
            }
        }
    }
    */



    sidebarTransitionEnd(event: any) {
        // Remove closeing-by-click after transition and (+1500ms), if sidebar is closed because someone clicked on a menu
        if (this.el.nativeElement.querySelector('.sidebar').classList.contains('closeing-by-click')) {
            this.closeingByClickTimeout = setTimeout(() => {
                this.renderer.removeClass(this.el.nativeElement.querySelector('.sidebar'), 'closeing-by-click');
            }, 500);
        }
    }

    @HostListener('click', ['$event.target', '$event'])
    onClick(btn, e) {
        if (this.el.nativeElement.querySelector('.sidebar').classList.contains('closed') && !btn.closest('.nav-link').hasAttribute('data-toggle')) {

            // Add closeing-by-click class to prevent slidebar (with closed class) reopen while closeing
            if (!this.el.nativeElement.querySelector('.sidebar').classList.contains('closeing-by-click') && this.el.nativeElement.querySelector('.sidebar').classList.contains('absolute-open')) {
                clearTimeout(this.closeingByClickTimeout);
                this.renderer.addClass(this.el.nativeElement.querySelector('.sidebar'), 'closeing-by-click');
            }
            // Simple or mobile view, slidebar (with closed class), close absoulte-open panel after click.
            this.renderer.removeClass(this.el.nativeElement.querySelector('.sidebar'), 'absolute-open');
            // close collapse - not needed
            /*
            if(btn.closest(".nav-child.collapse")) {
                console.log("BTN:",btn.closest(".nav-child.collapse"));
                $(btn.closest(".nav-child.collapse")).collapse('hide');
            }
            */
        }
        if ((!this.el.nativeElement.querySelector('.sidebar').classList.contains('closed') && this.innerWidth <= 991.98) && !btn.closest('.nav-link').hasAttribute('data-toggle')) {
            // Mobile view, close opend panel after click.
            this.update.emit(false);
        }
    }

    @HostListener('mouseover') onMouseOver() {
        if (this.el.nativeElement.querySelector('.sidebar').classList.contains('closed') && !this.el.nativeElement.querySelector('.sidebar').classList.contains('closeing-by-click')) {
            // add absolute-open class to open the slidebar (with closed class)
            this.renderer.addClass(this.el.nativeElement.querySelector('.sidebar'), 'absolute-open');
        }
    }

    @HostListener('mouseleave', ['$event']) onMouseLeave(event: MouseEvent) {
        if (event.relatedTarget == null) return; // debug random mouseleave event
        if (this.el.nativeElement.querySelector('.sidebar').classList.contains('closed')) {
            // remove absolute-open class to close the slidebar (with closed class)
            this.renderer.removeClass(this.el.nativeElement.querySelector('.sidebar'), 'absolute-open');
        }
    }

    @HostListener('dragstart', ['$event']) function (event: MouseEvent) {
        event.preventDefault(); // prevent menu dragstart event
    }

}
