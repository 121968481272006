<div class="col-md-12 text-right ag-grid-actions">
  <div class="action-btns">
    <a *ngIf="permissions.Create && actionBtnsDisplay.Create" title="{{addBtnTitle}}" [routerLink]="['/admin/'+tableName+'/create']"
       [state]="{ ignoreLoadingBar: true}" class="btn btn-success btn-sm">
      <!--<i class="fas fa-user-plus"></i>-->
      <i class="fas fa-plus"></i>
    </a>
    <a *ngIf="permissions.Show && !permissions.Edit && actionBtnsDisplay.Show" title="{{showBtnTitle}}" (click)="actionUserBySelectedRows('show')"
       class="btn btn-primary btn-sm">
      <i class="far fa-eye"></i>
    </a>
    <a *ngIf="permissions.Show && permissions.Edit && actionBtnsDisplay.Edit" title="{{modifyBtnTitle}}" (click)="actionUserBySelectedRows('edit')"
       class="btn btn-primary btn-sm">
      <i class="fas fa-pencil-alt"></i>
    </a>
    <a *ngIf="permissions.Show && permissions.Edit && permissions.Create && actionBtnsDisplay.Create && actionBtnsDisplay.Edit" title="{{copyBtnTitle}}" (click)="actionUserBySelectedRows('copy')"
       class="btn btn-info btn-sm">
      <i class="fas fa-copy"></i>
    </a>
    <a *ngIf="permissions.Show && permissions.Edit && actionBtnsDisplay.Edit && tableName == 'books'" title="{{maintenanceBtnTitle}}" (click)="actionUserBySelectedRows('maintenance')"
       class="btn btn-maintenance btn-sm">
      <i class="fas fa-tools"></i>
    </a>
    <a *ngIf="permissions.Show && permissions.Edit && actionBtnsDisplay.Edit && tableName == 'books'" title="{{wegbglZipBtnTitle}}" (click)="actionUserBySelectedRows('webglzip')"
       class="btn btn-webgl-zip btn-sm">
      <i class="fas fa-cube"></i>
    </a>
    <a *ngIf="permissions.Delete && actionBtnsDisplay.Delete" title="{{deleteBtnTitle}}" (click)="actionUserBySelectedRows('delete')"
       class="btn btn-danger btn-sm">
      <i *ngIf="!deleteInProgress" class="fas fa-trash-alt"></i>
      <i *ngIf="deleteInProgress" class="fas fa-spinner rotating"></i>
    </a>
  </div>
</div>

