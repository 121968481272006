/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./displayobject.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./displayobject.component";
var styles_DisplayobjectComponent = [i0.styles];
var RenderType_DisplayobjectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplayobjectComponent, data: {} });
export { RenderType_DisplayobjectComponent as RenderType_DisplayobjectComponent };
function View_DisplayobjectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "flag ", _v.parent.context.$implicit, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_DisplayobjectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.myObject[_v.parent.context.$implicit] == "MISSING") ? "missing" : ""); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.myObject[_v.parent.context.$implicit]; _ck(_v, 3, 0, currVal_1); }); }
function View_DisplayobjectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "displayobject", [], null, null, null, View_DisplayobjectComponent_0, RenderType_DisplayobjectComponent)), i1.ɵdid(1, 638976, null, 0, i3.DisplayobjectComponent, [], { myObject: [0, "myObject"], parentString: [1, "parentString"], level: [2, "level"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myObject[_v.parent.context.$implicit]; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.currentLevel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DisplayobjectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[4, "background-color", null], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayobjectComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayobjectComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayobjectComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isString(_co.myObject[_v.context.$implicit]); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.isString(_co.myObject[_v.context.$implicit]); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.isObject(_co.myObject[_v.context.$implicit]); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgrondColors[_co.currentLevel]; var currVal_1 = i1.ɵinlineInterpolate(1, "level-div level-", _co.currentLevel, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_DisplayobjectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "parent-string"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "elements"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayobjectComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.propertyList; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentString; _ck(_v, 1, 0, currVal_0); }); }
export function View_DisplayobjectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "displayobject", [], null, null, null, View_DisplayobjectComponent_0, RenderType_DisplayobjectComponent)), i1.ɵdid(1, 638976, null, 0, i3.DisplayobjectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DisplayobjectComponentNgFactory = i1.ɵccf("displayobject", i3.DisplayobjectComponent, View_DisplayobjectComponent_Host_0, { myObject: "myObject", parentString: "parentString", level: "level" }, {}, []);
export { DisplayobjectComponentNgFactory as DisplayobjectComponentNgFactory };
