import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SystemtextService {

    public st: any;

    constructor() {
    }


    replace(text: string, params: any) {
        // console.log(' replace systemtext');
        var st = text.split('.');
        var stext = '';
        var check = this.c(text);

        if (check.indexOf('!!! MISSING SYSTEM TEXT') === 0) {
            stext = check;
        } else {
            stext = this.getValueFromObjectByObjectstring(text);

            params.forEach(param => {
                var check = this.c(param.replace);
                if (check.indexOf('!!! MISSING SYSTEM TEXT') === 0) {
                    stext = check;
                } else {
                    var paramReplaceText = this.getValueFromObjectByObjectstring(param.replace);
                    if (param.capitalize) param.replace = this.capitalizeFirstLetter(param.replace);
                    stext = stext.replace(param.find, paramReplaceText);
                }
            });
        }

        if (st.length === 1) {
            this.st[st[0]] = stext;
        } else {
            var lastDot = text.lastIndexOf('.');
            var substr = text.substring(0, lastDot);
            var lastval = text.substring(lastDot + 1);

            var obj = this.getValueFromObjectByObjectstring(substr);
            obj[lastval] = stext;
        }


    }

    getValueFromObjectByObjectstring(text) {
        var splitText = text.split('.');
        var st = this.st;
        splitText.forEach(v => {
            st = st[v];
        });
        return st;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * Check systemtext
     * @param otext
     */
    c(otext) {
        // console.log(' check systemtext', otext);
        var st = otext.split('.');
        var sto = this.st;
        var i = 1;
        var l = st.length;
        var missing = '';

        st.forEach(v => {
            if (missing) return;
            sto = sto[v];
            if (i != l) {
                if (typeof(sto) !== 'object') {
                    missing = '!!! MISSING SYSTEM TEXT OBJECT - ' + v + ' !!!';
                }
            } else {
                if (typeof(sto) !== 'string') {
                    missing = '!!! MISSING SYSTEM TEXT STRING - ' + v + ' !!!';
                }
            }
            i++;
        });
        return missing != '' ? missing : sto;
    }
}
