<div class="form-group text-left" [formGroup]="parentFormGroup">
  <label for="{{inputName}}" class="col-lg-12 control-label">
    {{title}} <app-tooltip *ngIf="toolTipOptions && toolTipOptions.text != ''" [toolTipOptions]="toolTipOptions"></app-tooltip>
    <span *ngIf="required === true">*</span>
  </label>
  <div class="col-lg-12" class="position-relative" style="padding-left: 15px;">
      <div *ngIf="actionInProgress || readonly" class="disable-all"></div>
     <div class="form-control color-picker-div"
           [style.background]="this.color"
           [cpPosition]="'bottom'"
           [cpPositionOffset]="'50%'"
           [cpPositionRelativeToArrow]="true"
           [(colorPicker)]="this.color"
          (colorPickerChange)="colorPickerChange()"

     >

            <div class="image-bg" [style.backgroundImage]="currentBgImage"></div>

     </div>
    <div class="color-picker-value">
      {{this.color}}
    </div>


    <input type="{{inputType}}" class="form-control" id="{{inputName}}" name="{{inputName}}"
           placeholder="{{title}}" formControlName="{{inputName}}"
           [readonly]="!permissions.Edit || higherRoleIsNeeded || actionInProgress || readonly"
           [value]="this.color"
           [style.background]="this.color"
           [(colorPicker)]="this.color"
    >




    <div *ngIf="control && control.dirty && control.hasError('required')"
         class="alert alert-danger">Field is required
    </div>
    <div *ngIf="control && control.hasError('validateFailed')">
      <div class="alert alert-danger" *ngFor="let message of control.errors.validateFailed">
        {{ message }}
      </div>
    </div>
  </div>
</div>
