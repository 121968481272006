import {Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        if (!value) return value;

        return value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

@Pipe({name: 'navlinkHasSameRoot'})
export class NavlinkHasSameRootPipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        var valueParts = value.split('/');
        var firstPart = valueParts[0]+"/";
        if (args.indexOf(firstPart) > -1) {
            return true;
        }
        return false;
    }
}

@Pipe({
    name: 'marketplaceFilter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, sts: any): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            // console.log('it', it);
            var hasResult = (
                it.name.toLowerCase().includes(searchText) ||
                it.ar_meta_string.toLowerCase().includes(searchText) ||
                // it.description.toLowerCase().includes(searchText) ||
                it.organization_name.toLowerCase().includes(searchText) ||
                it.organization_email.toLowerCase().includes(searchText) ||
                sts.c(it.language).toLowerCase().includes(searchText) ||
                sts.c(it.category).toLowerCase().includes(searchText)
            );

            return hasResult;
        });
    }
}

@Pipe({
    name: 'marketplaceSort'
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {

        if (!Array.isArray(array) || field == undefined) {
            return;
        }

        var isDesc = false;
        var isString = false;

        if (field.indexOf(':') > -1) {
            if (field.substring(field.indexOf(':') + 1) == 'desc') isDesc = true;

            field = field.substring(0, field.indexOf(':'));
        }

        // if filed is organization_name, order by name first
        if(field == 'organization_name' || field == 'language_text' || field == 'category_text') this.transform(array,'name');

        //console.log("field",field);
        //console.log("isDesc",isDesc);

        array.sort((a: any, b: any) => {
            if (a[field] == undefined || b[field] == undefined) return 0;
            if (typeof a[field] == 'string' && typeof(b[field]) == 'string') isString = true;

            var af = isString ? a[field].toLowerCase() : a[field];
            var bf = isString ? b[field].toLowerCase() : b[field];

            if(!isDesc) {
                return isString ? af.toString().localeCompare(bf) : this.getPosition(af,bf);
            } else {
                return isString ? bf.toString().localeCompare(af) : this.getPosition(bf,af);
            }
        });
        return array;
    }
    getPosition(a,b){
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
@Pipe({ name: 'artype' })
export class ArTypePipe implements PipeTransform {
    constructor() {}
    transform(array: any[], field: string) {
        return array.filter(a =>{
            if(a.ar_type == field){
                return a;
            }
            });
    }
}