import {Injectable} from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class BookarfilesService {

    lastUid: number;

    constructor(private fb: FormBuilder) {
    }

    public generateUid() {
        var uid = new Date().valueOf();
        if (uid != this.lastUid) {
            this.lastUid = uid;
            return uid;
        } else {
            return this.generateUid();
        }
    }

    public initBookAr() {
        return this.fb.group({
            type: 'ar',
            uid: this.generateUid(),
            title: [''],
            keywords: [''],
            arAndroid: [''],
            arIos: [''],
            arWebgl: [''],
            arTargetImage: [''],
        });
    }
    public initBookVideo() {
        return this.fb.group({
            type: 'video',
            uid: this.generateUid(),
            title: [''],
            keywords: [''],
            video: [''],
            videoTargetImage: [''],
        });
    }
}
