import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
    selector: 'app-formcolorpicker',
    templateUrl: './formcolorpicker.component.html',
    styleUrls: ['./formcolorpicker.component.scss']
})
export class FormcolorpickerComponent implements OnInit {

    @Input() parentFormGroup;
    @Input() permissions;
    @Input() control;
    @Input() inputName;
    @Input() title;
    @Input() inputType;
    @Input() inputMinLength;
    @Input() required;
    @Input() higherRoleIsNeeded;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() sts;
    @Input() toolTipOptions: any;

    private _bgImage: string;

    @Input() set bgImage(value: string) {
        this._bgImage = value;
        this.setCurrentBgImage(value);
    }
    get bgImage(): string {
        return this._bgImage;
    }

    color:string;
    currentBgImage:SafeStyle;

    constructor( private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.title = this.sts.c(this.title);
        let controlValue = this.parentFormGroup.controls[this.inputName].value;
        this.color = controlValue ? controlValue : "#ffffff";
        this.colorPickerChange();

        if(this.bgImage) {
            this.setCurrentBgImage(this.bgImage);
        }
    }
   setColor(){
    let controlValue = this.parentFormGroup.controls[this.inputName].value;
    this.color = controlValue ? controlValue : "#ffffff";
   }
    colorPickerChange() {
        this.parentFormGroup.controls[this.inputName].setValue(this.color);
    }

    setCurrentBgImage(url) {
        this.currentBgImage = this.sanitizer.bypassSecurityTrustStyle(url ? `url('${url}')` : 'none');
    }


}
