<div class="container">
  <div class="row">
    <div class="col-12 col-lg-7">
      <div class="header" [style.backgroundImage]="'url('+ bgUrl +')'">
        {{text}}
      </div>
      <div class="text-left"><a class="back" [routerLink]="'/home'">Vissza a főoldalra</a></div>
    </div>
    <div class="col-12 col-lg-5 text-right sz2020">
      <img style="max-width: 100%" src="assets/img/sz2020.png" >
    </div>
  </div>
</div>
