import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prototipus',
  templateUrl: './prototipus.component.html',
  styleUrls: ['./prototipus.component.scss']
})
export class PrototipusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
