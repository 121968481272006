import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-formtextarea',
  templateUrl: './formtextarea.component.html',
  styleUrls: ['./formtextarea.component.scss']
})
export class FormtextareaComponent implements OnInit {

  @Input() parentFormGroup;
  @Input() permissions;
  @Input() control;
  @Input() inputName;
  @Input() title;
  @Input() inputMinLength;
  @Input() inputMaxLength;
  @Input() required;
  @Input() higherRoleIsNeeded;
  @Input() actionInProgress;
  @Input() readonly;
  @Input() sts;
  @Input() toolTipOptions: any;

  constructor() { }

  ngOnInit() {
      this.title = this.sts.c(this.title);
  }

}
