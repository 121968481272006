<div class="container" *ngIf="formReadyToShow">
  <div class="col-md-12 my-4">
    <div class="card card-body bg-light">
      <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm"
            autocomplete="off">
        <fieldset>

          <app-formheader [formType]="formType" [formName]="formName" [id]="kcId"
                          [sts]="sts"></app-formheader>


          <app-formswitch *ngIf="!isUpdate" [control]="useBookKeyControl" [inputName]="'useBookKey'"
                          [title]="'keycodescreation.using_the_key_of_the_book'" [sts]="sts"
                          [parentFormGroup]="mainForm" [permissions]="permissions"
                          [higherRoleIsNeeded]="higherRoleIsNeeded"
                          [readonly]="readonly"
                          [actionInProgress]="actionInProgress">
          </app-formswitch>

          <div *ngIf="showUniqueKeyInputs">
            <app-forminput [inputType]="'number'" [required]="true" [inputMinLength]="'1'"  [inputMaxLength]="'5000'" [control]="quantityControl"
                           [inputName]="'quantity'" [title]="'keycodescreation.quantity'" [sts]="sts"
                           [parentFormGroup]="mainForm" [permissions]="permissions"
                           [higherRoleIsNeeded]="higherRoleIsNeeded"
                           [readonly]="isUpdate || readonly"
                           [actionInProgress]="actionInProgress"></app-forminput>



            <app-forminput [inputType]="'number'" [required]="true" [inputMinLength]="'1'" [control]="usesControl"
                           [inputName]="'uses'" [title]="'keycodescreation.uses'" [sts]="sts"
                           [parentFormGroup]="mainForm" [permissions]="permissions"
                           [higherRoleIsNeeded]="higherRoleIsNeeded"
                           [readonly]="isUpdate || readonly"
                           [actionInProgress]="actionInProgress"></app-forminput>
          </div>

          <app-formmultiselect [itemList]="booksItemList" [itemSettings]="booksSettings"
                               [title]="'books.book'" [sts]="sts" [formControlNameString]="'book'"
                               [required]="true"
                               [control]="bookControl"
                               [parentFormGroup]="mainForm"
                               [readonly]="isUpdate || readonly"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>



          <app-forminput [inputType]="'text'" [required]="true" [control]="qrurlControl"
                         [inputName]="'qrurl'" [title]="'keycodescreation.qrurl'" [sts]="sts"
                         [parentFormGroup]="mainForm"
                         [permissions]="permissions"
                         [higherRoleIsNeeded]="higherRoleIsNeeded"
                         [readonly]="isUpdate || readonly"
                         [actionInProgress]="actionInProgress">
          </app-forminput>

          <app-formswitch *ngIf="isUpdate" [control]="newStyleControl" [inputName]="'newStyle'"
                          [title]="'keycodescreation.change_the_qr_design'" [sts]="sts"
                          [parentFormGroup]="mainForm" [permissions]="permissions"
                          [higherRoleIsNeeded]="higherRoleIsNeeded"
                          [readonly]="readonly"
                          [actionInProgress]="actionInProgress">
          </app-formswitch>
          <div [class]="isUpdate ? 'qr-style' : ''" *ngIf="showStyleInputs">
            <div *ngIf="isUpdate" class="style-inputs-info">{{styleInputsInfo}}</div>

            <app-formmultiselect [itemList]="qrlogosItemList" [itemSettings]="qrlogosSettings"
                                 [title]="'keycodescreation.qrlogo'" [sts]="sts" [formControlNameString]="'qrlogo'"
                                 [required]="true"
                                 [control]="qrlogoControl"
                                 [parentFormGroup]="mainForm"
                                 [readonly]="readonly"
                                 (itemSelect)="qrLogoSelect($event);"
                                 (itemDeSelect)="qrLogoDeselect($event);"
                                 [actionInProgress]="actionInProgress"></app-formmultiselect>

            <div style="display:flex;flex-direction: row;">
              <div>
                <app-formcolorpicker
                [inputType]="'hidden'" [control]="fontColor"
                [inputName]="'fontColor'" [title]="'keycodescreation.font_color'"
                [sts]="sts"
                [parentFormGroup]="mainForm" [permissions]="permissions"
                [higherRoleIsNeeded]="higherRoleIsNeeded"
                [readonly]="readonly"
                [actionInProgress]="actionInProgress">
                </app-formcolorpicker>
                <app-formcolorpicker
                  [inputType]="'hidden'" [control]="firstColor"
                  [inputName]="'firstColor'" [title]="'keycodescreation.first_color'"
                  [sts]="sts"
                  [parentFormGroup]="mainForm" [permissions]="permissions"
                  [higherRoleIsNeeded]="higherRoleIsNeeded"
                  [readonly]="readonly"
                  [actionInProgress]="actionInProgress">
                </app-formcolorpicker>

                <app-formcolorpicker
                  [inputType]="'hidden'" [control]="secondColor"
                  [inputName]="'secondColor'" [title]="'keycodescreation.second_color'"
                  [sts]="sts"
                  [parentFormGroup]="mainForm" [permissions]="permissions"
                  [higherRoleIsNeeded]="higherRoleIsNeeded"
                  [readonly]="readonly"
                  [actionInProgress]="actionInProgress">
                </app-formcolorpicker>
              </div>
              <app-qr-code #qrCode [qrLogoId]="selectedQrLogo" [textColor]="fontColor.value" [firstColor]="firstColor.value"  [secondColor]="secondColor.value" [gradientType]="gradientControl.value" ></app-qr-code>
            </div>



            <app-formmultiselect [itemList]="gradientItemList" [itemSettings]="gradientSettings"
                                 [title]="'keycodescreation.gradient_title'" [sts]="sts" [formControlNameString]="'gradient'"
                                 [required]="true"
                                 [control]="gradientControl"
                                 [parentFormGroup]="mainForm"
                                 [readonly]="readonly"
                                 [actionInProgress]="actionInProgress"></app-formmultiselect>

            <app-forminput [inputType]="'number'" [required]="true" [inputMinLength]="'1'"  [inputMaxLength]="'2000'" [control]="sizeControl"
                           [inputName]="'size'" [title]="'keycodescreation.size'" [sts]="sts"
                           [parentFormGroup]="mainForm" [permissions]="permissions"
                           [higherRoleIsNeeded]="higherRoleIsNeeded"
                           [readonly]="readonly"
                           [actionInProgress]="actionInProgress"></app-forminput>
          </div>


          <app-formmultiselect [itemList]="statusItemList" [itemSettings]="statusSettings"
                               [title]="'keycodescreation.status'" [sts]="sts" [formControlNameString]="'status'"
                               [required]="true"
                               [control]="statusControl"
                               [parentFormGroup]="mainForm"
                               [readonly]="readonly"
                               [actionInProgress]="actionInProgress"></app-formmultiselect>

          <app-formtextarea [inputMinLength]="'3'" [control]="commentControl"
                            [inputName]="'comment'" [title]="'keycodescreation.comment'" [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"></app-formtextarea>

          <div *ngIf="showQuantityAlert">
            <div class="alert alert-warning" role="alert">
              {{showQuantityAlertText}}
            </div>
          </div>

          <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                           [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                           [readonly]="readonly"
                           [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

        </fieldset>
      </form>
    </div>
  </div>
</div>
