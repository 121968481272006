import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GradientType} from '../../models/GradientType'
import {AdmingridService} from "../../services/admingrid.service";
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  private startCol;
  private endCol;
  @Input() qrLogoId;
  public selectedLogoId;
  @Input('textColor') textColor ;
  @Input('firstColor') set firstColor(value:string){
    this.startCol = value;
    this.startColor = this.domSan.bypassSecurityTrustStyle('stop-color:'+value);
    if(!this.isGradient){
      this.endColor = this.startColor;
    }
  };
  @Input('secondColor') set secondColor(value:string){
    this.endCol = value;
    if(this.isGradient){
      this.endColor = this.domSan.bypassSecurityTrustStyle('stop-color:'+value);
    }else{
      this.endColor = this.startColor;
    }
  };
  @Input('gradientType') set gradientType(value:GradientType){
    this.gradientTypeString = value[0];
    this.ChangeGradientType();

  };
  x1:string = '0%';
  x2:string = '0%';
  y1:string = '100%';
  y2:string = '0%';
  startColor;
  endColor;
  linear = true;
  gradientTypeString:GradientType;
  gradient:any;
  gradientQR:any;
  private  isGradient:boolean;
  logo:any = this.domSan.bypassSecurityTrustHtml('<path d="M138.28,106.44a2.14,2.14,0,0,1-1.1-2.08,3.64,3.64,0,0,1,.94-2.36.51.51,0,0,0,.15-.7l-.08-.09a.63.63,0,0,0-.2-.13l-30.8-9a.55.55,0,0,0-.36,0l-15,7.38a.48.48,0,0,0-.29.44v5.59a.42.42,0,0,0,.16.35.43.43,0,0,0,.3.16.5.5,0,0,0,.5-.5h0V100.2l14.6-7.14,29.33,8.61-13.62,6.48a.53.53,0,0,0-.28.46v5.75a.51.51,0,0,0,.24.43.48.48,0,0,0,.49,0l14.92-7.51a.52.52,0,0,0,.29-.41.51.51,0,0,0-.21-.44Z" transform="translate(0.1 0)" fill="#fff"/><path d="M138.51,108.51,115,138h0L92,107.07l29.9,8.86a8.43,8.43,0,0,0,1,.2,4.56,4.56,0,0,0,1.19-.37Z" transform="translate(0.1 0)" fill="#fff"/>');
  constructor(private domSan:DomSanitizer,  private admingridService: AdmingridService) { }

  ngOnInit() {
    this.setLogo(this.qrLogoId)
  }
  setLogo(id) {
    this.selectedLogoId = id;
    this.admingridService.getQrLogoSvgPart(id).subscribe(item => {
      this.logo = this.domSan.bypassSecurityTrustHtml(item.logo);
    }, error => {
      console.log("ERROR: "+<any>error.message);
      alert("ERROR: "+<any>error.message);
    });
  }
  ChangeGradientType(){
    this.isGradient = true;
    switch (this.gradientTypeString.id) {
      case 1:
        this.linear = true;
        this.SetGradientDirection(0,0,0,0);
        this.endColor = this.startColor;
        this.isGradient = false;
        break;
      case 2:
        this.linear = true;
        this.SetGradientDirection(0,0,0,100);
        break;
      case 3:
        this.linear = true;
        this.SetGradientDirection(0,100,0,0);
        break;
      case 4:
        this.linear = true;
        this.SetGradientDirection(0,100,0,100);
      break;
      case 5:
        this.linear = true;
        this.SetGradientDirection(100,0,0,100);
      break;
      case 6:
        this.linear = false;
      break;
        default:
        break;
    }
    if(this.isGradient){
      this.startColor = this.domSan.bypassSecurityTrustStyle('stop-color:'+this.startCol);
      this.endColor = this.domSan.bypassSecurityTrustStyle('stop-color:'+this.endCol);
    }
    if(this.linear){
      this.gradient = 'url(#linear-gradient)';
      this.gradientQR = 'url(#linear-gradient)';
    }else{
      this.gradient = 'url(#radial-gradient)';
      this.gradientQR = 'url(#radial-gradient-qr)';

    }
  }
  SetGradientDirection(_x1:number,_x2:number,_y1:number,_y2:number){
    this.x1= _x1+'%';
    this.x2 = _x2+'%';
    this.y1 = _y1+'%';
    this.y2 = _y2+'%';
  }
}
