/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-qr-codes-zip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./download-qr-codes-zip.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "../../services/admingrid.service";
var styles_downloadQrCodesZipComponent = [i0.styles];
var RenderType_downloadQrCodesZipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_downloadQrCodesZipComponent, data: {} });
export { RenderType_downloadQrCodesZipComponent as RenderType_downloadQrCodesZipComponent };
export function View_downloadQrCodesZipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "download fas fa-file-download"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_downloadQrCodesZipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "download-qr-codes", [], null, null, null, View_downloadQrCodesZipComponent_0, RenderType_downloadQrCodesZipComponent)), i1.ɵdid(1, 114688, null, 0, i2.downloadQrCodesZipComponent, [i3.HttpClient, i4.Router, i5.AdmingridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var downloadQrCodesZipComponentNgFactory = i1.ɵccf("download-qr-codes", i2.downloadQrCodesZipComponent, View_downloadQrCodesZipComponent_Host_0, {}, {}, []);
export { downloadQrCodesZipComponentNgFactory as downloadQrCodesZipComponentNgFactory };
