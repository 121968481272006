import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminformComponent} from '../../controllers/adminform/adminform.component';
import {PermissionsService} from '../../services/permissions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdmingridService} from '../../services/admingrid.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../services/message.service';


@Component({
    selector: 'app-organizationform',
    templateUrl: './organizationform.component.html',
    styleUrls: ['./organizationform.component.scss']
})
export class OrganizationformComponent extends AdminformComponent implements OnInit, OnDestroy {


    id: any;
    params: any;

    qrlogosItemList = [];
    qrlogosSettings = {};

    fullNameControl: FormControl;
    nameControl: FormControl;
    emailControl: FormControl;
    qrurlControl: FormControl;
    descriptionControl: FormControl;
    publicControl: FormControl;
    qrlogoControl: FormControl;


    ngOnInit() {
        this.tableName = 'organizations';
        this.formName = 'organization';
        this.setPermissions(this.tableName);
        this.sts.st = {form: this.localeText};


        this.permissions = this.permissionsService.getPermissions(this.tableName);

        this.qrlogosSettings = {
          text: 'Select qrlogo',
          classes: 'qrlogos-list',
          enableSearchFilter: true,
          singleSelection: true,
          enableFilterSelectAll: false,
          disabled: !this.permissions.Edit
        };

        // Update
        this.params = this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];

            if (this.id) {

                this.admingridService.getItem(this.tableName, this.id).subscribe(data => {

                    //this.sts.st = data.st;
                    this.sts.st = Object.assign({}, this.sts.st, data.st);

                    this.replaceSystemTitle('orgs.organization');

                    this.mainForm.controls['fullname'].setValue(data['item']['fullname']);
                    this.mainForm.controls['name'].setValue(data['item']['name']);
                    this.mainForm.controls['email'].setValue(data['item']['email']);
                    this.mainForm.controls['qrurl'].setValue(data['item']['qrurl']);
                    this.mainForm.controls['description'].setValue(data['item']['description']);
                    this.mainForm.controls['id'].setValue(data['item']['id']);
                    this.mainForm.controls['public'].setValue(data['item']['public']);

                    this.createItemList(data.qrlogos, data.item, 'qrlogos', 'qrlogo');
                    this.replaceTexts();
                    this.formReadyToShow = true;

                }, error => {
                    console.log(<any>error);
                    var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                    this.messageService.sendMessage({text: text, type: 'danger'});
                });
            }

            this.fullNameControl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
            this.nameControl = this.fb.control('', [Validators.required, Validators.minLength(3)]);
            this.emailControl = this.fb.control('', [Validators.required, this.isValidEmail]);
            this.qrurlControl = this.fb.control('', [Validators.required]);
            this.descriptionControl = this.fb.control('');
            this.publicControl = this.fb.control('');
            this.qrlogoControl = this.fb.control('', [Validators.required]);

            this.mainForm = this.fb.group({
                id: '',
                fullname: this.fullNameControl,
                name: this.nameControl,
                email: this.emailControl,
                qrurl: this.qrurlControl,
                description: this.descriptionControl,
                public: this.publicControl,
                qrlogo: this.qrlogoControl,
            });

            // console.log('this.mainForm:', this.mainForm.controls);
        });

        // Create
        if (this.formType == 'create') {
            this.admingridService.createItem(this.tableName).subscribe(data => {
                // console.log('data:', data);
                //this.sts.st = data.st;
                this.sts.st = Object.assign({}, this.sts.st, data.st);
                this.replaceSystemTitle('orgs.organization');
                this.createItemList(data.qrlogos, false, 'qrlogos', 'qrlogos');
                this.replaceTexts();
                this.formReadyToShow = true;
            }, error => {
                console.log(error);
                var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                this.messageService.sendMessage({text: text, type: 'danger'});
            });
        }


    }

    replaceTexts() {

      this.qrlogosSettings['text'] = this.sts.c('orgs.qrlogo');
      this.qrlogosSettings['searchPlaceholderText'] = this.sts.c('form.search');

    }

    createItemList(items, selectedItems, listname, controlname) {
      var itemListArray = [];

      items.forEach((item) => {
        itemListArray.push({'id': item.id, 'itemName': item.name, 'image': item.image});
      });

      this[listname + 'ItemList'] = itemListArray;
      if (selectedItems) {
        const selectedItemListArray = [];
        if(controlname === "qrlogo") {
          items.forEach((item) => {
            if(item.id == selectedItems.qrlogoid ) {
              selectedItemListArray.push({'id': item.id, 'itemName': item.name, 'image': item.image});
            }
          });
        }
        this.mainForm.controls[controlname].setValue(selectedItemListArray);
      }
    }

    ngOnDestroy() {
        this.params.unsubscribe();
    }




}
