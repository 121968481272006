import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SystemtextService } from 'src/app/services/systemtext.service';
import { environment } from 'src/environments/environment';

export interface IFileObj {
    id: number;
    orig_filename: string;
    mime_type: string;
}

export function isIFileObject(object: any): object is IFileObj {
    const iFileObj = object as IFileObj;
    return iFileObj != null;
}

@Component({
    selector: 'app-formsinglefileupload',
    templateUrl: './formsinglefile.component.html',
    styleUrls: ['./formsinglefile.component.scss']
})
export class FormsinglefileComponent implements OnInit {

    protected readonly thumbnailImageFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg'];
    protected readonly thumbnailVideoFileTypes = ['video/mp4'];
    private isVideo :boolean;
    // TODO: Szükség szerinti további fájltípusok hozzáadása, pl. image/* a thumbnailFileTypes-ban fel nem sorolt képek esetére.
    protected readonly thumbnailIconsSvg = {
        // General image type icon.
        // 'image/*': `<svg> ... </svg>`,

        // PDF icon
        'application/pdf':
            `<?xml version="1.0" standalone="no"?>
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg stroke-width="0.501" stroke-linejoin="bevel" fill-rule="evenodd" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" overflow="visible" width="47.906pt" height="47.906pt" viewBox="0 0 47.906 47.906">
             <defs>
                </defs>
             <g id="Document" fill="none" stroke="black" font-family="Times New Roman" font-size="16" transform="scale(1 -1)">
              <g id="Spread" transform="translate(0 -47.906)">
               <g id="Layer 1">
                <path d="M 9.361,3.432 L 9.361,44.381 L 25.627,44.381 L 38.64,31.368 L 38.64,3.432 L 9.361,3.432 Z" stroke-width="1" stroke="#333333" stroke-linecap="round" fill="#cccccc" marker-start="none" marker-end="none" stroke-miterlimit="79.8403193612775"/>
                <path d="M 9.361,3.432 L 9.361,44.381 L 25.627,44.381 L 25.627,31.368 L 38.64,31.368 L 38.64,3.432 L 9.361,3.432 Z" stroke-width="1" stroke="#333333" stroke-linecap="round" fill="#ffffff" marker-start="none" marker-end="none" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,37.125 L 22.696,37.125" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,31.875 L 22.696,31.875" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,26.625 L 35.04,26.625" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,21.375 L 35.04,21.375" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,16.125 L 35.04,16.125" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
                <path d="M 12.375,10.875 L 35.04,10.875" fill="none" stroke="#b2b2b2" stroke-width="2.25" stroke-linejoin="round" stroke-miterlimit="79.8403193612775"/>
               </g>
              </g>
             </g>
            </svg>`
    }

    @Output() destroyRequest = new EventEmitter();
    @Output() setThumbnailIconByMimeTypeRequest = new EventEmitter();

    protected _downloadUrl: string;
    @Input() set url(value: string) {
        this._downloadUrl = value;
        this._file = null;
        let lastSlashIndex = value ? value.lastIndexOf('/') : -1;
        this.filename = lastSlashIndex < 0 ? value : value.substr(lastSlashIndex + 1);
        this.updateThumbnailFromUrl();
    }
    get url() { return this._downloadUrl; }

    protected _fileObj: IFileObj;
    @Input() set fileObj(value: IFileObj) {
        this._fileObj = value;
        this._file = null;
        this._downloadUrl = `${environment.backendApiUrl}${environment.backendApiVersion}/files/getbyid/${this.fileObj.id}`;
        this.filename = value ? value.orig_filename : null;
        this.updateThumbnailFromIFileObj();
    }

    get fileObj():IFileObj {
        return this._fileObj;
    }

    protected _file: File;
    @Input() set file(value: File) {
        this._file = value;
        this._downloadUrl = null;
        this.filename = this.file ? this.file.name : null;
        this.updateThumbnailFromFile();
    }
    get file(): File { return this._file; }
    protected filename:string;

    @Input() readonly: boolean;

    protected thumbnailClass:string[] = ['thumbnail'];
    protected thumbnailBgImageStyle:SafeStyle;
    protected destroyButtonTitle:string;
    protected defaultDestroyButtonTitle:string;
    protected fileData: string;

    constructor(private sts: SystemtextService, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.defaultDestroyButtonTitle = this.sts.c('form.remove');
    }

    getAsBinaryString(fn: (data: string) => void) {
        if(!this.file) fn(null);
        if(this.fileData) fn(this.fileData);

        const reader = new FileReader();

        reader.onloadend = () => {
            if(reader.error)
                fn(null);
            else
                this.fileData = reader.result as string;
                fn(this.fileData);
        }

        reader.readAsBinaryString(this.file);
    }

    /**
     * Updates the thumbnail image for the current file.
     */
    protected updateThumbnailFromFile() {

        // Check if we have a file.
        if(!this.file) {
            this.thumbnailBgImageStyle = 'none';
            this.removeClass('icon');
            return;
        }

        // Get mime type.
        let type = this.file.type;


        // Set thumbnail image for image types.
        if(this.thumbnailImageFileTypes.includes(type)) {
            this.removeClass('icon');
            this.createThumbnailDataUrlFromFile();
            return;
        }
        // Set thumbnail image for video types.
        if(this.thumbnailVideoFileTypes.includes(type)) {
          this.isVideo = true;
          this.removeClass('icon');
          this.createThumbnailDataUrlFromVideoFile();
          return;
        }
        // Add a file icon.
        this.setThumbnailIconByMimeType(type);
    }
    /**
     * Creates an image preview thumbnail for the current video file.
     */
    protected createThumbnailDataUrlFromVideoFile() {
      const reader = new FileReader();
      reader.onloadend = () => {
          let url = reader.result as string;
          this._downloadUrl = url;
          this.removeClass('icon');
      };
      reader.readAsDataURL(this.file);
    }
    /**
     * Creates an image preview thumbnail for the current file.
     */
    protected createThumbnailDataUrlFromFile() {
        const reader = new FileReader();
        reader.onloadend = () => {
            let url = reader.result as string;
            this.thumbnailBgImageStyle = this.sanitizer.bypassSecurityTrustStyle(url ? `url('${url}')` : 'none');
            this.removeClass('icon');
            this.setThumbnailIconByMimeTypeRequest.emit(url);
        };
        reader.readAsDataURL(this.file);
    }

    protected updateThumbnailFromIFileObj() {
        // Check if we have a file.
        if(!this.fileObj) {
            this.thumbnailBgImageStyle = 'none';
            this.removeClass('icon');
            return;
        }

        // Get mime type.
        let type = this.fileObj.mime_type;

        // Set thumbnail image for image types.
        if(this.thumbnailImageFileTypes.includes(type)) {
            this.removeClass('icon');
            this.thumbnailBgImageStyle = this.url ? this.sanitizer.bypassSecurityTrustStyle(`url('${this._downloadUrl}')`) : 'none';
            return;
        }
        if(this.thumbnailVideoFileTypes.includes(type)) {
          this.isVideo = true;
          this.removeClass('icon');
          return;
        }
        // Add a file icon.
        this.setThumbnailIconByMimeType(type);
    }

    protected setThumbnailIconByMimeType(type: string) {
        this.addClass('icon');

        // Check if we have a specific icon for the file type.
        let generalType = type.split('/')[0];
        let thumbnailSvg = this.thumbnailIconsSvg[`${generalType}/*`] || this.thumbnailIconsSvg[type];
        if(!thumbnailSvg)  return;

        // Set the file type specific icon.
        var svgBase64 = btoa(thumbnailSvg);
        this.thumbnailBgImageStyle = this.sanitizer.bypassSecurityTrustStyle(`url('data:image/svg+xml;base64,${svgBase64}')`);
    }

    protected updateThumbnailFromUrl() {
        this.removeClass('icon');
        this.thumbnailBgImageStyle = this.url ? this.sanitizer.bypassSecurityTrustStyle(`url('${this.url}')`) : 'none';
    }

    protected onRemoveButtonClick(event:MouseEvent) {
        // console.log('onDestroy', event);

        this.destroyRequest.emit(this);
    }

    protected addClass(className: string) {
        var classes =
            className.split(' ')
            .filter(className => !this.thumbnailClass.includes(className));

        if(classes.length)
            this.thumbnailClass.push.apply(this.thumbnailClass, classes);
    }

    protected removeClass(className: string) {
        var classes = className.split(' ');

        if(classes.length)
            this.thumbnailClass = this.thumbnailClass.filter(className => !classes.includes(className));
    }

}
