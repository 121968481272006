<div class="container" *ngIf="formReadyToShow">
    <div class="col-md-12 my-4">
        <div class="card card-body bg-light">
            <form novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm">
                <fieldset>

                    <app-formheader [formType]="formType" [formName]="formName" [id]="roleId" [sts]="sts"></app-formheader>


                    <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="nameControl"
                                   [inputName]="'name'" [title]="'roles.name'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions" [actionInProgress]="actionInProgress"></app-forminput>


                    <div class="form-group">

                        <table class="permissions-table">
                            <thead>
                                <tr>
                                    <td class="menu-name"></td>
                                    <td><i class="far fa-eye"></i>{{this.sts.c('roles.show')}}</td>
                                    <td><i class="fas fa-plus"></i> {{this.sts.c('roles.create')}}</td>
                                    <td><i class="fas fa-pencil-alt"></i> {{this.sts.c('roles.edit')}}</td>
                                    <td><i class="fas fa-trash-alt"></i> {{this.sts.c('roles.delete')}}</td>
                                    <!--
                                    <td><i class="fas fa-file-alt"></i> Docgen</td>
                                    <td><i class="fas fa-file-import"></i> Import</td>
                                    <td><i class="fas fa-file-export"></i> Export</td>
                                    -->
                                </tr>
                            </thead>


                            <ng-container *ngFor="let item of rolePermissionsArray.controls | keyvalue; let i=index">
                                <tr class="menu-name-2-row" [ngClass]="(i % 2) == 0 ? 'gray-row' : ''">
                                    <td colspan="7" >
                                        <!-- <div class="menu-name-2">{{item.key}}</div> -->
                                        <div class="menu-name-2">{{this.menunames[item.key]}}</div>
                                    </td>
                                </tr>
                                <tr class="menu-permissions" [ngClass]="(i % 2) == 0 ? 'gray-row' : ''">
                                    <td class="menu-name">{{this.menunames[item.key]}}</td>
                                    <td>

                                        <div *ngIf="item.value.controls.Show">
                                            <i class="far fa-eye" title="Show"></i>
                                            <p class="title">Show</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Show"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.value.controls.Create">
                                            <i class="fas fa-plus" title="Create"></i>
                                            <p class="title">Create</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Create"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.value.controls.Edit">
                                            <i class="fas fa-pencil-alt" title="Edit"></i>
                                            <p class="title">Edit</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Edit"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.value.controls.Delete">
                                            <i class="fas fa-trash-alt" title="Delete"></i>
                                            <p class="title">Delete</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Delete"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <!--
                                    <td>
                                        <div *ngIf="item.value.controls.Docgen">
                                            <i class="fas fa-file-alt" title="Docgen"></i>
                                            <p class="title">Docgen</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Docgen"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.value.controls.Import">
                                            <i class="fas fa-file-import" title="Import"></i>
                                            <p class="title">Import</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Import"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="item.value.controls.Export">
                                            <i class="fas fa-file-export" title="Export"></i>
                                            <p class="title">Export</p>
                                            <input type="checkbox" class="form-control checkbox-element"
                                                   [formControl]="item.value.controls.Export"
                                                   [attr.disabled]="actionInProgress || (permissions.Show && !permissions.Edit) ? 'disabled' : null"/>
                                        </div>
                                    </td>
                                    -->
                                </tr>
                            </ng-container>

                        </table>
                    </div>

                    <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                                     [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                                     [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

                </fieldset>
            </form>
        </div>
    </div>
</div>
