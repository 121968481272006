import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MessageService} from "../../../services/message.service";

@Component({
  selector: 'app-gridmodal',
  templateUrl: './gridmodal.component.html',
  styleUrls: ['./gridmodal.component.scss']
})
export class GridmodalComponent implements OnInit {

  @Input() girdModalOptions: any;
  @Input() sts: any;
  @Output() gridModalAction = new EventEmitter<any>();
  @Output() passwordConfirmation = new EventEmitter<any>();

  passwordText: string;
  passwordErrorEmpty: string;
  passwordErrorBad: string;

  constructor(private messageService: MessageService) {
    this.messageService.getStatus().subscribe(
      message => {
        if (message) {
          if (message.name == 'grid' && message.status == 'ready') {
            this.passwordText = this.sts.c('grid.modal_password');
            this.passwordErrorEmpty = this.sts.c('grid.modal_password_error_empty');
            this.passwordErrorBad = this.sts.c('grid.modal_password_error_bad');
          }
        }
      }
    );
  }

  ngOnInit() {
  }

  gridModalActionEmit(params: object) {
    this.gridModalAction.emit(params);
  }
  onPasswordConfirmationChange(value: string): void {
    this.passwordConfirmation.emit(value);
  }

}
