import {Component, OnInit, Input, Pipe, PipeTransform} from '@angular/core';
import {Navbaritem} from '../../models/navbaritem';
import {Router} from '@angular/router';

@Component({
    selector: 'app-navbaritem',
    templateUrl: './navbaritem.component.html',
    styleUrls: ['./navbaritem.component.scss']
})
export class NavbaritemComponent implements OnInit {

    @Input() navbaritem: Navbaritem;
    showClass: string;
    ariaExpanded: string = 'false';
    public currentUrl: string = '';

    constructor(private router: Router) {
        router.events.subscribe((val) => {

            if (val.hasOwnProperty('url')) {
                this.currentUrl = val['url'];
            }

        });
    }

    ngOnInit() {
        this.currentUrl = this.router.url;

        if (this.navbaritem.open) {
            this.showClass = 'show';
            this.ariaExpanded = 'true';
        }
    }

    log(val, text) {
        console.log(text + ': ', val);
    }


}
