import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthGuardService {
    constructor(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    canActivate() {
        if (!this.auth.loggedIn()) {
            this.router.navigate(['/login']);
            return false;
        }
        /*
        let userroles =  JSON.parse(sessionStorage.getItem('userroles'));
        if ( userroles == 'Client') {
            this.router.navigate(['/']);
            return false;
        }
        */
        return true;
    }
}
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
