import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-forminput',
    templateUrl: './forminput.component.html',
    styleUrls: ['./forminput.component.scss']
})
export class ForminputComponent implements OnInit {

    @Input() parentFormGroup;
    @Input() permissions;
    @Input() control;
    @Input() inputName;
    @Input() title;
    @Input() inputType;
    @Input() inputMinLength;
    @Input() inputMaxLength;
    @Input() required;
    @Input() higherRoleIsNeeded;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() sts;
    @Input() toolTipOptions: any;

    // ngx-mask options
    @Input() mask;
    @Input() prefix;
    @Input() suffix;
    @Input() showMaskTyped;
    @Input() prefixOutside;
    @Input() prefixOutsideWidth;
    @Input() prefixOutsideInputStyle;


    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {

        this.mask = this.mask ? this.mask : false;
        this.prefix = this.prefix ? this.prefix : '';
        this.suffix = this.suffix ? this.suffix : '';
        this.showMaskTyped = this.showMaskTyped === false ? false : true;

        this.prefixOutside = this.prefixOutside ? this.prefixOutside : false;
        this.prefixOutsideInputStyle = '';
        this.prefixOutsideWidth = 0;
        if (this.prefixOutside) {
            this.prefixOutsideWidth = this.prefixOutside.length * 9;
            this.prefixOutsideInputStyle = this.sanitizer.bypassSecurityTrustStyle("calc(100% - "+this.prefixOutsideWidth+"px)");
        }

        this.title = this.sts.c(this.title);
    }

}
