<div class="p-2">
  <input #fileInput type="file" accept="{{acceptType}}" [multiple]="multiple" (change)="change($event)" style="visibility: hidden; display:none" [disabled]="disabled">
  <div class="d-flex ">
    <input class="form-control" [(ngModel)]="filename" [ngModelOptions]="{standalone: true}" type="text" placeholder="{{placeholder}}" [disabled]="disabled"
      (click)="selectFile()" readonly="true">
      <button class="btn btn-danger" *ngIf="filename" (click)="delFile()" [disabled]="disabled">
        Törlés
      </button>
      <button class="btn btn-primary flex-shrink-0" *ngIf="!filename" (click)="selectFile()" [disabled]="disabled">
        {{placeholder}}
      </button>
  </div>
</div>
