import {Component, OnInit} from '@angular/core';
import {Userroles} from '../../../models/userroles';
import {Observable} from 'rxjs';
import {AdmingridService} from '../../services/admingrid.service';
import {PermissionsService} from '../../services/permissions.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AdmingridComponent} from '../../controllers/admingrid/admingrid.component';
import {MessageService} from '../../services/message.service';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from '../../../models/user';

@Component({
    selector: 'app-roles',
    templateUrl: '../grid.base.template.html',
    styleUrls: ['../grid.base.template.scss']
})
export class RolesComponent extends AdmingridComponent implements OnInit{

    // users: Userroles[];
    firstLoadDone:boolean = false; // TODO: Ha nincs a colDef-ek előtt valamilyen változó, akkor nem tölti be a lapot. De ez csak ennél a componentnél van :/

    colDef_id = {headerName: 'Id', field: 'id', colId: 'r.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter'};
    colDef_name = {headerName: '', field: 'name', colId: 'r.name', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter'};

    columnDefs = [this.colDef_id, this.colDef_name];



    ngOnInit() {
        super.ngOnInit();

        this.tableName = 'roles';
        this.formName = 'Role';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);

        this.firstLoadDone = false;


        this.messageService.getStatus().subscribe(
            message => {
                if (message) {
                    if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {


                        this.sts.replace('grid.add_btn', [{'find': ':item', 'replace': 'roles.role'}]);
                        this.sts.replace('grid.show_btn', [{'find': ':item', 'replace': 'roles.role'}]);
                        this.sts.replace('grid.modify_btn', [{'find': ':item', 'replace': 'roles.role'}]);
                        this.sts.replace('grid.copy_btn', [{'find': ':item', 'replace': 'roles.role'}]);
                        this.sts.replace('grid.delete_btn', [{'find': ':item', 'replace': 'roles.role'}]);

                        this.setColNameByLang(this.colDef_name, 'roles.name', message.gridOptions);

                        message.gridOptions.api.refreshHeader();

                        this.onColumnsChanged();

                        this.firstLoadDone = true;


                    }
                }
            }
        );
    }

}
