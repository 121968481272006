import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {FormBuilder, Validators, FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {AdmingridService} from '../../admin/services/admingrid.service';
import {Router} from '@angular/router';
import {SystemtextService} from '../../services/systemtext.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @Input() isModal: boolean;
    @Input() cancelIsLogout: boolean = false;
    @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    userForm: FormGroup;
    passwordControl: FormControl;
    loginemailControl: FormControl;
    token: string;
    status: string;
    message: string;
    cancelText: string;

    wt: any;

    public actionInProgress: boolean = false;

    constructor(fb: FormBuilder, private authService: AuthService, private router: Router, private adminGridService: AdmingridService, private sts: SystemtextService) {
        this.passwordControl = fb.control('', [Validators.required]);
        this.loginemailControl = fb.control('', [Validators.required, LoginComponent.isValidEmail]);
        this.userForm = fb.group({loginemail: this.loginemailControl, password: this.passwordControl});
    }

    static isValidEmail(control: FormControl) {
        let email_regexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        return email_regexp.test(control.value) ? null : {invalidEmail: true};
    }

    ngOnInit() {
        // console.log('login ng on init');
        if (this.sts.st[localStorage.getItem('lang')] == undefined || this.sts.st[localStorage.getItem('lang')].lang == undefined) {
            var webdatas = sessionStorage.getItem('webdatas');
            this.sts.st = Object.assign({}, this.sts.st, JSON.parse(webdatas));
            this.wt = this.sts.st[localStorage.getItem('lang')].login;
        } else {
            this.wt = this.sts.st[localStorage.getItem('lang')].login;
        }

        this.cancelText = (this.cancelIsLogout != undefined && this.cancelIsLogout === true) ? this.wt.logout : this.wt.cancel;

    }

    login() {
        this.actionInProgress = true;
        this.userForm.value['email'] = this.userForm.value['loginemail'];
        this.userForm.value['lang'] = localStorage.getItem('lang');
        this.authService.login(this.userForm.value).subscribe(response => {
            // console.log(response);

            this.actionInProgress = false;
            if (response === true) {
                if (this.isModal != undefined && this.isModal === true) {
                    // console.log('LOGIN IS MODAL!');
                    // console.log('Last Action:', this.adminGridService.lastAction);
                    this.hideModal.emit(true);

                    var dontReload = ['addItem', 'updateItem'];
                    if (dontReload.indexOf(this.adminGridService.lastAction) === -1) {
                        // reload after login
                        var currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                            this.router.navigate([currentUrl])
                        );
                    }


                } else {
                    this.router.navigate(['/admin']);
                }
            } else {
                this.showErrorMessage('Username or password is incorrect');
            }
        }, error => {
            console.log(<any>error);
            this.showErrorMessage(error['message']);
            sessionStorage.clear();
        });
    }

    showErrorMessage(message: string) {
        this.actionInProgress = false;
        this.status = 'error';
        this.message = message;
    }

}
