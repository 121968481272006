import {Component, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AdmingridService} from "../../services/admingrid.service";


@Component({
  selector: 'download-qr-codes',
  template: `
  <div class="text-center">
    <i class="download fas fa-file-download" (click)="download()" aria-hidden="true"></i>
  </div>`,
  styleUrls: ['./download-qr-codes-zip.component.scss']
})
export class downloadQrCodesZipComponent implements OnInit {
  data: any;
  params: any;
  constructor(private http: HttpClient, private router: Router, private admingridService: AdmingridService) {}

  agInit(params) {
    this.params = params;
    this.data = params.value;
  }

  ngOnInit() {}

  download() {
    let rowData = this.params;
    // let i = rowData.rowIndex;
    const id = rowData.value;

    this.admingridService.getQrCodesZip(id).subscribe(item => {
      // console.log("item",item);

      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(item)
      a.href = objectUrl
      a.download = id+'-qr-codes.zip';
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.getElementById("closeGridModalHidden").click();

    }, error => {
      console.log("ERROR: "+<any>error.message);
      alert("ERROR: "+<any>error.message);
    });

  }

}
