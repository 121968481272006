import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

    @Input() toolTipOptions: any;

    constructor() {
    }

    ngOnInit() {
    }

    mouseEnter() {
        // debug tooltip slipping
        window.scroll(0, window.pageYOffset-1);
        window.scroll(0, window.pageYOffset+1);
    }

}
