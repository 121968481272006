/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formswitch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../tooltip/tooltip.component.ngfactory";
import * as i3 from "../../tooltip/tooltip.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../../node_modules/ngx-ui-switch/index.ngfactory";
import * as i7 from "ngx-ui-switch";
import * as i8 from "./formswitch.component";
var styles_FormswitchComponent = [i0.styles];
var RenderType_FormswitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormswitchComponent, data: {} });
export { RenderType_FormswitchComponent as RenderType_FormswitchComponent };
function View_FormswitchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, i2.View_TooltipComponent_0, i2.RenderType_TooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.TooltipComponent, [], { toolTipOptions: [0, "toolTipOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toolTipOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FormswitchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"]))], null, null); }
function View_FormswitchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "label", [["class", "control-label title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormswitchComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormswitchComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.toolTipOptions && (_co.toolTipOptions.text != "")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.required === true); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormswitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "form-group text-left"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "col-lg-12"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ui-switch", [["switchColor", "#fcfcfc"]], null, [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onToggle($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.setValue() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_UiSwitchComponent_0, i6.RenderType_UiSwitchComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.UiSwitchComponent]), i1.ɵdid(9, 180224, null, 0, i7.UiSwitchComponent, [[2, i7.ɵa], i1.ChangeDetectorRef], { switchColor: [0, "switchColor"], checked: [1, "checked"], disabled: [2, "disabled"] }, { change: "change" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormswitchComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["style", "clear: both"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.parentFormGroup; _ck(_v, 1, 0, currVal_7); var currVal_8 = "col-lg-12"; var currVal_9 = ((_co.actionInProgress || _co.readonly) ? "disabled" : null); _ck(_v, 6, 0, currVal_8, currVal_9); var currVal_10 = "#fcfcfc"; var currVal_11 = _co.switchValue; var currVal_12 = (((!_co.permissions.Edit || _co.higherRoleIsNeeded) || _co.actionInProgress) || _co.readonly); _ck(_v, 9, 0, currVal_10, currVal_11, currVal_12); var currVal_13 = _co.title; _ck(_v, 11, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormswitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formswitch", [], null, null, null, View_FormswitchComponent_0, RenderType_FormswitchComponent)), i1.ɵdid(1, 114688, null, 0, i8.FormswitchComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormswitchComponentNgFactory = i1.ɵccf("app-formswitch", i8.FormswitchComponent, View_FormswitchComponent_Host_0, { parentFormGroup: "parentFormGroup", control: "control", permissions: "permissions", inputName: "inputName", title: "title", higherRoleIsNeeded: "higherRoleIsNeeded", actionInProgress: "actionInProgress", readonly: "readonly", sts: "sts", required: "required", toolTipOptions: "toolTipOptions", size: "size" }, {}, []);
export { FormswitchComponentNgFactory as FormswitchComponentNgFactory };
