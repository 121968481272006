<div class="container">
    <div class="col-md-12">
        <div>
            <a [routerLink]="['/']" class="btn btn-light"> Back</a>
        </div>
    </div>
    <div class="col-md-12">

        <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
        <div *ngFor="let error of errors">
            <div class="alert alert-danger" role="alert"> {{ error }}</div>
        </div>

        <div class="card card-body bg-light">
            <form novalidate class="form-horizontal" (ngSubmit)="registerUser()" [formGroup]="userForm">
                <fieldset>
                    <legend>Register</legend>
                    <div class="form-group">
                        <label for="email" class="col-lg-12 control-label">Email</label>
                        <div class="col-lg-12">
                            <input type="text" class="form-control" id="email" name="email"
                                   placeholder="Email of the user" formControlName="email">
                            <div *ngIf="emailControl.dirty && emailControl.hasError('required')"
                                 class="alert alert-danger">Email is required
                            </div>
                            <div *ngIf="emailControl.dirty && emailControl.hasError('minlength')"
                                 class="alert alert-danger">Email should have at least 3 characters
                            </div>
                            <div *ngIf="emailControl.dirty && emailControl.hasError('invalidEmail')"
                                 class="alert alert-danger">Not a valid email
                            </div>
                        </div>
                    </div>
                    <div class="form-group"><label for="name" class="col-lg-12 control-label">Full name</label>
                        <div class="col-lg-12"><input type="text" class="form-control" id="name" name="name"
                                                      placeholder="Name of the user" formControlName="name">
                            <div *ngIf="nameControl.dirty && nameControl.hasError('required')"
                                 class="alert alert-danger">Name is required
                            </div>
                            <div *ngIf="nameControl.dirty && nameControl.hasError('minlength')"
                                 class="alert alert-danger">Name should have at least 3 characters
                            </div>
                        </div>
                    </div>
                    <div formGroupName="passwordForm">
                        <div class="form-group">
                            <label for="password" class="col-lg-12 control-label">Password</label>
                            <div class="col-lg-12">
                                <input type="password" class="form-control" id="password" name="password"
                                       placeholder="Password" formControlName="password">
                                <div *ngIf="passwordControl.dirty && passwordControl.hasError('required')"
                                     class="alert alert-danger">Password is required
                                </div>
                                <div *ngIf="passwordControl.dirty && passwordControl.hasError('minlength')"
                                     class="alert alert-danger">Password should have at least 6 characters
                                </div>
                            </div>
                        </div>
                        <div class="form-group"><label for="password" class="col-lg-12 control-label">Password
                            Confirmation</label>
                            <div class="col-lg-12">
                                <input type="password" class="form-control" id="password_confirmation"
                                       name="password_confirmation" placeholder="Password Confirmation"
                                       formControlName="password_confirmation">
                                <div *ngIf="confirmationControl.dirty && confirmationControl.hasError('required')"
                                     class="alert alert-danger">Password Confirmation is required
                                </div>
                                <div *ngIf="confirmationControl.dirty && confirmationControl.hasError('minlength')"
                                     class="alert alert-danger">Password Confirmation should have at least 6 characters
                                </div>
                                <div *ngIf="confirmationControl.dirty && passwordForm.hasError('invalidPassword')"
                                     class="alert alert-danger">The passwords you enter must match
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12">
                            <a [routerLink]="['/']" class="btn btn-default"> Cancel</a>
                            <button type="submit" class="btn btn-primary" [disabled]="userForm.invalid">Register
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
