/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-google-charts/angular-google-charts.ngfactory";
import * as i3 from "angular-google-charts";
import * as i4 from "@angular/common";
import * as i5 from "./dashboard.component";
import * as i6 from "../../services/permissions.service";
import * as i7 from "../../services/dashboard.service";
import * as i8 from "../../services/message.service";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "org-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.key; _ck(_v, 1, 0, currVal_0); }); }
function View_DashboardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "google-chart", [["class", "google-chart"], ["style", "width:100%;padding: 10px; margin: 0 auto"]], null, null, null, i2.View_GoogleChartComponent_0, i2.RenderType_GoogleChartComponent)), i1.ɵdid(2, 638976, [["chart", 4]], 0, i3.GoogleChartComponent, [i1.ElementRef, i3.ScriptLoaderService, [2, i3.DashboardComponent]], { type: [0, "type"], data: [1, "data"], columns: [2, "columns"], title: [3, "title"], width: [4, "width"], height: [5, "height"], options: [6, "options"], dynamicResize: [7, "dynamicResize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartType; var currVal_1 = _co.booksData[_v.parent.parent.context.$implicit.key][_v.parent.context.$implicit.key]["chartData"]; var currVal_2 = _co.colNames; var currVal_3 = _co.chartTitle; var currVal_4 = "100%"; var currVal_5 = 200; var currVal_6 = _co.chartOptions; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_DashboardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "no-data"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.st["grid"]["no_data"]; _ck(_v, 1, 0, currVal_0); }); }
function View_DashboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "book-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "book-counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ": ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_6)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_7)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.booksData[_v.parent.context.$implicit.key][_v.context.$implicit.key]["hasData"]; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.booksData[_v.parent.context.$implicit.key][_v.context.$implicit.key]["hasData"]; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.booksData[_v.parent.context.$implicit.key][_v.context.$implicit.key]["bookData"]["name"]; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.st["grid"]["all_activations"]; var currVal_2 = _co.booksData[_v.parent.context.$implicit.key][_v.context.$implicit.key]["userCount"]; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
function View_DashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "books row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DashboardComponent_5)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSuperadmin; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.value)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DashboardComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.booksData)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col align-self-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyToShow; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissions.Show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i5.DashboardComponent, [i6.PermissionsService, i7.DashboardService, i8.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i5.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
