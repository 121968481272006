import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
import { DropdownFilter } from '../../controllers/admingrid/dropdownfilter.component';
export class KeyCodesComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 'k.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_bookname = { headerName: '', field: 'bookname', colId: 'bookname', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false };
        this.colDef_kcid = { headerName: '', field: 'kcid', colId: 'kcid', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false, cellRenderer: function (param) {
                return param.data.deleted ? '<i style="color: #dc3545;" class="far fa-trash-alt"></i> ' + param.value : param.value;
            } };
        this.colDef_key = { headerName: '', field: 'key', colId: 'key', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false };
        this.colDef_uses = { headerName: '', field: 'uses', colId: 'uses', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false };
        this.colDef_used = { headerName: '', field: 'used', colId: 'used', sortable: true, resizable: true, alwaysShow: false, show: true, filter: false };
        this.colDef_already_used = { headerName: '', field: 'already_used', colId: 'already_used', sortable: true, resizable: true, show: true, filterLangPrefix: 'grid.', filterSingleSelection: true, filter: false, cellRenderer: this.checkboxRenderer };
        /*colDef_already_used = {headerName: '', field: 'already_used', colId: 'already_used', sortable: true, resizable: true, show: true, filterLangPrefix:'grid.', filterSingleSelection:true, filter: 'DropdownFilter', cellRenderer: this.checkboxRenderer};
          Having-es szűrés kéne, abból is olyan, ahol beküldjük a pontos lekérést, pl: CASE WHEN count(cb.id) > 0 then 1 else 0 end as already_used
          Ezt jelenleg nem kezeli a szűrő.
          Lehet még a www/backend/app/Http/Controllers/GridBaseController.php advancedFilter-ben kivételt kezelni esetleg.
         */
        this.columnDefs = [this.colDef_id, this.colDef_bookname, this.colDef_kcid, this.colDef_key, this.colDef_uses, this.colDef_used, this.colDef_already_used];
        this.firstLoadDone = false;
        this.actionBtnsDisplay = {
            Show: true,
            Create: false,
            Edit: true,
            Delete: false,
        };
        this.headerDisplay = {
            Columns: true,
            PageSize: true,
            QuickFilter: false,
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.frameworkComponents = { DropdownFilter: DropdownFilter };
        this.tableName = 'keycodes';
        this.formName = 'Keycodes';
        this.setPermissions('books');
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.sts.replace('grid.modify_btn', [{ 'find': ':item', 'replace': 'keycodes.keycodes' }]);
                    this.setColNameByLang(this.colDef_bookname, 'books.book', message.gridOptions);
                    this.setColNameByLang(this.colDef_kcid, 'keycodes.kc_id', message.gridOptions);
                    this.setColNameByLang(this.colDef_key, 'keycodes.key', message.gridOptions);
                    this.setColNameByLang(this.colDef_uses, 'keycodes.uses', message.gridOptions);
                    this.setColNameByLang(this.colDef_used, 'keycodes.used', message.gridOptions);
                    this.setColNameByLang(this.colDef_already_used, 'keycodes.already_used', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
}
