/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./prototyperow.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./prototyperow.component";
import * as i4 from "@angular/platform-browser";
var styles_PrototyperowComponent = [i0.styles];
var RenderType_PrototyperowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrototyperowComponent, data: {} });
export { RenderType_PrototyperowComponent as RenderType_PrototyperowComponent };
export function View_PrototyperowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "img-full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideImg() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "show": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideImg() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", ""]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 19, "div", [["class", "row position-relative "]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "bg-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-lg-4 text-center d-flex align-items-center justify-content-center my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "cover-bg-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "mobile-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "cover"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "col-lg-4 text-center  d-flex align-items-center justify-content-center my-3 mb-5 mb-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "img-container position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["class", "page"]], [[8, "src", 4], [8, "alt", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showImg(_co.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "zoom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showImg(_co.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["minta oldalp\u00E1r"])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "col-lg-4 text-center d-flex align-items-center justify-content-center my-3 mb-5 mb-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "monitor-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "iframe", [["allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"], ["allowfullscreen", ""], ["class", "youtube"], ["frameborder", "0"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["youtube vide\u00F3"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "img-full"; var currVal_1 = _ck(_v, 3, 0, (_co.showId === _co.id)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.pageSrc; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.pageAlt, ""); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.title; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.coverSrc; var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.coverAlt, ""); _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_7 = _co.pageSrc; var currVal_8 = i1.ɵinlineInterpolate(1, "", _co.pageAlt, ""); _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_9 = _co.title; _ck(_v, 22, 0, currVal_9); var currVal_10 = _co.safeYoutubeUrl; _ck(_v, 23, 0, currVal_10); }); }
export function View_PrototyperowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prototyperow", [], null, null, null, View_PrototyperowComponent_0, RenderType_PrototyperowComponent)), i1.ɵdid(1, 114688, null, 0, i3.PrototyperowComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrototyperowComponentNgFactory = i1.ɵccf("app-prototyperow", i3.PrototyperowComponent, View_PrototyperowComponent_Host_0, { coverSrc: "coverSrc", coverAlt: "coverAlt", pageSrc: "pageSrc", pageAlt: "pageAlt", youtubeUrl: "youtubeUrl", title: "title", id: "id" }, {}, []);
export { PrototyperowComponentNgFactory as PrototyperowComponentNgFactory };
