import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public lastAction: string = '';
  url: string;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
  }

  getBooksData(): Observable<any> {
    this.lastAction = 'getBooksData';
    return this.http.get(this.url + '/dashboard/getbooksdata').pipe(map((response) => response), catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || {message: 'Server Error'});
  }
}
