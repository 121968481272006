import {Component, Input, OnInit} from '@angular/core';

import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-prototyperow',
  templateUrl: './prototyperow.component.html',
  styleUrls: ['./prototyperow.component.scss']
})
export class PrototyperowComponent implements OnInit {
  @Input() coverSrc:string;
  @Input() coverAlt:string;
  @Input() pageSrc:string;
  @Input() pageAlt:string;
  @Input() youtubeUrl:any;
  @Input() title:any;
  @Input() id:any;

  safeYoutubeUrl: SafeResourceUrl;

  showId;

  constructor( private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.coverSrc ='assets/img/prototipus/'+this.coverSrc;
    this.pageSrc = 'assets/img/prototipus/'+this.pageSrc;
    this.safeYoutubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.youtubeUrl);
  }

  showImg(id) {
    this.showId = id;
  }
  hideImg() {
    this.showId = null;
  }

}
