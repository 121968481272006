import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { Label } from '../../models/Label';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-ar-label',
  templateUrl: './ar-label.component.html',
  styleUrls: ['./ar-label.component.scss']
})
export class ArLabelComponent implements OnInit,AfterContentInit {
  @Input('label') label :Label;
  @Input('index') index :number;
  labelModel:string;
  @Output('onLabelTextChange') onLabelTextChange:EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  @Input('isPlaying') isPlaying :boolean;
  ngOnInit() {
    
  }
  ngAfterContentInit(){
    this.labelModel = this.label.txt.valueOf();
  }
  ChangeLabelText(){
    let str = JSON.stringify({index:this.index,text:this.labelModel.valueOf()});
    this.onLabelTextChange.emit(str);
  }
}
