import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AdminformComponent} from '../../controllers/adminform/adminform.component';
import {map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-shoppingform',
    templateUrl: './shoppingform.component.html',
    styleUrls: ['./shoppingform.component.scss']
})
export class ShoppingformComponent extends AdminformComponent implements OnInit, OnDestroy {

    id: any;
    params: any;

    organizations;

    sellersItemList = [];
    sellersSettings = {};
    sellerControl: FormControl;

    booksItemList = [];
    booksSettings = {};
    bookControl: FormControl;
    booksDisabled: boolean = true;
    booksLoading: boolean = false;

    buyersItemList = [];
    buyersSettings = {};
    buyerControl: FormControl;
    buyersDisabled: boolean = true;
    buyersLoading: boolean = false;


    ngOnInit() {


        this.tableName = 'shoppings';
        this.formName = 'shopping';
        this.setPermissions(this.tableName);
        this.sts.st = {form: this.localeText};

        this.permissions = this.permissionsService.getPermissions(this.tableName);

        this.sellersSettings = {
            text: 'Select Seller',
            classes: 'seller-list',
            enableSearchFilter: true,
            singleSelection: true,
            enableFilterSelectAll: false,
            disabled: !this.permissions.Edit,
            emitOutput: true,
        };

        this.booksSettings = {
            text: 'Select Seller',
            classes: 'seller-list',
            enableSearchFilter: true,
            singleSelection: true,
            enableFilterSelectAll: false,
            disabled: !this.permissions.Edit
        };

        this.buyersSettings = {
            text: 'Select Seller',
            classes: 'seller-list',
            enableSearchFilter: true,
            singleSelection: true,
            enableFilterSelectAll: false,
            disabled: !this.permissions.Edit,
            emitOutput: true,
        };

        // Update
        this.params = this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];

            /*
            if (this.id) {

                this.admingridService.getItem(this.tableName, this.id).subscribe(data => {

                    //this.sts.st = data.st;
                    this.sts.st = Object.assign({}, this.sts.st, data.st);

                    this.replaceSystemTitle('books.book');
                    this.replaceTexts();

                    this.mainForm.controls['name'].setValue(data['item']['name']);
                    this.mainForm.controls['description'].setValue(data['item']['description']);
                    this.mainForm.controls['language'].setValue(data['item']['language']);
                    this.mainForm.controls['id'].setValue(data['item']['id']);
                    this.mainForm.controls['marketplace'].setValue(data['item']['marketplace']);

                    this.createItemList(data['languages'], [data['item']['language']], 'languages', 'language');
                    this.createItemList(data['editors'], data['selectedEditors'], 'editors', 'editor');

                    this.formReadyToShow = true;

                }, error => {
                    console.log(<any>error);
                    var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                    this.messageService.sendMessage({text: text, type: 'danger'});
                });
            }
            */


            this.sellerControl = this.fb.control('', [Validators.required]);
            this.bookControl = this.fb.control('', [Validators.required]);
            this.buyerControl = this.fb.control('', [Validators.required]);

            this.mainForm = this.fb.group({
                id: '',
                seller: this.sellerControl,
                book: this.bookControl,
                buyer: this.buyerControl,
            });

            // console.log('this.mainForm:', this.mainForm.controls);
        });


        // Create
        if (this.formType == 'create') {
            this.admingridService.createItem(this.tableName).subscribe(data => {
                // console.log('data:', data);

                this.organizations = data.sellers;

                //this.sts.st = data.st;
                this.sts.st = Object.assign({}, this.sts.st, data.st);
                this.replaceSystemTitle('shoppings.shopping');
                this.replaceTexts();
                this.createItemList(this.organizations, false, 'sellers', 'seller');

                this.formReadyToShow = true;
            }, error => {
                console.log(error);
                var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                this.messageService.sendMessage({text: text, type: 'danger'});
            });
        }

    }


    createItemList(items, selectedItems, listname, controlname) {
        var itemListArray = [];

        items.forEach((item) => {
            itemListArray.push({'id': item.id, 'itemName': item.name});
        });

        this[listname + 'ItemList'] = itemListArray;

        if (selectedItems) {
            const selectedItemListArray = [];
            selectedItems.forEach((item) => {
                selectedItemListArray.push({'id': item.id, 'itemName': item.name});
            });
            this.mainForm.controls[controlname].setValue(selectedItemListArray);
        }
    }

    sellerSelect(item: any) {
        this.booksDisabled = true;
        this.booksLoading = true;
        this.buyersDisabled = true;
        this.buyersLoading = true;

        //console.log("getBooksByOrganization",item);
        //this.createItemList(this.aaa, false, 'books', 'book');

        this.getBooksByOrg(item.id).subscribe(data => {
            // console.log('data:', data);
            this.createItemList(data.books, false, 'books', 'book');

            var buyerOrg = this.organizations.filter(org => org.id != item.id);
            this.createItemList(buyerOrg, false, 'buyers', 'buyer');

            this.booksDisabled = false;
            this.booksLoading = false;
            this.buyersDisabled = false;
            this.buyersLoading = false;
        }, error => {
            console.log(error);
        });

    }
    getBooksByOrg(id: number): Observable<any> {
        let url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
        return this.http.get(url + '/shoppings/getBooksByOrg/' + id).pipe(map((response) => response), catchError(this.errorHandler));
    }
    errorHandler(error: HttpErrorResponse) {
        return throwError(error.error || {message: 'Server Error'});
    }

    sellerDeSelect(item: any) {
        //console.log('sellerDeSelect', item);

        this.booksItemList = [];
        this.mainForm.controls['book'].setValue('');
        this.booksDisabled = true;

        this.buyersItemList = [];
        this.mainForm.controls['buyer'].setValue('');
        this.buyersDisabled = true;
    }

    replaceTexts() {
        this.sts.replace('form.select_item', [{'find': ':item', 'replace': 'shoppings.seller'}]);
        this.sellersSettings['text'] = this.sts.c('form.select_item');
        this.sellersSettings['searchPlaceholderText'] = this.sts.c('form.search');

        var seller = this.sts.c('shoppings.seller');
        this.sts.replace('form.select_item', [{'find': seller, 'replace': 'books.book'}]);
        this.booksSettings['text'] = this.sts.c('form.select_item');

        var books = this.sts.c('books.book');
        this.sts.replace('form.select_item', [{'find': books, 'replace': 'shoppings.buyer'}]);
        this.buyersSettings['text'] = this.sts.c('form.select_item');
    }


    ngOnDestroy() {
        this.params.unsubscribe();
    }

}
