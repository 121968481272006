import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    constructor() {
    }

    getPermissions(table: string) {
        let permissionsObj = JSON.parse(sessionStorage.userpermissions);
        let rolesObj = JSON.parse(sessionStorage.userroles);
        var isSuperAdmin = permissionsObj.find(obj => obj.name == 'Superadmin') ? true : false;
        var isAdmin = rolesObj.find(obj => obj == 'Admin') ? true : false;
        var table = table.charAt(0).toUpperCase() + table.slice(1);
        var obj = {
            Show: permissionsObj.find(obj => obj.name == table + ' Show') || isSuperAdmin ? true : false,
            Create: permissionsObj.find(obj => obj.name == table + ' Create') || isSuperAdmin ? true : false,
            Edit: permissionsObj.find(obj => obj.name == table + ' Edit')  || isSuperAdmin ? true : false,
            Docgen: false,
            Delete:  permissionsObj.find(obj => obj.name == table + ' Delete') || isSuperAdmin ? true : false,
            Export: false,
            Import: false,
            isSuperadmin: isSuperAdmin,
            isAdmin: isAdmin
        };
        return obj;
    }
    getShowPermissions(table: string) {
        let permissionsObj = JSON.parse(sessionStorage.userpermissions);
        var isSuperAdmin = permissionsObj.find(obj => obj.name == 'Superadmin') ? true : false;
        return permissionsObj.find(obj => obj.name == table + ' Show') || isSuperAdmin ? true : false;
    }
}
