import {Component, Input, Output, OnInit, EventEmitter, HostListener, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LanguageService} from '../../services/language.service';
import {SystemtextService} from '../../services/systemtext.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-menutop',
    templateUrl: './menutop.component.html',
    styleUrls: ['./menutop.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenutopComponent implements OnInit {

    langItemList = [];
    langSettings = {};

    mainForm: FormGroup;
    langControl: FormControl;

    wt: any; // web text
    adminBtnText: string;

    constructor(protected fb: FormBuilder, protected ls: LanguageService, protected sts: SystemtextService, private router: Router) {
    }


    ngOnInit() {

        // this.sts.st = JSON.parse(sessionStorage.getItem('basetexts')).menu[localStorage.getItem('lang')];
        this.wt = this.sts.st[localStorage.getItem('lang')].menu;
        this.adminBtnText = sessionStorage.getItem('userdata') == null || this.router.url == '/logout' ? this.wt.login : this.wt.admin;


        this.langControl = this.fb.control('',);
        this.mainForm = this.fb.group({
            languageselector: this.langControl,
        });


        this.ls.getLanguages().subscribe(languages => {

            this.langItemList = languages;

            var lang = localStorage.getItem('lang');
            var selectedId;
            languages.forEach((language) => {
                if (language.code == lang) {
                    this.mainForm.controls.languageselector.setValue([language]);
                    selectedId = language.id;
                }
            });

            this.langSettings = {
                text: 'Select language',
                enableSearchFilter: false,
                singleSelection: true,
                enableFilterSelectAll: false,
                classes: 'language-selector',
                selectedId: selectedId
            };


        }, error => {
            console.log(error);
        });


    }


    @HostListener('dragstart', ['$event']) function (event: MouseEvent) {
        event.preventDefault(); // prevent menu dragstart event
    }
}
