import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-subpageheader',
  templateUrl: './subpageheader.component.html',
  styleUrls: ['./subpageheader.component.scss']
})
export class SubpageheaderComponent implements OnInit {

  constructor() { }
  @Input() text:string;
  @Input() imgfolder:string;
  bgUrl:string;
  ngOnInit() {
    this.bgUrl = 'assets/img/'+this.imgfolder+'/headerbg.jpg';
  }

}
