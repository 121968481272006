/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagenotfound.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./pagenotfound.component";
var styles_PagenotfoundComponent = [i0.styles];
var RenderType_PagenotfoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PagenotfoundComponent, data: {} });
export { RenderType_PagenotfoundComponent as RenderType_PagenotfoundComponent };
export function View_PagenotfoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "not-found-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["style", "text-align: center;font-size: 4em;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page Not Found"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["routerLink", "/"], ["style", "margin:auto;display:block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Home"]))], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_PagenotfoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagenotfound", [], null, null, null, View_PagenotfoundComponent_0, RenderType_PagenotfoundComponent)), i1.ɵdid(1, 114688, null, 0, i3.PagenotfoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagenotfoundComponentNgFactory = i1.ɵccf("app-pagenotfound", i3.PagenotfoundComponent, View_PagenotfoundComponent_Host_0, {}, {}, []);
export { PagenotfoundComponentNgFactory as PagenotfoundComponentNgFactory };
