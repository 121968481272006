import { BehaviorSubject } from 'rxjs';
import { sampleTime } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class TimelineService {
    constructor() {
        this._time = new BehaviorSubject(0);
        this._maxTime = new BehaviorSubject(0);
        this._isPlaying = new BehaviorSubject(0);
        this._saved = new BehaviorSubject(true);
        this._volume = new BehaviorSubject(0.7);
        this._duration = new BehaviorSubject(0);
        this._resetDuration = new BehaviorSubject(false);
        this._nextSlide = new BehaviorSubject(false);
        this._contentIndex = new BehaviorSubject(null);
        this.time = this._time.asObservable();
        this.maxTime = this._maxTime.asObservable();
        this.isPlaying = this._isPlaying.asObservable();
        this.saved = this._saved.asObservable();
        this.volume = this._volume.asObservable().pipe(sampleTime(200));
        this.duration = this._duration.asObservable();
        this.resetDuration = this._resetDuration.asObservable();
        this.nextSlide = this._nextSlide.asObservable();
        this.contentIndex = this._contentIndex.asObservable();
    }
    SetContentIndex(i) {
        this._contentIndex.next(i);
    }
    SetSaved(saved, from) {
        console.log(from);
        this._saved.next(saved);
    }
    SetTime(time, from) {
        // console.log(from);
        this._time.next(time);
    }
    SetVolume(vol) {
        this._volume.next(vol);
    }
    SetMaxTime(mTime) {
        this._maxTime.next(mTime);
    }
    Play() {
        console.log('---------Called Play in service');
        this._isPlaying.next(2);
    }
    Pause() {
        this._isPlaying.next(1);
    }
    Stop() {
        this._isPlaying.next(0);
        setTimeout(() => {
            this._time.next(0);
        }, 0);
    }
    SetSlideDuration(duration) {
        this._duration.next(duration);
    }
    SetOriginalSlideDuration() {
        this._resetDuration.next(true);
    }
    NextSlide(next) {
        this._nextSlide.next(next);
    }
}
TimelineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimelineService_Factory() { return new TimelineService(); }, token: TimelineService, providedIn: "root" });
