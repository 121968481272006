import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
export class RolesComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        // users: Userroles[];
        this.firstLoadDone = false; // TODO: Ha nincs a colDef-ek előtt valamilyen változó, akkor nem tölti be a lapot. De ez csak ennél a componentnél van :/
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 'r.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_name = { headerName: '', field: 'name', colId: 'r.name', sortable: true, resizable: true, alwaysShow: true, show: true, filter: 'agTextColumnFilter' };
        this.columnDefs = [this.colDef_id, this.colDef_name];
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableName = 'roles';
        this.formName = 'Role';
        this.setPermissions(this.tableName);
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.sts.replace('grid.add_btn', [{ 'find': ':item', 'replace': 'roles.role' }]);
                    this.sts.replace('grid.show_btn', [{ 'find': ':item', 'replace': 'roles.role' }]);
                    this.sts.replace('grid.modify_btn', [{ 'find': ':item', 'replace': 'roles.role' }]);
                    this.sts.replace('grid.copy_btn', [{ 'find': ':item', 'replace': 'roles.role' }]);
                    this.sts.replace('grid.delete_btn', [{ 'find': ':item', 'replace': 'roles.role' }]);
                    this.setColNameByLang(this.colDef_name, 'roles.name', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
}
