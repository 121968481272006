import {ViewChild,Component, OnInit} from '@angular/core';
import {PermissionsService} from '../../services/permissions.service';
import {MessageService} from '../../services/message.service';
import {AdmingridService} from '../../services/admingrid.service';
import {GridOptions} from 'ag-grid-community';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {Router, ActivatedRoute} from '@angular/router';
import {SystemtextService} from '../../../services/systemtext.service';

@Component({
    selector: 'app-admingrid',
    templateUrl: './admingrid.component.html',
    styleUrls: ['./admingrid.component.scss']
})
export class AdmingridComponent implements OnInit {

    @ViewChild('agGrid',{static: false}) agGrid;
    public tableName: string;
    public formName: string;
    public localeText = JSON.parse(sessionStorage.getItem('basetexts')).grid[localStorage.getItem('lang')];

    protected frameworkComponents:{};

    public actionBtnsDisplay = {
        Show: true,
        Create: true,
        Edit: true,
        Delete: true,
    };

    public headerDisplay = {
        Columns: true,
        PageSize: true,
        QuickFilter: true,
    };

    public permissions;
    public deleteInProgress: boolean = false;

    public extraDeleteConfirmText:string = "";

    public overlayLoadingTemplate = '<span class="ag-overlay-loading-center">' + this.localeText.overlay_loading + '</span>';
    public overlayNoRowsTemplate = '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">' + this.localeText.overlay_no_rows + '</span>';

    public paginationPageSize = 10;
    public cacheBlockSize = 10;

    public quickFilterText: string = '';
    public quickFilterTimeout;
    public quickFilterDelay: number = 800;

    public columnDefs: any;
    public origColumnDefs: any;
    public rowData: any;

    public gridOptions = <GridOptions>{
        suppressPropertyNamesCheck: true, // Disables showing a warning message in the console if using a gridOptions or colDef property that doesn't exist.
        rowData: this.rowData,
        // columnDefs:this.columnDefs,
        animateRows: true,
        // floatingFilter: true,
        domLayout: 'autoHeight',
        //rowSelection: 'single',
        rowSelection: 'multiple',
        pagination: true,
        paginationPageSize: this.paginationPageSize,

        rowModelType: 'infinite',
        overlayLoadingTemplate: this.overlayLoadingTemplate,
        overlayNoRowsTemplate: this.overlayNoRowsTemplate,

        // cacheOverflowSize:2,
        // maxConcurrentDatasourceRequests:2,
        // infiniteInitialRowCount: 1,
        // maxBlocksInCache:1,
        cacheBlockSize: this.cacheBlockSize,

        /*
        components: {
            rolesCellRenderer: this.rolesCellRenderer
        },
        */

        columnTypes: {
            'array_agg': {},
            nonEditableColumn: {editable: false},
        },
        localeText: this.localeText,

    };

    public defaultFilterParams: object = {
        clearButton: true,
        applyButton: true
    };

    public dataSource: any;

    public girdModalOptions = <any>{
      title: "Modal title",
      body: "...",
      showx:false,
      actionInProgress: false,
      close: {
        text: "Close",
        show: true,
        class: "btn-secondary"
      },
      action1: {
        show: true,
        text: "Action 1",
        class: "btn-primary",
        params: ""
      },
      action2: {
        show: false,
        text: "Action 2",
        class: "btn-primary",
        params: ""
      },
      passwordConfirmation: {
        show: false,
        errorEmpty: false,
        errorBadPw: false
      }
    }
    private passwordConfirmationValue:string = "";

    constructor(protected permissionsService: PermissionsService, protected admingridService: AdmingridService, protected router: Router, protected route: ActivatedRoute, protected messageService: MessageService, protected loadingBarService: LoadingBarService, protected sts: SystemtextService) {
        this.messageService.clearMessage();
    }

    ngOnInit(gridOptions: GridOptions = null) {

        if (typeof(gridOptions) == 'object')
            this.gridOptions = Object.assign(this.gridOptions, gridOptions);

        // console.log("super ngOnInit");

        // console.log('localeText:', this.localeText);
        this.sts.st = {grid: this.localeText};
        this.route.queryParams.subscribe(params => setTimeout(() => { // https://github.com/angular/angular/issues/17572
            if (params.message && params.messageType) {
                this.messageService.sendMessage({text: params.message, type: params.messageType});
            }
        }, 0));

    }

    setPermissions(tableName) {
        this.permissions = this.permissionsService.getPermissions(tableName);
    }

    changeValueByLang(items) {
        // run in child component if necessary
    }
    actionBySelectedRowsChangeBeforeExecute(propObj) {
        // run in child component if necessary
    }

    onGridReady(params) {
        // console.log('onGridReady!', this.tableName);

        this.origColumnDefs = JSON.parse(JSON.stringify(this.columnDefs));
        //console.log("this.origColumnDefs",this.origColumnDefs);

        this.getGridOptionsFromLocalStorage();

        this.dataSource = {
            rowCount: null,
            getRows: (params) => {
                // console.log('params', params);
                // console.log('params.startRow', params.startRow);
                // console.log('params.filterModel', params.filterModel);
                // console.log('params.sortModel', params.sortModel);


                this.gridOptions.api.showLoadingOverlay();
                // console.log("grid data source");

                this.admingridService.getItems(this.tableName, params.startRow, this.paginationPageSize, params.sortModel, this.quickFilterText, params.filterModel).subscribe(items => {

                    // console.log('items:', items);
                    // this.sts.st = items.st;
                    this.sts.st = Object.assign({}, this.sts.st, items.st);
                    this.changeValueByLang(items.items);
                    // console.log('this.sts.st:', this.sts.st);

                    /*
                    if(items['refreshedToken']) {
                        console.log("Has access token!");
                        sessionStorage.setItem('token', items['access_token']);

                        var currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                            this.router.navigate([currentUrl])
                        );
                    }
                    */

                    params.successCallback(items.items, items.rowNumber);

                    if (this.gridOptions.api) {
                        this.gridOptions.api.hideOverlay();
                    }


                    this.messageService.sendStatus({name: 'grid', status: 'ready', form: this.formName, gridOptions: this.gridOptions});


                }, error => {
                    // console.log(error);
                    var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
                    this.messageService.sendMessage({text: text, type: 'danger'});
                });


            }
        };
        params.api.setDatasource(this.dataSource);
        params.api.sizeColumnsToFit();

    }


    deleteItem(id, name, selectedRow) {
        if (!this.deleteInProgress) {
            var body = name[0] == undefined  ? "id: "+id : name;
            if(this.tableName == "books") {
              this.girdModalOptions.passwordConfirmation.show = true;
            }
            this.girdModal("delete", id, body, selectedRow);
        }
    }

    maintenanceItem(id, name, selectedRow) {
        if (!this.deleteInProgress) {
            var body = name[0] == undefined  ? "id: "+id : name;
            this.girdModal("maintenance", id, body, selectedRow);
        }
    }
    generateWebglZip(id, name, selectedRow) {
        if (!this.deleteInProgress) {
            var body = name[0] == undefined  ? "id: "+id : name;
            this.girdModal("generateWebglZip", id, body, selectedRow);
        }
    }

    girdModal(type, id, body, selectedRow) {
        if(type == "delete") {
          this.deleteModal(id, body);
        }
        if(type == "maintenance") {
          this.maintenanceModal(id, body, selectedRow);
        }
        if(type == "generateWebglZip") {
          this.generateWebglZipModal(id, body, selectedRow);
        }
    }

    deleteModal(id, body) {
      // console.log("this.tableName",this.tableName);
      this.girdModalOptions.title = this.sts.c('grid.delete_btn');
      this.girdModalOptions.body = body.indexOf("id: ") == 0 ? body : body.join(", ");
      this.girdModalOptions.close.text = this.sts.c('grid.cancel');

      if(this.tableName == "users") {
        this.girdModalOptions.action1.text = this.sts.c('users.final_delete');
        this.girdModalOptions.action1.class = "btn-danger";
        this.girdModalOptions.action1.params =  {id: id, prop:{type:'delete', anonymization:false}}

        this.girdModalOptions.action2.show = true;
        this.girdModalOptions.action2.text = this.sts.c('users.anonymization');
        this.girdModalOptions.action2.class = "btn-warning";
        this.girdModalOptions.action2.params =  {id: id, prop:{type:'delete',anonymization:true}}

      } else if(this.tableName == "books") {
        this.girdModalOptions.action1.text = this.sts.c('grid.delete');
        this.girdModalOptions.action1.class = "btn-danger";
        this.girdModalOptions.action1.params =  {id: id, prop:{type:'delete',needPassword:true}}
      } else {
        this.girdModalOptions.action1.text = this.sts.c('grid.delete');
        this.girdModalOptions.action1.class = "btn-danger";
        this.girdModalOptions.action1.params =  {id: id, prop:{type:'delete'}}
      }
      (<HTMLInputElement>document.getElementById("modalpasswordconfirmation")).value = "";
      this.girdModalOptions.passwordConfirmation.errorEmpty = false
      this.girdModalOptions.passwordConfirmation.errorBadPw = false
      document.getElementById("openGridModal").click();
    }

    maintenanceModal(id, body, selectedRow) {
      // console.log("this.tableName",this.tableName);
      // console.log("selectedRow",selectedRow);
      this.girdModalOptions.title = this.sts.c('books.maintenance')+": "+body;
      this.girdModalOptions.body = this.sts.c('books.ttip_maintenance');
      this.girdModalOptions.close.text = this.sts.c('grid.cancel');
      this.girdModalOptions.action1.text = selectedRow[0].maintenance_orig ? this.sts.c('books.end_maintenance') : this.sts.c('books.start_maintenance');
      this.girdModalOptions.action1.class = "btn-danger";
      var oppositeStatus = selectedRow[0].maintenance_orig ? false : true;
      this.girdModalOptions.action1.params =  {id: id, prop:{type:'maintenance', maintenanceStatus: oppositeStatus}}
      document.getElementById("openGridModal").click();
    }

    generateWebglZipModal(id, body, selectedRow) {
      // console.log("this.tableName",this.tableName);
      // console.log("selectedRow",selectedRow);
      this.girdModalOptions.title = this.sts.c('books.webglzip')+": "+body;
      this.girdModalOptions.body = this.sts.c('books.ttip_webglzip');
      this.girdModalOptions.close.text = this.sts.c('grid.cancel');
      this.girdModalOptions.action1.text =  this.sts.c('books.start_webglzip');
      this.girdModalOptions.action1.class = "btn-info";
      this.girdModalOptions.action1.params =  {id: id, prop:{type:'webglzip'}}
      document.getElementById("openGridModal").click();
    }

    passwordConfirmation(params) {
        this.passwordConfirmationValue = params;
    }

    gridModalAction(params) {
      // console.log("grid modal action params:", params);
      if (params.prop.needPassword) {
        this.girdModalOptions.passwordConfirmation.errorEmpty = false
        this.girdModalOptions.passwordConfirmation.errorBadPw = false

        if (!this.passwordConfirmationValue.trim()) {
          this.girdModalOptions.passwordConfirmation.errorEmpty = true
          return;
        } else {
          this.girdModalOptions.actionInProgress = true;
          this.admingridService.validateUser({password: this.passwordConfirmationValue}).subscribe(response => {
            // console.log("response", response);
            this.passwordConfirmationValue = "";
            if (response.success == false) {
              this.girdModalOptions.passwordConfirmation[response.error] = true;
               this.girdModalOptions.actionInProgress = false;
              return;
            }
            if(response.success == true) {
              this.executeGridModalAction(params);
            }
          }, error => {
            alert(<any>error);
             this.girdModalOptions.actionInProgress = false;
          });
        }
      } else {
        this.executeGridModalAction(params);
      }
    }

    executeGridModalAction(params) {
      // console.log("grid modal action params:", params);
      this.girdModalOptions.actionInProgress = true;
      // console.log("selectedRow:",selectedRow);
      // this.agGrid.api.updateRowData({remove: selectedRow}); // cannot be used in infinity mode
      if(params.prop.type == 'delete') {
        this.deleteInProgress = true;
        this.admingridService.deleteItem(this.tableName, params.id, params.prop).subscribe(item => {
          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          // remove from frontend
          // this.agGrid.api.updateRowData({remove: selectedRow});
          this.loadingBarService.complete();

          // TODO: Jó ez így?
          // this.agGrid.api.refreshInfiniteCache(); // Ezzel a kiválasztott lapon marad a törlés után, de újratölti egyesével az összes előző lapot
          this.agGrid.api.setDatasource(this.dataSource); // Ezzel az első lapra lép a törlés után


          this.deleteInProgress = false;
          const type = item.messageType ? item.messageType : 'success';
          this.messageService.sendMessage({text: item.message, type: type});

        }, error => {
          console.log(<any>error);
          this.deleteInProgress = false;
          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }

      if(params.prop.type == 'maintenance') {
        this.admingridService.maintenanceBook(this.tableName, params.id, params).subscribe(item => {
          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          // remove from frontend
          // this.agGrid.api.updateRowData({remove: selectedRow});
          this.loadingBarService.complete();

          // TODO: Jó ez így?
          // this.agGrid.api.refreshInfiniteCache(); // Ezzel a kiválasztott lapon marad a törlés után, de újratölti egyesével az összes előző lapot
          this.agGrid.api.setDatasource(this.dataSource); // Ezzel az első lapra lép a törlés után

          const type = item.messageType ? item.messageType : 'success';
          this.messageService.sendMessage({text: item.message, type: type});

        }, error => {
          console.log(<any>error);
          this.deleteInProgress = false;
          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }

      if(params.prop.type == 'webglzip') {
        this.admingridService.getWebglZip(this.tableName, params.id, params).subscribe(item => {

          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(item)
          a.href = objectUrl
          a.download = 'webglFiles-book-'+params.id+'.zip';
          a.click();
          URL.revokeObjectURL(objectUrl);

          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          // remove from frontend
          // this.agGrid.api.updateRowData({remove: selectedRow});
          this.loadingBarService.complete();

        }, error => {
          console.log(<any>error);
          this.deleteInProgress = false;
          this.girdModalOptions.actionInProgress = false;
          document.getElementById("closeGridModalHidden").click();
          var text = typeof(error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
          this.messageService.sendMessage({text: text, type: 'danger'});
        });
      }

    }

    actionBySelectedRows(type: string) {
        const selectedNodes = this.agGrid.api.getSelectedNodes();

        const selectedData = selectedNodes.map(node => node.data);
        const id = selectedData.map(node => node.id);
        const name = selectedData.map(node => node.name);

        var propObj = { selectedNodes: selectedNodes, type: type };
        this.actionBySelectedRowsChangeBeforeExecute(propObj);
        type = propObj.type;

        this.girdModalOptions.passwordConfirmation.show = false;

        if (id.length == 0) {
            const alertText = this.sts.c('grid.m_please_select_at_least_one_item');
            alert(alertText);
        }
        if (id.length > 1 && ['show', 'edit', 'copy', 'maintenance'].indexOf(type) > -1) {
            const alertText = this.sts.c('grid.m_please_select_only_one_item');
            alert(alertText);
        }

        if (type === 'show' && id.length === 1) {
            this.router.navigateByUrl('/admin/' + this.tableName + '/show/' + id, {state: {ignoreLoadingBar: true}});
        }
        if (type === 'edit' && id.length === 1) {
            this.router.navigateByUrl('/admin/' + this.tableName + '/edit/' + id, {state: {ignoreLoadingBar: true}});
        }
        if (type === 'copy' && id.length === 1) {
            this.router.navigateByUrl('/admin/' + this.tableName + '/copy/' + id, {state: {ignoreLoadingBar: true}});
        }
        if (type === 'maintenance' && id.length === 1) {
            const selectedRow = this.agGrid.api.getSelectedRows();
            this.maintenanceItem(id, name, selectedRow);
        }
        if (type === 'webglzip' && id.length === 1) {
          const selectedRow = this.agGrid.api.getSelectedRows();
          this.generateWebglZip(id, name, selectedRow);
        }
        if (type === 'delete' && id.length > 0) {
            const selectedRow = this.agGrid.api.getSelectedRows();
            this.deleteItem(id, name, selectedRow);
        }

    }

    getSelectedRows() {
        const selectedNodes = this.agGrid.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        const selectedDataStringPresentation = selectedData.map(node => node.id).join(', ');
        // console.log(`Selected nodes: ${selectedDataStringPresentation}`);
    }

    onPageSizeChanged(value) {
        //console.log("onPageSizeChanged");
        var value = value ? value : Number((<HTMLInputElement>document.getElementById('page-size')).value);

        this.gridOptions.cacheBlockSize = value;
        this.gridOptions.paginationPageSize = value;
        this.paginationPageSize = value;

        // this.agGrid.api['infinitePageRowModel'].resetCache();
        this.agGrid.api.onSortChanged();
        this.setGridOptionLocalStorage(this.tableName, 'size', [value]);

    }

    getGridOptionsFromLocalStorage() {
        // console.log('getGridOptionsFromLocalStorage');
        var gridOptions = JSON.parse(localStorage.getItem('gridOptions'));
        if (gridOptions != null && gridOptions[this.tableName + '-size'] != undefined) {
            var sizeValue = gridOptions[this.tableName + '-size'];

            this.paginationPageSize = sizeValue;
            this.cacheBlockSize = sizeValue;
            this.gridOptions.cacheBlockSize = sizeValue;
            this.gridOptions.paginationPageSize = sizeValue;
        }

        if (gridOptions != null && gridOptions[this.tableName + '-columns'] != undefined) {
            var menus = gridOptions[this.tableName + '-columns'];
            this.columnDefs.forEach((element) => {
                let fieldName = element.field;
                if (menus.indexOf('colDef_' + fieldName) >= 0) {
                    element.show = true;
                } else {
                    element.show = false;
                }
            });
        }
    }

    setGridOptionLocalStorage(menu: string, type: string, value: any) {
        // console.log('menu:', menu);
        // console.log('type:', type);
        // console.log('value:', value);

        var LsGridOptions: any = localStorage.getItem('gridOptions');

        if (LsGridOptions == null) {
            localStorage.setItem('gridOptions', '{}');
            LsGridOptions = '{}';
        }
        LsGridOptions = JSON.parse(LsGridOptions);

        if (type === 'size') {
            if (value[0] == 10) {
                delete LsGridOptions[menu + '-size'];
            } else {
                LsGridOptions[menu + '-size'] = value[0];
            }
        }
        if (type === 'colums') {
            var valueClone = value.slice(0);
            var defaultColumns = true;
            this.origColumnDefs.forEach((element) => {
                if (element.show === false) return true;
                let fieldName = element.field;
                if (valueClone.indexOf('colDef_' + fieldName) >= 0) {
                    valueClone.splice(valueClone.indexOf('colDef_' + fieldName), 1);
                } else {
                    defaultColumns = false;
                }
            });
            if (valueClone.length === 0 && defaultColumns) {
                delete LsGridOptions[menu + '-columns'];
            } else {
                LsGridOptions[menu + '-columns'] = value.join();
            }


        }

        LsGridOptions = JSON.stringify(LsGridOptions);
        localStorage.setItem('gridOptions', LsGridOptions);

    }


    onFilterTextBoxChanged(filtertext: string) {
        clearTimeout(this.quickFilterTimeout);
        this.quickFilterTimeout = setTimeout(() => {
            this.quickFilterText = filtertext;

            // TODO: Jó ez így?
            // this.agGrid.api.paginationGoToFirstPage();
            // this.agGrid.api.purgeInfiniteCache();
            // this.agGrid.maxBlocksInCache = 1;
            // this.agGrid.api.refreshInfiniteCache();

            this.agGrid.api.setDatasource(this.dataSource);

        }, this.quickFilterDelay);
    }

    setColumnDefs(columnDefs: object[]) {
        var defaultFilterParams = Object.assign(this.defaultFilterParams || {}, {tableName: this.tableName});

        this.gridOptions.columnDefs = columnDefs.filter((item) => {
            if (item) {
                if (item['filter'] != null) {
                    item['filterParams'] = Object.assign(item['filterParams'] || {}, defaultFilterParams);
                }
            }
            return item;
        });
    }

    onColumnsChanged(seOrigColumns = false) {
        //console.log('setDefault:', seOrigColumns);
        var cols = [];
        var colNames = [];
        this.agGrid.api.setColumnDefs(cols);

        if (!seOrigColumns) {
            this.columnDefs.forEach((element) => {
                let fieldName = element.field;
                if (this.getBooleanValue('cid-' + fieldName)) {
                    cols.push(this['colDef_' + element.field]);
                    colNames.push('colDef_' + element.field);
                }
            });
            this.setGridOptionLocalStorage(this.tableName, 'colums', colNames);
        } else {
            this.origColumnDefs.forEach((element) => {
                let fieldName = element.field;
                if (element.show) {
                    cols.push(this['colDef_' + element.field]);
                    this.elementCheck('cid-' + fieldName);
                } else {
                    this.elementUncheck('cid-' + fieldName);
                }
            });
        }


        this.agGrid.api.setColumnDefs(cols);
        this.agGrid.api.sizeColumnsToFit();

    }

    getBooleanValue(cssSelector) {
        // console.log('cssSelector:', cssSelector);
        return (<HTMLInputElement>document.getElementById(cssSelector)).checked;
    }

    elementCheck(cssSelector) {
        (<HTMLInputElement>document.getElementById(cssSelector)).checked = true;
    }

    elementUncheck(cssSelector) {
        (<HTMLInputElement>document.getElementById(cssSelector)).checked = false;
    }


    /**
     * Set column name by lang in Options and in ColumnDefs
     * @param {string} field
     * @param {string} langtext
     * @param {object} gridOptions
     */
    setColNameByLang(colDef: object, langtext: string, gridOptions: any) {
        var textByLang = this.sts.c(langtext);
        var field = colDef['colId'];
        gridOptions.columnApi.getColumn(field)['colDef'].headerName = textByLang;
        colDef['headerName'] = textByLang;


    }


    checkboxRenderer(param) {
        return param.value  ? '<span style="color: #28a745">&#x2714;</span>' : '<span style="color: #dc3545">&#10006;</span>';
    }


    languageRenderer(param) {

        var langString = param.value.split('¤');
        var langName = langString[0];
        var langFromDb = langString[1];

        var langFromDbString = langFromDb.split('.');
        var langCode = langFromDbString[1];

        var flags = JSON.parse(sessionStorage.getItem("flags"));

        return '<div style="'+flags.styleInlineGrid+' background-position: '+flags.bgposition[langCode]+'"  ></div>' + langName;

    }

}
